/**
 * @generated SignedSource<<ba2e3b5ca8459f4d7f7dc8bb79612586>>
 * @relayHash d17b7d02c56efacfe603b2d440c2fd0a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c26ce19a32f11e4aa97daae63596d4acca03a0b1e74f76dbaa843ef1ba26c337

import type { ConcreteRequest, Query } from 'relay-runtime';
export type extendedFormContentMultiHcEntitlementQuery$variables = {
  cloudId: string;
  workSpaceARI: string;
};
export type extendedFormContentMultiHcEntitlementQuery$data = {
  readonly helpCenter: {
    readonly helpCentersConfig: {
      readonly __typename: "HelpCentersConfig";
      readonly isEnabled: boolean;
    } | {
      readonly __typename: "QueryError";
      readonly extensions: ReadonlyArray<{
        readonly errorType: string | null | undefined;
        readonly statusCode: number | null | undefined;
      }> | null | undefined;
      readonly identifier: string | null | undefined;
      readonly message: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type extendedFormContentMultiHcEntitlementQuery = {
  response: extendedFormContentMultiHcEntitlementQuery$data;
  variables: extendedFormContentMultiHcEntitlementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workSpaceARI"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "workspaceAri",
    "variableName": "workSpaceARI"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isEnabled"
    }
  ],
  "type": "HelpCentersConfig"
},
v5 = {
  "kind": "ScalarField",
  "name": "identifier"
},
v6 = {
  "kind": "ScalarField",
  "name": "message"
},
v7 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v8 = {
  "kind": "ScalarField",
  "name": "statusCode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "extendedFormContentMultiHcEntitlementQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCentersConfig",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ]
                  }
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "extendedFormContentMultiHcEntitlementQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCentersConfig",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ]
                  }
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "c26ce19a32f11e4aa97daae63596d4acca03a0b1e74f76dbaa843ef1ba26c337",
    "metadata": {},
    "name": "extendedFormContentMultiHcEntitlementQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8f923ba417e51d30e9f4d5e3995b1ad1";

export default node;
