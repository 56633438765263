import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import { Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Customer } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import messages from './messages';

type RequestsCellViewProps = {
	profile: Customer;
};

const REQUESTS_STATUS = {
	OPEN: 'Open',
	CLOSED: 'Closed',
} as const;

const LinkButton = ({
	jqlString,
	label,
	type,
}: {
	label: string;
	jqlString: string;
	type: 'Open' | 'Closed';
}) => {
	const baseUrl = '';

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onClick = (_x: any, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, `viewRequests${type}`);
	};

	return (
		<Button
			spacing="none"
			appearance="link"
			href={`${baseUrl}/issues/?jql=${jqlString}`}
			target="_blank"
			onClick={onClick}
		>
			{label}
		</Button>
	);
};

export const OpenRequestsCellView = ({
	profile: { openRequestsJql, openRequestCount, showCounts },
}: RequestsCellViewProps) => {
	const { formatMessage } = useIntl();

	if (!showCounts) {
		return (
			<LinkButton
				label={formatMessage(messages.viewOpen)}
				jqlString={openRequestsJql}
				type={REQUESTS_STATUS.OPEN}
			/>
		);
	}

	if (openRequestCount > 0) {
		return (
			<LinkButton
				label={formatMessage(messages.openRequests, { requestsCount: openRequestCount })}
				jqlString={openRequestsJql}
				type={REQUESTS_STATUS.OPEN}
			/>
		);
	}

	return <Text>{formatMessage(messages.openRequests, { requestsCount: openRequestCount })}</Text>;
};

export const ClosedRequestsCellView = ({
	profile: { closedRequestCount, closedRequestsJql, showCounts },
}: RequestsCellViewProps) => {
	const { formatMessage } = useIntl();

	if (!showCounts) {
		return (
			<LinkButton
				label={formatMessage(messages.viewClosed)}
				jqlString={closedRequestsJql}
				type={REQUESTS_STATUS.CLOSED}
			/>
		);
	}

	if (closedRequestCount > 0) {
		return (
			<LinkButton
				label={formatMessage(messages.closedRequests, {
					requestsCount: closedRequestCount,
				})}
				jqlString={closedRequestsJql}
				type={REQUESTS_STATUS.CLOSED}
			/>
		);
	}

	return (
		<Text>{formatMessage(messages.closedRequests, { requestsCount: closedRequestCount })}</Text>
	);
};
