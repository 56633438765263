import {
	NEW as NewOption,
	isNew,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/organizations/constants.tsx';
import type { Options, Option, UseSelectUtilsProps } from './types';

export const useSelectUtils = ({ onSelect, onFetchOptions }: UseSelectUtilsProps) => {
	const onChange = (selectedOption: Option) => {
		onSelect(selectedOption);
	};

	const getNewOptionData = (name: string, label: string): Option => ({
		label,
		name,
		value: NewOption,
		status: '',
		numberOfOtherProjects: 0,
	});

	const loadOptions = (query: string, resolve: (arg1: Options) => void) => {
		onFetchOptions({ query, resolve });
	};

	const isValidNewOption = (inputValue: string, inputAsOption: Option, OptionList: Options) => {
		if (!inputValue || !inputValue.trim()) {
			return false;
		}

		return !OptionList.some(({ name }) => name.toLowerCase() === inputValue.trim().toLowerCase());
	};

	const formatOptionLabel = ({ label, name, status, value }: Option) => {
		if (isNew(value)) {
			return label;
		}

		return `${name}${status ? ` (${status})` : ''}`;
	};

	return {
		onChange,
		loadOptions,
		getNewOptionData,
		isValidNewOption,
		formatOptionLabel,
	};
};
