import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	nameColumn: {
		id: 'servicedesk-customers-organization-common.table.header.name-column',
		defaultMessage: 'Name',
		description: 'Column header for name',
	},
	openRequestsColumn: {
		id: 'servicedesk-customers-organization-common.table.header.open-requests-column',
		defaultMessage: 'Open requests',
		description: 'Column header for open requests',
	},
	closedRequestsColumn: {
		id: 'servicedesk-customers-organization-common.table.header.closed-requests-column',
		defaultMessage: 'Closed requests',
		description: 'Column header for closed requests',
	},
	statusColumn: {
		id: 'servicedesk-customers-organization-common.table.header.status-column',
		defaultMessage: 'Status',
		description: 'Column header for displaying the logged in status of a customer',
	},
	statusAbsentAaDescription: {
		id: 'servicedesk-customers-organization-common.table.header.status-absent-aa-description',
		defaultMessage:
			'Customers without status are Atlassian account users managed by your organization admins',
		description: 'Column header for displaying the logged in status of a customer',
	},
	actionsColumn: {
		id: 'servicedesk-customers-organization-common.table.header.actions-column',
		defaultMessage: 'Actions',
		description:
			'Column header for displaying the actions on a customer. E.g. one of the possible actions it to resend an invitation email.',
	},
});
