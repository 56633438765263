import React, { useEffect, type ReactElement } from 'react';
import { LoadingButton } from '@atlaskit/button';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { type FlagId, toFlagId } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { usePathParam } from '@atlassian/jira-router';
import { useCommonPageContext } from '@atlassian/jira-servicedesk-common-page-context';
import type { Customer } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import { normalizeToUserProfile } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/utils.tsx';
import { useReinviteCustomerService } from '@atlassian/jira-servicedesk-customers-organization-common/src/services/reinvite-customers/index.tsx';
import { useResendVerificationEmailService } from '@atlassian/jira-servicedesk-customers-organization-common/src/services/resend-verification-email/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { PENDING_LOGIN, HAS_LOGGED_IN } from '../../../common/types/constants';
import { ResendInviteErrorFlag } from '../../../common/ui/resend-invite-error-flag';
import { ResendVerificationEmailErrorFlag } from '../../../common/ui/resend-verification-email-error-flag';
import messages from './messages';

type ActionsCellProps = {
	profile: Customer;
	onActionSuccess: (email: string, successAction: string) => void;
	onActionFailure: (id: FlagId, errorFlagComponent: ReactElement) => void;
};

const getCutoffDateForCustomerVerification = () =>
	getFeatureFlagValue('cut-off-date-for-tenants-for-customer-invite-verification-feature', '');

export const ActionsCellView = ({
	profile,
	onActionSuccess,
	onActionFailure,
}: ActionsCellProps) => {
	const { formatMessage } = useIntl();
	const { projectKey } = useCommonPageContext();
	const userProfile = normalizeToUserProfile(profile);
	const [organizationId] = usePathParam('organizationId');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		invitedSuccess,
		loading,
		error,
		errorMessage,
		fetch: inviteCustomersFetch,
		/* @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string' */
	} = useReinviteCustomerService(projectKey);

	const {
		resendVerificationEmailSuccess,
		resendVerificationEmailLoading,
		resendVerificationEmailError,
		resendVerificationEmailFetch,
		isRateLimited,
	} = useResendVerificationEmailService();

	const actionType = {
		resendVerificationEmail: 'resendVerificationEmail',
		resendInvite: 'resendInvite',
	};

	const { email, loggedInStatus, emailVerifiedStatus } = userProfile || { email: '' };

	useEffect(() => {
		if (error || resendVerificationEmailError) {
			if (error) {
				const id = toFlagId('resend-invite-error-flag');
				onActionFailure(id, <ResendInviteErrorFlag id={id} errorMessage={errorMessage} />);

				const analyticsEventForResendVerificationEmailLinkGenericError = createAnalyticsEvent({
					actionSubject: 'resendInvite',
					action: 'clicked GenericError',
				});
				fireUIAnalytics(analyticsEventForResendVerificationEmailLinkGenericError);
			} else if (resendVerificationEmailError) {
				const id = toFlagId('resend-verification-email-error-flag');
				onActionFailure(
					id,
					<ResendVerificationEmailErrorFlag id={id} isRateLimited={isRateLimited} />,
				);

				if (isRateLimited) {
					const analyticsEventForResendVerificationEmailLinkRateLimitError = createAnalyticsEvent({
						actionSubject: actionType.resendVerificationEmail,
						action: 'clicked RateLimitError',
					});
					fireUIAnalytics(analyticsEventForResendVerificationEmailLinkRateLimitError);
				} else {
					const analyticsEventForResendVerificationEmailLinkGenericError = createAnalyticsEvent({
						actionSubject: actionType.resendVerificationEmail,
						action: 'clicked GenericError',
					});
					fireUIAnalytics(analyticsEventForResendVerificationEmailLinkGenericError);
				}
			}
		} else if (invitedSuccess || resendVerificationEmailSuccess) {
			const successAction = invitedSuccess
				? actionType.resendInvite
				: actionType.resendVerificationEmail;
			onActionSuccess(email, successAction);
			if (resendVerificationEmailSuccess) {
				const analyticsEventForResendVerificationEmailLink = createAnalyticsEvent({
					actionSubject: actionType.resendVerificationEmail,
					action: 'clicked Success',
				});
				fireUIAnalytics(analyticsEventForResendVerificationEmailLink);
			}
		}
	}, [
		error,
		onActionSuccess,
		onActionFailure,
		invitedSuccess,
		email,
		errorMessage,
		resendVerificationEmailSuccess,
		resendVerificationEmailError,
		isRateLimited,
		actionType.resendInvite,
		actionType.resendVerificationEmail,
		createAnalyticsEvent,
	]);

	const tenantContext = useTenantContext();
	if (tenantContext) {
		const { firstActivationDateMs } = tenantContext;
		const cutOffDate = getCutoffDateForCustomerVerification();
		const isTenantActivationAfterCutOffDate =
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			new Date(firstActivationDateMs as number) >= new Date(cutOffDate);
		const isFeatureFlagEnabledToShowResendVerificationLink =
			ff('sptn-1697-email-verified-signal-on-customers-page_v2g52') &&
			isTenantActivationAfterCutOffDate;

		// logged status is Active and email is not verified, show resend email verification link
		const shouldShowResendVerificationEmail =
			loggedInStatus === HAS_LOGGED_IN && emailVerifiedStatus === false;

		if (isFeatureFlagEnabledToShowResendVerificationLink && shouldShowResendVerificationEmail) {
			return (
				<LoadingButton
					appearance="link"
					spacing="none"
					isLoading={resendVerificationEmailLoading}
					onClick={() => {
						resendVerificationEmailFetch(email);
					}}
				>
					{formatMessage(messages.resendVerificationEmailLabel)}
				</LoadingButton>
			);
		}
	}

	const shouldShowResendInviteLink = loggedInStatus === PENDING_LOGIN;

	if (!shouldShowResendInviteLink) {
		return null;
	}

	return (
		<LoadingButton
			appearance="link"
			spacing="none"
			isLoading={loading}
			onClick={() => {
				inviteCustomersFetch(email, organizationId);
			}}
		>
			{formatMessage(messages.resendInviteLinkLabel)}
		</LoadingButton>
	);
};
