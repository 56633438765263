import { useCallback, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { performPostRequest } from '@atlassian/jira-servicedesk-common/src/utils/fetch/requests.tsx';
import type { ValidationServiceError } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { State } from './types';

export const useReinviteCustomerService = (projectKey: ProjectKey): State => {
	const [loading, setLoading] = useState<boolean>(false);
	const [invitedSuccess, setInvitedSuccess] = useState<boolean>(false);
	const [error, setError] = useState<ValidationServiceError>(null);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fetch = useCallback(
		async (email: string, organizationId: string | undefined) => {
			setLoading(true);
			setError(null);
			setInvitedSuccess(false);
			setErrorMessage(null);
			try {
				await performPostRequest(
					`/rest/servicedesk/1/pages/people/customers/pagination/${projectKey}/invite/resend`,
					{
						body: JSON.stringify({ email, organizationId }),
					},
				);
				setInvitedSuccess(true);
				setLoading(false);

				fireOperationalAnalytics(createAnalyticsEvent({}), 'customerInvite success');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				const { response } = err;
				if (response?.failed?.length > 0) {
					setErrorMessage(response.failed[0].error);
				} else if (response?.errors?.length > 0) {
					setErrorMessage(response?.errors[0].errorMessage);
				}
				setLoading(false);
				setError(err);
				fireErrorAnalytics({
					event: createAnalyticsEvent({}),
					error: err,
					meta: {
						id: 'resendInviteButton',
						packageName: 'customerOrganisationCommon',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		},
		[createAnalyticsEvent, projectKey],
	);

	return {
		loading,
		error,
		invitedSuccess,
		errorMessage,
		fetch,
	};
};
