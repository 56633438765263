import React, { Component } from 'react';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type {
	IntlShapeV2 as IntlShape,
	MessageDescriptorV2 as MessageDescriptor,
} from '@atlassian/jira-intl/src/v2/types.tsx';

type HtmlFormattedMessageProps = MessageDescriptor & {
	values: {
		[key: string]: string;
	};
	intl: IntlShape;
};

/**
 * Helper class to safely add HTML to translation strings.
 *
 * All HTML MUST be injected via 'values' and not be included directly in the defaultMessages.
 *
 * Also note that in order to mitigate mistranslations this component will not replace any placeholders unless all placeholders
 * can be replaced.
 */
// eslint-disable-next-line jira/react/no-class-components
class HtmlFormattedMessage extends Component<HtmlFormattedMessageProps> {
	static defaultProps = {
		values: {},
	};

	componentDidUpdate() {
		this.renderTranslatedMessage(this.ref);
	}

	onRef = (ref: HTMLSpanElement) => {
		this.ref = ref;
		this.renderTranslatedMessage(ref);
	};

	ref: HTMLSpanElement | undefined;

	renderTranslatedMessage(ref: HTMLSpanElement | undefined) {
		if (ref) {
			const { values } = this.props;

			const { innerHTML } = ref;

			const containAllKeys = Object.keys(values).every((key) => innerHTML.indexOf(key) !== -1);

			if (containAllKeys) {
				let newHtmlContent = innerHTML;

				Object.keys(values).forEach((key) => {
					newHtmlContent = newHtmlContent.replace(`{${key}}`, values[key]);
				});

				// eslint-disable-next-line no-param-reassign
				ref.innerHTML = newHtmlContent;
			}
		}
	}

	render() {
		const { intl, id, defaultMessage, values } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const messageTags = Object.keys(values).reduce<Record<string, any>>((tags, key) => {
			// eslint-disable-next-line no-param-reassign
			tags[key] = `{${key}}`;
			return tags;
		}, {});

		return (
			<span ref={this.onRef} key={JSON.stringify(values)}>
				{intl.formatMessage(
					{
						id,
						defaultMessage,
					},
					messageTags,
				)}
			</span>
		);
	}
}

export default injectIntl(HtmlFormattedMessage);
