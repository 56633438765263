import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addToHelpCenterUpdated: {
		id: 'servicedesk-help-center-select-field.add-to-help-center-updated',
		defaultMessage: 'Choose help center',
		description: 'The text is shown as a label for helpCenter field',
	},
	info: {
		id: 'servicedesk-help-center-select-field.info',
		defaultMessage:
			'Customers will receive a link to this help center in the invitation email. However, they’ll still be able to access other help centers on your site.',
		description: 'The text is shown when user hovers over info icon in help center label',
	},
	placeHolder: {
		id: 'servicedesk-help-center-select-field.place-holder',
		defaultMessage: 'Choose a help center',
		description: 'The text is a placeholder for help center field',
	},
	somethingWentWrong: {
		id: 'servicedesk-help-center-select-field.something-went-wrong',
		defaultMessage: 'Something went wrong. Check your connection, then give it another try.',
		description: 'An error occurred in server while searching for help center.',
	},
});
