import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	publicSignUpAccessMessageJsm: {
		id: 'servicedesk-customers.access-message.public-sign-up-access-message-jsm',
		defaultMessage:
			'Anyone can raise a request in this service project. {tagStart} Change permissions. {tagEnd}',
		description:
			'Permissions description which tells JSM agents and admins that their service project is open for customers to sign up for their own accounts',
	},
	openAccessMessage: {
		id: 'servicedesk-customers.access-message.open-access-message',
		defaultMessage:
			'Everyone with an account on this Jira instance is a customer. {tagStart} Change permissions. {tagEnd}',
		description:
			'Everyone who has account on Jira instance can access to the portal and send requests',
	},
	adminAccessMessageJsm: {
		id: 'servicedesk-customers.access-message.admin-access-message-jsm',
		defaultMessage:
			'This service project is open to customers who are added to the project. {tagStart} Change permissions. {tagEnd}',
		description: 'information label for permissions message',
	},
	publicSignUpAccessMessageJsmNew: {
		id: 'servicedesk-customers.access-message.public-sign-up-access-message-jsm-new',
		defaultMessage:
			'Anyone can raise a request in this service project. <a>Change permissions.</a>',
		description:
			'Permissions description which tells JSM agents and admins that their service project is open for customers to sign up for their own accounts',
	},
	openAccessMessageNew: {
		id: 'servicedesk-customers.access-message.open-access-message-new',
		defaultMessage:
			'Everyone with an account on this Jira instance is a customer. <a>Change permissions.</a>',
		description:
			'Everyone who has account on Jira instance can access to the portal and send requests',
	},
	adminAccessMessageJsmNew: {
		id: 'servicedesk-customers.access-message.admin-access-message-jsm-new',
		defaultMessage:
			'This service project is open to customers who are added to the project. <a>Change permissions.</a>',
		description: 'information label for permissions message',
	},
});
