import React, { type SyntheticEvent } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ButtonGroup from '@atlaskit/button/button-group';
import LoadingButton from '@atlaskit/button/loading-button';
import Button from '@atlaskit/button/standard-button';
import { FormFooter } from '@atlaskit/form';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages';

export type Props = {
	isLoading: boolean;
	isFormValid: boolean;
	onSubmit?: (event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
	onCancel?: (event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
	intl: IntlShape;
};

export const FooterView = ({
	intl: { formatMessage },
	isFormValid = false,
	isLoading = false,
	onSubmit = noop,
	onCancel = noop,
}: Props) => (
	<FormFooter align="start">
		<ButtonGroup>
			<LoadingButton
				appearance="primary"
				type="submit"
				isLoading={isLoading}
				isDisabled={!isFormValid}
				testId="servicedesk-customers-organization-common.common.ui.inline-dialog.footer.submit-button"
				onClick={onSubmit}
			>
				{formatMessage(messages.add)}
			</LoadingButton>
			<Button
				appearance="link"
				onClick={onCancel}
				testId="servicedesk-customers-organization-common.common.ui.inline-dialog.footer.cancel-button"
			>
				{formatMessage(messages.cancel)}
			</Button>
		</ButtonGroup>
	</FormFooter>
);

export const Footer = injectIntl(FooterView);
