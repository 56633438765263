import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { CustomError } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';

export const isNetworkErrorCode = (statusCode: number): boolean => statusCode === 0;

export const isClientErrorCode = (statusCode: number): boolean =>
	statusCode >= 400 && statusCode < 500;

export const isNetworkOrClientErrorCode = (statusCode: number): boolean =>
	isNetworkErrorCode(statusCode) || isClientErrorCode(statusCode);

export const logError = (err: CustomError) => {
	if (isNetworkOrClientErrorCode(Number(err.statusCode))) {
		return;
	}
	fireErrorAnalytics({
		meta: {
			id: 'helpCenterFetchService',
			packageName: 'jiraServicedeskCustomersOrganizationCommon',
			teamName: 'boysenberry',
		},
		error: err,
		sendToPrivacyUnsafeSplunk: true,
	});
};

export const splitInputTextBySeparator = (input = ''): string[] => {
	const substrings = input.split(/[\s,;]+/g);
	return substrings.filter((value) => value.trim());
};

/**
 * abortControllerUtils to abort previous fetch request.
 * getAbortSignal returns new signal.
 */
export const abortControllerUtils = (() => {
	let abortController: AbortController;

	const getAbortSignal = () => {
		if (abortController) {
			abortController.abort();
		}

		// AbortController is only supported from Node.js 15
		// and throws error when run in SSR (Node.js 12)
		try {
			abortController = new AbortController();
			return abortController.signal;
		} catch {
			return null;
		}
	};

	return {
		getAbortSignal,
	};
})();

export const isHttpClientErrorResponse = (statusCode: number): boolean =>
	statusCode === 0 || (statusCode >= 400 && statusCode < 500);
