import React, { useCallback } from 'react';
import AkEmptyState from '@atlaskit/empty-state';
import { Inline } from '@atlaskit/primitives';
import { DirHeaderActionsContainer } from '@atlassian/jira-horizontal-nav-jsm-directory/src/ui/dir-header-actions-container.tsx';
import TaskBoardWithPeople from '@atlassian/jira-illustrations/src/ui/adg4/jira/heroes/components/task-board-with-people';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout/src/common/utils/get-will-show-nav4/index.tsx';
import {
	useAnalyticsEvents,
	FireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { appBundleReadyMark } from '@atlassian/jira-providers-spa-apdex-analytics/src/marks';
import { JsmPageInteractiveEvent } from '@atlassian/jira-servicedesk-common/src/utils/browser-metric/index.tsx';
import { useIsJCS } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs';
import { debounce } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/utils/index.tsx';
import { customersSearchPageLoadExperience } from '@atlassian/jira-servicedesk-customers-organization-common/src/experiences.tsx';
import { ErrorView as ErrorViewWithAnalytics } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/error-view/index.tsx';
import { Search } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/search/index.tsx';
import { useAppStore } from '@atlassian/jira-servicedesk-customers/src/controllers/app-store/index.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { customersPageLoad } from '../utils/performance-analytics';
import { HeaderActions } from './header-actions';
import messages from './messages';
import { TableWithActions } from './table';

// Use longer-than-normal debounce because this is a costly
// endpoint which triggers alerts.  See JIG-335
const DEBOUNCE_MILLISECONDS = 500;
const SearchWithResultsView = ({ intl: { formatMessage } }: Intl) => {
	const [
		{
			searchString,
			loading,
			pageData,
			userPermissions: {
				// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
				projectSimplified,
				// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
				serviceDeskOpenAccess,
				// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
				canAdminister: canAdministerProject,
			} = {},
			error,
		},
		{ updateSearchString, updatePageNumber, fetchAndUpdatePageData },
	] = useAppStore();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { total, query } = pageData || {};
	const shouldShowSearchTip = !loading && Boolean(pageData || error) && Boolean(searchString);
	const showEmptyState = !loading && !total && !query;
	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceSearchAction = useCallback(
		debounce(
			// @ts-expect-error - TS2345 - Argument of type '() => void | Promise<void>' is not assignable to parameter of type '(...rest: readonly any[]) => Promise<any>'.
			fetchAndUpdatePageData.bind(null, { createAnalyticsEvent }),
			DEBOUNCE_MILLISECONDS,
		),
		[],
	);

	const isEditable = projectSimplified && !serviceDeskOpenAccess && canAdministerProject;
	const isJCS = useIsJCS();

	const onSearchChange = (value: string) => {
		updateSearchString(value);
		debounceSearchAction();
		customersSearchPageLoadExperience.start();
	};

	const onPageChange = (value: number) => {
		updatePageNumber(value);
		debounceSearchAction();
		customersSearchPageLoadExperience.start();
	};

	if (error) {
		customersSearchPageLoadExperience.failure();
		return <ErrorViewWithAnalytics />;
	}

	if (showEmptyState) {
		return (
			<>
				{getWillShowNav4() && (
					<Inline alignInline="end">
						<HeaderActions />
					</Inline>
				)}
				<FireOperationalAnalytics eventName="customersOrgsEmptyState loaded" />
				<AkEmptyState
					header=""
					maxImageWidth={320}
					maxImageHeight={300}
					description={formatMessage(messages.noCustomersJsm)}
					renderImage={getAkEmptyStateRenderImageFn(TaskBoardWithPeople)}
				/>
			</>
		);
	}

	return (
		<>
			<DirHeaderActionsContainer headerActions={<HeaderActions />}>
				<Search
					shouldShowSearchTip={shouldShowSearchTip}
					onChange={onSearchChange}
					query={searchString}
					placeholder={formatMessage(messages.searchPlaceholder)}
					description={formatMessage(messages.searchDescription)}
				/>
			</DirHeaderActionsContainer>
			<TableWithActions
				setPageData={fetchAndUpdatePageData}
				onPageChange={onPageChange}
				loading={loading}
				pageData={pageData}
				isEditable={isEditable}
				hasActions={!isJCS}
			/>
			<JsmPageInteractiveEvent
				metricKey="servicedesk.customers"
				withMarks={[appBundleReadyMark]}
				appName="servicedesk.customers"
				// @ts-expect-error - TS2322 - Type 'PageLoadMetric' is not assignable to type 'BM3Metric'.
				metric={customersPageLoad}
				extra={{ version: 2 }}
			/>
		</>
	);
};

SearchWithResultsView.displayName = 'SearchWithResults';

export const SearchWithResults = injectIntl(SearchWithResultsView);
