import React from 'react';
import withInjectedProps from '@atlassian/jira-common-util-with-injected-props';
import { ff } from '@atlassian/jira-feature-flagging';
import { DocumentTitle as DocumentTitleDI } from '@atlassian/jira-global-document-title/src';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import messages from './messages';
import type { Props } from './types';

export const PageTitle = (props: Props) => {
	const {
		DocumentTitle,
		section,
		intl: { formatMessage },
		projectName,
		subsection,
	} = props;

	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ proformaForms: MessageDescriptorV2; chat: MessageDescriptorV2; incidentManagementSettings: MessageDescriptorV2; ... 32 more ...; serviceProject: MessageDescriptorV2; }'.
	const titleOld = `${projectName} - ${formatMessage(messages[section])} - ${formatMessage(messages.serviceProject)}`;

	const titleNew = subsection
		? // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ proformaForms: MessageDescriptorV2; chat: MessageDescriptorV2; incidentManagementSettings: MessageDescriptorV2; ... 32 more ...; serviceProject: MessageDescriptorV2; }'.
			`${projectName} - ${formatMessage(messages[section])} - ${subsection} - ${formatMessage(messages.serviceProject)}`
		: // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ proformaForms: MessageDescriptorV2; chat: MessageDescriptorV2; incidentManagementSettings: MessageDescriptorV2; ... 32 more ...; serviceProject: MessageDescriptorV2; }'.
			`${projectName} - ${formatMessage(messages[section])} - ${formatMessage(messages.serviceProject)}`;

	const title = ff('a11y-fission-queues-related-issues_giuuk') ? titleNew : titleOld;

	return <DocumentTitle title={title} />;
};

const InjectedPage = withInjectedProps(PageTitle, {
	DocumentTitle: DocumentTitleDI,
});

export const ServiceDeskDocumentTitle = injectIntl(InjectedPage);
export default ServiceDeskDocumentTitle;
