import debounceFunc from 'lodash/debounce';

export const DEBOUNCE_INTERVAL = 300;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...rest: ReadonlyArray<any>) => Promise<any>>(
	callBack: F,
	timeout: number = DEBOUNCE_INTERVAL,
	// @ts-expect-error - TS2322 - Type 'DebouncedFunc<F>' is not assignable to type 'F'.
): F => debounceFunc(callBack, timeout);
