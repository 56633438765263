import React, { useMemo, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import uuid from 'uuid';
import { token } from '@atlaskit/tokens';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { SearchField } from '@atlassian/jira-searchfield';
import messages from './messages';

type Props = {
	shouldShowSearchTip: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SearchTip: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	StyledSearchContainer: ComponentType<Record<any, any>>;
	query: string;
	placeholder: string;
	description?: string;
	onChange: (query: string) => void;
	intl: IntlShape;
};

const SearchView = ({
	StyledSearchContainer,
	SearchTip,
	shouldShowSearchTip,
	onChange,
	placeholder,
	description,
	query,
	intl: { formatMessage },
}: Props) => {
	const descriptionId = useMemo(() => uuid(), []);

	return (
		<StyledSearchContainer>
			<SearchField
				placeholder={placeholder}
				isAlwaysExpanded
				increasedWidth
				onChange={onChange}
				value={query}
				placeholderAlwaysVisible
				isAutocompleteDisabled
				describedById={description && descriptionId}
			/>
			{shouldShowSearchTip && (
				<SearchTip data-test-id="servicedesk-customers-organization-common.ui.search.search-tip">
					{formatMessage(messages.searchTip)}
				</SearchTip>
			)}
			{description && (
				<span id={descriptionId} hidden>
					{description}
				</span>
			)}
		</StyledSearchContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SearchTipDI = styled.p({
	display: 'inline-block',
	marginLeft: token('space.200', '16px'),
	textAlign: 'center',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledSearchContainerDI = styled.div({
	paddingTop: token('space.250', '20px'),
});

SearchView.defaultProps = {
	StyledSearchContainer: StyledSearchContainerDI,
	SearchTip: SearchTipDI,
	shouldShowSearchTip: false,
};

export const Search = injectIntl(SearchView);
