import React from 'react';
import isArray from 'lodash/isArray';
import { ErrorMessage as AkErrorMessage } from '@atlaskit/form';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type {
	ValidationServiceError,
	ServiceError,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import messages from './messages';

export type Props = {
	isError: boolean;
	formErrors: ValidationServiceError;
	serviceError?: ServiceError;
	intl: IntlShape;
};

export const FormErrorsView = ({
	intl: { formatMessage },
	isError,
	formErrors,
	serviceError,
}: Props) => {
	if (formErrors || isError) {
		if (formErrors && formErrors.errors && isArray(formErrors.errors)) {
			const { errors } = formErrors;
			return (
				<>
					{errors.map(({ error: { errorMessage } }) => (
						<AkErrorMessage key={errorMessage}>{errorMessage}</AkErrorMessage>
					))}
				</>
			);
		}

		return <AkErrorMessage>{formatMessage(messages.genericErrorText)}</AkErrorMessage>;
	}

	if (serviceError) {
		// @ts-expect-error - TS2339 - Property 'reasonCode' does not exist on type 'Error | ValidationErrors | { statusCode: number; message?: string | undefined; }'.
		if (serviceError.reasonCode === '401') {
			return <AkErrorMessage>{formatMessage(messages.loggedOut)}</AkErrorMessage>;
		}
		return <AkErrorMessage>{formatMessage(messages.genericErrorText)}</AkErrorMessage>;
	}

	return null;
};

FormErrorsView.defaultProps = {
	isError: false,
	formErrors: null,
};

export const FormErrors = injectIntl(FormErrorsView);
