import React, { type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import AkTextArea from '@atlaskit/textarea';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type MultiLineTextProps = {
	isInvalid: boolean;
	isMultiline?: boolean;
	isValidating: boolean;
	value: string;
	onKeyDown: (event: KeyboardEvent) => void;
	setEmailOnChange: (input: string) => void;
};

export const MultiLineText = ({
	isInvalid,
	isMultiline = false,
	isValidating,
	value,
	onKeyDown,
	setEmailOnChange,
}: MultiLineTextProps) => {
	const { formatMessage } = useIntl();

	return (
		<HybridTextArea isMultiline={isMultiline}>
			<AkTextArea
				aria-label={formatMessage(messages.placeHolder)}
				resize="vertical"
				name="customer-emails-multiple"
				value={value}
				onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setEmailOnChange(event.target.value)}
				placeholder={formatMessage(messages.placeHolder)}
				isCompact
				// @ts-expect-error - TS2322 - Type '(event: KeyboardEvent) => void' is not assignable to type 'KeyboardEventHandler<HTMLTextAreaElement>'.
				onKeyDown={onKeyDown}
				isReadOnly={isValidating}
				isInvalid={isInvalid}
				testId="servicedesk-customers-organization-common.common.ui.multiline-text.dynamic-input"
			/>
		</HybridTextArea>
	);
};

type HybridTextAreaProps = { isMultiline?: boolean };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const HybridTextArea = styled.div<HybridTextAreaProps>((props: HybridTextAreaProps) =>
	props.isMultiline
		? {
				textarea: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
					minHeight: '8.813em !important',
				},
			}
		: {
				textarea: {
					resize: 'none',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
					padding: `${token('space.100', '8px')} !important`,
					height: '2.87em',
				},
			},
);
