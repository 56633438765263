export const SEARCH_PER_PAGE = 20;
export const LIST_PER_PAGE = 50;
export const DEFAULT_PAGE_DATA = {
	total: 0,
	customers: [],
	rowsPerPage: LIST_PER_PAGE,
	page: 1,
	totalHasBeenDetermined: true,
	lastDeterminedPaged: 1,
	query: '',
};
