import { useState, useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performPostRequest } from '@atlassian/jira-servicedesk-common/src/utils/fetch/requests.tsx';
import type { BaseUrl, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { removeOrganizationExperience } from '../../experiences';
import type { State } from './types';
import { getUrl } from './utils';

export const useRemoveOrganizationService = (baseUrl: BaseUrl, projectId: ProjectId): State => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<unknown>(null);
	const [error, setError] = useState<unknown>(null);

	const fetch = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
		async (organizationId: number, analyticsEvent) => {
			setLoading(true);
			setError(null);

			removeOrganizationExperience.start();

			try {
				await performPostRequest(getUrl(baseUrl, projectId, organizationId));
				setData(organizationId);
				setLoading(false);
				fireTrackAnalytics(analyticsEvent, 'organization removed', `${organizationId}`);
				removeOrganizationExperience.success();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				setLoading(false);
				setError(err);
				// Only fire analytics for unexpected errors. In this case the backend really
				// only returns a 403 if the current user doesn't have permission to manage
				// organizations. I'm not sure how to replicate that situation so I'll leave it as
				// unexpected, based on an assumption that such users wouldn't see the delete button
				fireErrorAnalytics({
					error: err,
					meta: {
						id: 'useRemoveOrganizationService',
						packageName: 'jiraServicedeskCustomersOrganizationCommon',
						teamName: 'boysenberry',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
				removeOrganizationExperience.failure();
			}
		},
		[baseUrl, projectId],
	);

	return {
		loading,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type '((Error | ValidationErrors | { statusCode: number; message?: string | undefined; }) & ValidationErrors) | null'.
		error,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'ServiceData<Data>'.
		data,
		// @ts-expect-error - TS2322 - Type '(organizationId: number, analyticsEvent: any) => Promise<void>' is not assignable to type '(() => Promise<void>) & ((organizationId: number, analyticsEvent: UIAnalyticsEventInterface) => Promise<void>)'.
		fetch,
	};
};
