import React, { type ReactNode } from 'react';

type Props = {
	canAdminister?: boolean;
	canManageOrganizations?: boolean;
	children: ReactNode;
};

export const CanManageOrganization = ({
	canAdminister,
	canManageOrganizations,
	children,
}: Props) => {
	if (canAdminister === true || canManageOrganizations === true) {
		return <>{children}</>;
	}
	return null;
};
