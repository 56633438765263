import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	inOtherProjects: {
		id: 'servicedesk-customers-organization-common.common.organizations-select-field.in-other-projects',
		defaultMessage: 'in {numberOfOtherProjects, plural, one {# project} other { # other projects}}',
		description: 'Organization status label whether organization is used in other projects',
	},
	somethingWentWrong: {
		id: 'servicedesk-customers-organization-common.common.organizations-select-field.something-went-wrong',
		defaultMessage: 'Something went wrong. Check your connection, then give it another try.',
		description: 'An error occurred in server while searching for user.',
	},
});
