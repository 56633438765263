import { useState, useCallback } from 'react';

export const usePopupController = (defaultState = false) => {
	const [isOpen, setIsOpen] = useState(defaultState);

	const togglePopup = () => {
		setIsOpen((prev) => !prev);
	};

	const closePopup = useCallback(() => setIsOpen(false), []);

	return [isOpen, togglePopup, closePopup] as const;
};
