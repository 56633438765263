import React, { useState, useMemo, type ReactNode, useEffect, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import uuid from 'uuid';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, type AnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { ANALYTICS_EVENT_ACTION, ANALYTICS_EVENT_SUBJECT, ANALYTICS_PAGE_CLICK } from './constants';
import messages from './messages';
import type { Props, OnPaginationChange, PaginationRangeProps, PaginationDirection } from './types';

const renderLeftEllipsis = (currentBatch: number) => (
	<BackupWrapper hide={currentBatch === 1} direction="left">
		...
	</BackupWrapper>
);

const renderRightEllipsis = (hideEllipsis: boolean) => (
	<BackupWrapper hide={hideEllipsis} direction="right">
		...
	</BackupWrapper>
);

const renderChevron = (
	selectedIndex: number,
	pages: number | undefined,
	onClick: OnPaginationChange,
	direction: PaginationDirection,
) => {
	const onChevronClick = (event: SyntheticEvent, analyticsEvent: AnalyticsEvent): void => {
		const pageNumber = direction === 'LEFT' ? selectedIndex - 1 : selectedIndex + 1;
		fireUIAnalytics(analyticsEvent, ANALYTICS_PAGE_CLICK, {
			pageNumber,
		});
		onClick(pageNumber, analyticsEvent);
	};

	const isDisabled = () => {
		if (direction === 'LEFT') {
			return selectedIndex <= 0;
		}
		if (direction === 'RIGHT') {
			return selectedIndex >= (pages ? pages - 1 : 0);
		}

		return true;
	};

	return (
		<Button
			onClick={(event, analyticsEvent: AnalyticsEvent) => onChevronClick(event, analyticsEvent)}
			appearance="subtle"
			spacing="compact"
			isDisabled={isDisabled()}
			testId={`servicedesk-customers-organization-common.ui.table.bracket-pagination.${direction.toLowerCase()}-button`}
		>
			{direction === 'LEFT' ? (
				<ChevronLeftLargeIcon label="previous" />
			) : (
				<ChevronRightLargeIcon label="next" />
			)}
		</Button>
	);
};

const renderMarkup = (
	selectedIndex: number,
	pages: number,
	currentBatch: number,
	onPaginationChange: OnPaginationChange,
	bracketSize: number,
	formatMessage: ReturnType<typeof useIntl>['formatMessage'],
): ReactNode[] => {
	const onClick = (_: SyntheticEvent, analyticsEvent: AnalyticsEvent, index: number): void => {
		fireUIAnalytics(analyticsEvent, ANALYTICS_PAGE_CLICK, {
			pageNumber: index,
		});
		onPaginationChange(index, analyticsEvent);
	};
	const markupBatch: ReactNode[] = [];
	let batches: ReactNode[] = [];

	Array.from({ length: pages }, (_, index: number): number => index).forEach(
		(value: number): void => {
			const isSelected = value === selectedIndex;
			const pageNumber = value + 1;

			batches.push(
				<NumberWrapper
					key={`pagination-${value}`}
					data-testId="servicedesk-customers-organization-common.ui.table.bracket-pagination.page-number"
				>
					<Button
						onClick={(event, analyticsEvent: AnalyticsEvent) =>
							onClick(event, analyticsEvent, value)
						}
						appearance="subtle"
						spacing="compact"
						isSelected={isSelected}
						aria-label={formatMessage(
							isSelected ? messages.pageLabelSelected : messages.pageLabel,
							{
								pageNumber,
							},
						)}
					>
						{pageNumber}
					</Button>
				</NumberWrapper>,
			);
			if (pageNumber % bracketSize === 0) {
				markupBatch.push(<MarkupWrapper>{batches}</MarkupWrapper>);
				batches = [];
			}
		},
	);
	if (batches.length > 0) {
		// add remaining item in the batch
		markupBatch.push(<MarkupWrapper>{batches}</MarkupWrapper>);
		batches = [];
	}

	return markupBatch;
};

const renderFirstPageLink = (
	selectedIndex: number,
	onPaginationChange: OnPaginationChange,
): ReactNode => {
	const onClick = (event: SyntheticEvent, analyticsEvent: AnalyticsEvent, index: number): void => {
		fireUIAnalytics(analyticsEvent, ANALYTICS_PAGE_CLICK, {
			pageNumber: index,
		});
		onPaginationChange(index, analyticsEvent);
	};

	return (
		<NumberWrapper
			key={`pagination-first-${1}`}
			data-testId="servicedesk-customers-organization-common.ui.table.bracket-pagination.page-number"
		>
			<Button
				onClick={(event, analyticsEvent: AnalyticsEvent) => onClick(event, analyticsEvent, 0)}
				appearance="subtle"
				spacing="compact"
				isSelected={selectedIndex === 0}
			>
				{1}
			</Button>
		</NumberWrapper>
	);
};

export const PaginationCollapseRange = (props: PaginationRangeProps) => {
	const { formatMessage } = useIntl();
	const descriptionId = useMemo(() => uuid(), []);

	const { currentBatch, selectedIndex, pages, onChange, bracketSize, hideRightEllipsis } = props;
	const hideEllipsis =
		undefined === hideRightEllipsis ? selectedIndex >= (pages ? pages - 1 : 0) : hideRightEllipsis;

	return (
		<PaginationContainerWrapper
			as="nav"
			aria-label={formatMessage(messages.navigationLabel)}
			aria-describedby={descriptionId}
		>
			<span id={descriptionId} hidden>
				{formatMessage(messages.navigationDescription)}
			</span>

			{renderChevron(selectedIndex, pages, onChange, 'LEFT')}
			{selectedIndex + 1 > bracketSize ? renderFirstPageLink(selectedIndex, onChange) : ''}
			{renderLeftEllipsis(currentBatch)}
			{
				renderMarkup(selectedIndex, pages ?? 0, currentBatch, onChange, bracketSize, formatMessage)[
					currentBatch - 1
				]
			}
			{renderRightEllipsis(hideEllipsis)}
			{renderChevron(selectedIndex, pages, onChange, 'RIGHT')}
		</PaginationContainerWrapper>
	);
};

PaginationCollapseRange.defaultProps = {
	currentBatch: 1,
};

const BracketPagination = (props: Props) => {
	// isPaginationDisabled is passed because for any click bucket change happens,
	// and in case of fast click where things are still loading we don't want any action to happen
	// This cant be controlled at table layer because bucket logic exists in pagination
	// https://jdog.jira-dev.com/browse/DWP-297
	const { pages, selectedIndex, bracketSize, onChange, isDisabled, hideRightEllipsis } = props;
	// Batch is 1 based index
	const [currentBatch, updateBatch] = useState<number>(
		Math.ceil((selectedIndex + 1) / bracketSize),
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: ANALYTICS_EVENT_ACTION,
		actionSubject: ANALYTICS_EVENT_SUBJECT,
	});

	useEffect(() => {
		if (!isDisabled) {
			updateBatch(Math.ceil((selectedIndex + 1) / bracketSize));
		}
	}, [selectedIndex, bracketSize, isDisabled]);

	const onPaginationChange: OnPaginationChange = (newIndex): void => {
		if (!isDisabled) {
			fireUIAnalytics(analyticsEvent, ANALYTICS_PAGE_CLICK, {
				pageNumberRequested: newIndex + 1,
			});
			onChange(newIndex);
		}
	};

	return (
		<PaginationCollapseRange
			selectedIndex={selectedIndex}
			currentBatch={currentBatch}
			pages={pages}
			bracketSize={bracketSize}
			onChange={onPaginationChange}
			hideRightEllipsis={hideRightEllipsis}
		/>
	);
};

BracketPagination.defaultProps = {
	bracketSize: 5,
};
export default BracketPagination;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MarkupWrapper = styled.div<{
	children?: ReactNode;
}>({
	display: 'inline-flex',
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BackupWrapper = styled.div<{
	children?: ReactNode;
	['data-test-id']?: string;
	hide?: boolean;
	direction: string;
}>({
	alignSelf: 'center',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: (props: any) => (props.hide ? 'none' : 'inline-block'),
	padding: `0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NumberWrapper = styled.div<{
	children?: ReactNode;
	['data-test-id']?: string;
}>({
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: (props: any) => (props.hide ? 'hidden' : 'visible'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PaginationContainerWrapper = styled.div<{
	children?: ReactNode;
}>({
	display: 'inline-flex',
	width: 'auto',
	justifyContent: 'center',
	alignContent: 'center',
	gridColumnStart: 1,
	gridRowStart: 1,
	justifySelf: 'right',
	bottom: token('space.0', '0'),
});
