import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import { onInit, actions } from './actions';
import type { State, Actions, Props } from './types';

export const initialState: State = {
	userPermissions: undefined,
	searchString: '',
	page: 1,
	pageData: undefined,
	loading: false,
	error: null,
};

export const store = createStore<State, Actions>({
	name: 'CustomerAndOrganizationStore',
	initialState,
	actions,
});

export const useAppStore = createHook(store);

export const AppStoreContainer = createContainer<State, Actions, Props>(store, {
	displayName: 'CustomerAndOrganizationContainer',
	onInit,
});
