import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	genericErrorText: {
		id: 'servicedesk-customers-organization-common.common.inline-dialog.form-errors.generic-error-text',
		defaultMessage: "We're having trouble. Check your connection and try again.",
		description: 'Inline error text when we are not sure what went wrong',
	},
	loggedOut: {
		id: 'servicedesk-customers-organization-common.common.inline-dialog.form-errors.logged-out',
		defaultMessage: 'You are not currently logged in. Please log in to perform this action.',
		description: 'Inline error text when user is logged out.',
	},
});
