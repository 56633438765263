// Trim empty space and new lines
const trimAll = (str: string) =>
	str
		.replace('\n', '')
		.split(',')
		.map((e) => e.trim())
		.filter(Boolean);

// Inserts new lines to the input in between comma-separated values for display in a text area
const newLineForText = (str: string) =>
	str
		.split(/[;,\n]+/)
		.map((e) => e.trim())
		.join(',\n');

// check if email input value length is > 3 and is seperated BY [',' , ';' , '\n']
const hasMultipleEmails = (email: string) => {
	const moreDelineators = new RegExp(/[;,\n]+/);
	const hasDelineators = email.match(moreDelineators) !== null;

	return email.length > 3 && hasDelineators;
};

export { trimAll, newLineForText, hasMultipleEmails };
