import React from 'react';
import type { Organizations } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/organizations/types.tsx';
import { Footer as DialogFooter } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/inline-dialog/footer/index.tsx';

export type Props = {
	isLoading: boolean;
	organizations: Organizations;
	onCancel: () => void;
};

export const Footer = ({ isLoading, organizations, onCancel }: Props) => {
	const isFormValid = organizations && organizations.length > 0;
	return <DialogFooter isFormValid={isFormValid} isLoading={isLoading} onCancel={onCancel} />;
};

Footer.defaultProps = {
	isLoading: false,
};
