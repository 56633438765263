import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout/src/common/utils/get-will-show-nav4/index.tsx';
import {
	UNSAFE_DO_NOT_USE_BANNER_HEIGHT,
	UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT,
} from '@atlassian/jira-navigation-system';
import { useCommonPageContext as useCommonPageContextDI } from '@atlassian/jira-servicedesk-common-page-context';
import { JsmPageInteractiveEvent } from '@atlassian/jira-servicedesk-common/src/utils/browser-metric/index.tsx';
import { Header } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/header/index.tsx';
import { useAppStore } from '@atlassian/jira-servicedesk-customers/src/controllers/app-store/index.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { AccessMessage } from './access-message';
import { HeaderActions } from './header-actions';
import { SearchWithResults } from './main';
import messages from './messages';

type Props = {
	useCommonPageContext?: typeof useCommonPageContextDI;
} & Intl;

const Customers = ({
	useCommonPageContext = useCommonPageContextDI,
	intl: { formatMessage },
}: Props) => {
	const { baseUrl, projectKey, isProjectSimplified } = useCommonPageContext();

	const [{ userPermissions }, { onResetSearchState }] = useAppStore();

	useEffect(
		() => () => {
			onResetSearchState();
		},
		[onResetSearchState],
	);

	return (
		<PageWrapper>
			{!getWillShowNav4() && (
				<Header actions={<HeaderActions />}>
					{formatMessage(messages.customersBreadcrumbTitle)}
				</Header>
			)}
			<AccessMessage
				// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
				baseUrl={baseUrl}
				// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
				projectKey={projectKey}
				userPermissions={userPermissions}
				// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
				isProjectSimplified={isProjectSimplified}
			/>
			<SearchWithResults />
			<JsmPageInteractiveEvent />
		</PageWrapper>
	);
};

export const CustomersApp = injectIntl(Customers);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `calc(100vh - ${UNSAFE_DO_NOT_USE_BANNER_HEIGHT} - ${UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT})`,
});
