import React, { type ReactNode } from 'react';
import Button from '@atlaskit/button/standard-button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';

type InputType = {
	input: string;
	onRemoved: (email: string) => unknown;
};

const InputLists = ({ input, onRemoved }: InputType) => {
	const key = `${input}_${Math.random()}`;

	return (
		<Box xcss={inputStyles} key={key}>
			<Textfield
				aria-label="default text field"
				value={input}
				isReadOnly
				elemAfterInput={
					<Button
						aria-label="Remove"
						title="Remove"
						appearance="subtle-link"
						iconAfter={<CrossIcon label="remove" size="small" />}
						spacing="compact"
						onClick={() => onRemoved(input)}
					/>
				}
			/>
		</Box>
	);
};

export type InputListProps = {
	inputs: string[];
	onRemoved: (email: string) => unknown;
};
export const InputListView = (props: InputListProps) => {
	const lists = props.inputs;
	const allLists: ReactNode[] = [];

	lists.forEach((input: string) => {
		allLists.push(<InputLists input={input} key={Math.random()} onRemoved={props.onRemoved} />);
	});

	return (
		<div data-testid="servicedesk-customers-organization-common.ui.add-customers-dialog.form.fields.dynamic-input-field.input-lists.valid-input-list">
			{allLists}
		</div>
	);
};

export const InputListFields = injectIntl(InputListView);

const inputStyles = xcss({
	display: 'flex',
	position: 'relative',
	marginBottom: 'space.050',
	alignItems: 'center',
});
