import React from 'react';
import { FireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { GenericError } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/errors';

export const ErrorView = () => (
	<>
		<FireOperationalAnalytics eventName="customersOrgsErrorView loaded" />
		<GenericError />
	</>
);
