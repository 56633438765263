import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/async';
import { VIEW_PROFILE_ACTION } from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import type { User } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { LozengeGroup } from './lozenge-group';
import messages from './messages';

type UserCellProps = {
	profile: User;
} & Intl;

const UserCellView = ({
	profile: { identifier, avatarUrl, displayName, email, organisationNames },
	intl: { formatMessage },
}: UserCellProps) => {
	const privateEmail = formatMessage(messages.privateEmail);

	return (
		<AsyncProfileCardNext
			accountId={identifier}
			TenantContextSubscriber={TenantContextSubscriber}
			actions={[VIEW_PROFILE_ACTION]}
		>
			<Box
				xcss={wrapperStyles}
				testId="servicedesk-customers-organization-common.ui.table.customer-cell.user.user-cell"
			>
				<Box xcss={agentStyles}>
					<Avatar src={avatarUrl} size="small" borderColor="transparent" />
					<Box xcss={emailStyles}>
						<Inline as="span" xcss={labelStyles}>
							{displayName}
						</Inline>
						<Inline as="span" xcss={[labelStyles, privateEmailStyles]}>
							{email || privateEmail}
						</Inline>
					</Box>
					<LozengeGroup organizationNames={organisationNames} />
				</Box>
			</Box>
		</AsyncProfileCardNext>
	);
};

export const UserCell = injectIntl(UserCellView);

const wrapperStyles = xcss({
	padding: 'space.050',
	display: 'flex',
	textAlign: 'left',
});

const agentStyles = xcss({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	border: 0,
	borderRadius: 'border.radius',
	paddingTop: 'space.0',
	paddingBottom: 'space.0',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4.5}px`,
	overflowX: 'hidden',
});

const labelStyles = xcss({
	lineHeight: '1.4',
	paddingLeft: 'space.050',
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	textAlign: 'left',
});

const emailStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
});

const privateEmailStyles = xcss({
	font: token('font.body.small'),
	color: 'color.text.subtlest',
});

// These are still being imported in other packages...
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const StyledAgent = styled.div<{ type: String }>({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	border: 0,
	borderRadius: '3px',
	padding: `0 ${token('space.050', '4px')}`,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4.5}px`,
	overflowX: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div({
	padding: token('space.050', '4px'),
	display: 'flex',
	textAlign: 'left',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Label = styled.span({
	lineHeight: '1.4',
	paddingLeft: token('space.050', '4px'),
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	textAlign: 'left',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmailWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// These are still being imported in other packages...
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PrivateEmailLabel = styled(Label)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
	font: token('font.body.small', fontFallback.body.small),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N90),
});
