import React, { useEffect, useState } from 'react';
import { graphql, usePreloadedQuery, type PreloadedQuery } from 'react-relay';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { extendedFormContentMultiHcEntitlementQuery as entitlementQuery } from '@atlassian/jira-relay/src/__generated__/extendedFormContentMultiHcEntitlementQuery.graphql';
import { isHttpClientErrorResponse } from '../../../../common/utils';

type MultiHelpCenterViewProps = {
	queryRef: PreloadedQuery<entitlementQuery, Record<string, unknown>>;
	render: (props: { hasMultiHcAndInviteRouting: boolean }) => React.ReactNode;
};

const logError = (err: Error) => {
	fireErrorAnalytics({
		meta: {
			id: 'entitlementServiceFetch',
			packageName: 'jiraServicedeskCustomersOrganizationCommon',
			teamName: 'boysenberry',
		},
		error: err,
		sendToPrivacyUnsafeSplunk: true,
	});
};

const ExtendedFormContentContainer = (props: MultiHelpCenterViewProps) => {
	const formContentMultihcViewQueryData = usePreloadedQuery<entitlementQuery>(
		graphql`
			query extendedFormContentMultiHcEntitlementQuery($cloudId: ID!, $workSpaceARI: ID!) {
				helpCenter(cloudId: $cloudId) {
					helpCentersConfig(workspaceAri: $workSpaceARI) {
						__typename
						... on HelpCentersConfig {
							isEnabled
						}
						... on QueryError {
							identifier
							message
							extensions {
								errorType
								statusCode
							}
						}
					}
				}
			}
		`,
		props.queryRef,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [hasMultiHcAndInviteRouting, setHasMultiHcAndInviteRouting] = useState(false);

	useEffect(() => {
		if (!hasMultiHcAndInviteRouting && formContentMultihcViewQueryData) {
			let updatedMultiHcAndInviteRouting = false;
			const helpCenterConfig = formContentMultihcViewQueryData?.helpCenter?.helpCentersConfig;
			if (!helpCenterConfig) {
				// This could occur when the request did not reach Kosh
				logError(
					new Error(
						`Missing required fields in entitlement response, possibly an AGG error. ${JSON.stringify(formContentMultihcViewQueryData)}`,
					),
				);
			} else if (helpCenterConfig.__typename === 'HelpCentersConfig') {
				const { isEnabled } = helpCenterConfig;
				updatedMultiHcAndInviteRouting = isEnabled;
				const analyticsEvent = createAnalyticsEvent({});
				fireTrackAnalytics(analyticsEvent, 'entitlement APICallSuccess', {
					source: 'entitlementServiceFetch',
					hasMultiHcAndInviteRouting: isEnabled,
				});
			} else if (helpCenterConfig.__typename === 'QueryError') {
				const { identifier, message, extensions } = helpCenterConfig;
				const { errorType, statusCode } = extensions?.[0] || {};
				if (!isHttpClientErrorResponse(Number(statusCode))) {
					logError(
						new Error(
							`QueryError: ${JSON.stringify({
								identifier,
								message,
								errorType,
								statusCode,
							})}`,
						),
					);
				}
			}
			if (updatedMultiHcAndInviteRouting !== hasMultiHcAndInviteRouting) {
				setHasMultiHcAndInviteRouting(updatedMultiHcAndInviteRouting);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formContentMultihcViewQueryData, hasMultiHcAndInviteRouting]);

	return <>{props.render({ hasMultiHcAndInviteRouting })}</>;
};

export const ExtendedFormContent = (props: MultiHelpCenterViewProps) => (
	<JSErrorBoundary
		id="add-customer-entitlement-check"
		fallback={() => props.render({ hasMultiHcAndInviteRouting: false })}
	>
		<ExtendedFormContentContainer {...props} />
	</JSErrorBoundary>
);
