import { useState, useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { BaseUrl, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { State } from './types';
import { getUrl } from './utils';

export const useRemoveCustomerService = (baseUrl: BaseUrl, projectKey: ProjectKey): State => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<unknown>(null);
	const [error, setError] = useState<unknown>(null);

	const fetch = useCallback(
		async (customerId: string) => {
			setLoading(true);
			setError(null);
			try {
				await performDeleteRequest(getUrl(baseUrl, projectKey, customerId));
				setData(customerId);
				setLoading(false);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				setLoading(false);
				setError(err);
				// Only fire analytics for unexpected errors. ValidationErrors are used when
				// the backend returns a 400. Expected 400 errors from the backend include:
				// - user is removed successfully from the customer role but still maintains access to Service Desk
				// This error message is used to let the user know that they may still need to adjust
				// permissions if removal of access is required, but it doesn't indicate a failure
				const shouldFireErrorAnalytics = !isValidationError(err);
				if (shouldFireErrorAnalytics) {
					fireErrorAnalytics({
						error: err,
						meta: {
							id: 'useRemoveCustomerService',
							packageName: 'jiraServicedeskCustomers',
							teamName: 'boysenberry',
						},
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			}
		},
		[baseUrl, projectKey],
	);
	return {
		loading,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type '((Error | ValidationErrors | { statusCode: number; message?: string | undefined; }) & ValidationErrors) | null'.
		error,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'ServiceData<Data>'.
		data,
		// @ts-expect-error - TS2322 - Type '(customerId: string) => Promise<void>' is not assignable to type '(() => Promise<void>) & ((arg1: string) => Promise<void>)'.
		fetch,
	};
};
