import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeHolder: {
		id: 'servicedesk-customers-organization-common.common.organizations-select-field.organizations-single-select.place-holder',
		defaultMessage: 'Choose or create an organization',
		description: 'place holder choose or create an organization',
	},
	createNew: {
		id: 'servicedesk-customers-organization-common.common.organizations-select-field.organizations-single-select.create-new',
		defaultMessage: '{orgName} (create new)',
		description: 'label for create new',
	},
	noOptionsMessage: {
		id: 'servicedesk-customers-organization-common.common.organizations-select-field.organizations-single-select.no-options-message',
		defaultMessage: 'Start typing to create a new organization',
		description: 'label for start typing to create a new organization',
	},
});
