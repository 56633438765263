import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

export const CustomerTypes = {
	invalidCustomer: 'invalid-customer',
	validCustomer: 'valid-customer',
} as const;

export type CustomerBase = {
	key: string;
	emailAddress: string;
	displayName: string;
};

export type InvalidCustomerEntity = CustomerBase & {
	error: string;
};

export type ValidCustomerEntity = CustomerBase & {
	accountId?: AccountId;
};

export type InvalidCustomer = InvalidCustomerEntity & {
	type: 'invalid-customer';
};

export type ValidCustomer = ValidCustomerEntity & {
	type: 'valid-customer';
};

export type ValidatedCustomer = ValidCustomer | InvalidCustomer;

export type ValidatedCustomers = ValidatedCustomer[];

export type FailedWithErrors = {
	email: string;
	error: string;
};

export type EmailValidationError = {
	passed: string[];
	failed: string[];
	added: string[];
	sent: string[];
	failedWithErrors?: FailedWithErrors[];
};

export type EmailValidationErrorType = EmailValidationError | null;
