import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	information: {
		id: 'servicedesk-customers-organization-common.remove-organization-dialog.information',
		defaultMessage:
			'Organization members will lose access to {projectName} unless they have the Customers role for the project.',
		description: 'Information message for removing the organization from the project.',
	},
	confirm: {
		id: 'servicedesk-customers-organization-common.remove-organization-dialog.confirm',
		defaultMessage: 'Confirm',
		description: 'Button text to confirm the remove organization operation.',
	},
	header: {
		id: 'servicedesk-customers-organization-common.remove-organization-dialog.header',
		defaultMessage: 'Remove {orgName} from project',
		description: 'Header text for the remove project dialog',
	},
	cancel: {
		id: 'servicedesk-customers-organization-common.remove-organization-dialog.cancel',
		defaultMessage: 'Cancel',
		description: 'Button text to cancel the remove operation.',
	},
	errorMessage: {
		id: 'servicedesk-customers-organization-common.remove-organization-dialog.error-message',
		defaultMessage:
			'Something has gone wrong with your request. If symptoms persist contact your System Administrator.',
		description: 'Error message for remove organization dialog',
	},
	errorMessageTitle: {
		id: 'servicedesk-customers-organization-common.remove-organization-dialog.error-message-title',
		defaultMessage: 'Error',
		description: 'Error message title for remove organization dialog',
	},
});
