import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

type PaginationLabelProps = {
	label: ReactNode;
	start: number;
	end: number;
	size: number;
	justifyCenter?: boolean;
};

type PaginationLabelViewProps = PaginationLabelProps & Intl;

export const TEST_ID = 'servicedesk-customers-organization-common.ui.table.pagination-count-label';

export const PaginationLabelView = ({
	label,
	start,
	end,
	size,
	justifyCenter,
	intl: { formatMessage },
}: PaginationLabelViewProps) => {
	if (label) {
		return (
			<Box
				as="p"
				xcss={[labelStyles, !justifyCenter && justifyRightStyles]}
				aria-live="polite"
				data-testId={TEST_ID}
			>
				{label}
			</Box>
		);
	}

	if (size > 0 && end >= start)
		return (
			<Box as="p" xcss={labelStyles} aria-live="polite" data-testId={TEST_ID}>
				{formatMessage(messages.paginationMessage, {
					start,
					end,
					size,
					span: (chunks: ReactNode) => <VisuallyHidden>{chunks}</VisuallyHidden>,
				})}
			</Box>
		);

	return null;
};

PaginationLabelView.defaultProps = {
	label: '',
	start: 0,
	end: 0,
	size: 0,
	showPagination: false,
};

export const PaginationLabel = injectIntl(PaginationLabelView);

const labelStyles = xcss({
	float: 'right',
	margin: 'space.0',
	position: 'relative',
	gridColumnStart: 1,
	gridRowStart: 1,
	justifySelf: 'center',
	bottom: 'space.0',
});

const justifyRightStyles = xcss({
	justifySelf: 'right',
});
