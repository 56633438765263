import { useCallback, useState } from 'react';
import { performPostRequest } from '@atlassian/jira-servicedesk-common/src/utils/fetch/requests.tsx';
import type { ValidationServiceError } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import type { State } from './types';

export const useResendVerificationEmailService = (): State => {
	const [resendVerificationEmailLoading, setLoading] = useState<boolean>(false);
	const [isRateLimited, setIsRateLimited] = useState<boolean>(false);
	const [resendVerificationEmailSuccess, setResendVerificationEmailSuccess] =
		useState<boolean>(false);
	const [resendVerificationEmailError, setError] = useState<ValidationServiceError>(null);

	const resendVerificationEmailFetch = useCallback(async (email: string) => {
		setLoading(true);
		setError(null);
		setResendVerificationEmailSuccess(false);
		try {
			await performPostRequest(
				'/rest/servicedesk/1/customer/pages/user/resend-verification-email',
				{
					body: JSON.stringify({
						email,
						verificationContext: {
							portalId: null,
						},
					}),
				},
			);
			setResendVerificationEmailSuccess(true);
			setLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			const { statusCode } = err;
			if (statusCode === 429) {
				setIsRateLimited(true);
			} else {
				setIsRateLimited(false);
			}
			setLoading(false);
			setError(err);
		}
	}, []);

	return {
		resendVerificationEmailLoading,
		resendVerificationEmailError,
		resendVerificationEmailSuccess,
		resendVerificationEmailFetch,
		isRateLimited,
	};
};
