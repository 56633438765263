import React, { useEffect, useState } from 'react';
import Button from '@atlaskit/button/standard-button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalTransition,
	ModalBody,
	ModalTitle,
	ModalHeader,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	MODAL,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useCommonPageContext } from '@atlassian/jira-servicedesk-common-page-context';
import { CollapsibleSectionMessage } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/collapsible-section-message/index.tsx';
import { useRemoveOrganizationService } from '@atlassian/jira-servicedesk-customers-organization-common/src/services/remove-organization/index.tsx';
import { organizationsRemoveFromProjectExperience } from '../../experiences';
import messages from './messages';

type SuccessProps = {
	projectKey: string;
	orgName: string;
};

type RemoveOrganizationProps = {
	isOpen: boolean;
	orgName: string;
	orgId: number;
	confirmMessage?: string;
	onClose: () => void;
	onSuccess: (arg1: SuccessProps) => void;
	intl: IntlShape;
};

const RemoveOrganizationConfirmation = ({
	isOpen = false,
	onClose,
	onSuccess,
	intl: { formatMessage },
	orgName,
	orgId,
	confirmMessage = '',
}: RemoveOrganizationProps) => {
	const { baseUrl, projectId, projectName, projectKey } = useCommonPageContext();
	const [isErrorSectionOpen, setIsErrorSectionOpen] = useState(true);
	const {
		error: isError,
		data,
		fetch: removeOrganization,
		loading,
		/* @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string' */
	} = useRemoveOrganizationService(baseUrl, projectId);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleClick = (_: any, analyticsEvent: any) => {
		organizationsRemoveFromProjectExperience.start();
		removeOrganization(orgId, analyticsEvent);
	};

	useEffect(() => {
		if (Boolean(data) && data === orgId) {
			/* @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string' */
			onSuccess({ projectKey, orgName });
		}
		if (isError) {
			organizationsRemoveFromProjectExperience.failure();
			setIsErrorSectionOpen(true);
		}
	}, [isError, onSuccess, orgId, orgName, projectKey, projectName, data]);

	const actions = [
		{
			text: confirmMessage || formatMessage(messages.confirm),
			onClick: handleClick,
			'data-test-id': 'jsd.servicedesk.customers-organization-common.confirm-button',
			appearance: 'default' as const,
			isLoading: loading,
		},
		{
			text: formatMessage(messages.cancel),
			onClick: onClose,
			'data-test-id': 'jsd.servicedesk.customers-organization-common.close-button',
			appearance: 'link' as const,
		},
	];

	return (
		<ModalTransition>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog autoFocus onClose={onClose}>
						<ModalHeader>
							<ModalTitle>{formatMessage(messages.header, { orgName })}</ModalTitle>
						</ModalHeader>

						<ModalBody>
							<ContextualAnalyticsData sourceName="removeOrganization" sourceType={MODAL}>
								{isError && (
									<CollapsibleSectionMessage
										appearance="error"
										message={formatMessage(messages.errorMessage)}
										isOpen={isErrorSectionOpen}
										title={formatMessage(messages.errorMessageTitle)}
										onClose={setIsErrorSectionOpen}
									/>
								)}
								{formatMessage(messages.information, { projectName })}
								<FireScreenAnalytics />
							</ContextualAnalyticsData>
						</ModalBody>
						<ModalFooter>
							{actions.map((props, index) => (
								<Button
									key={index}
									{...props}
									appearance={
										index === 0 ? props.appearance || 'primary' : props.appearance || 'subtle'
									}
								>
									{props.text}
								</Button>
							))}
						</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransition>
	);
};

export const RemoveOrganizationDialog = injectIntl(RemoveOrganizationConfirmation);
