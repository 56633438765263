import React from 'react';
import { styled, css } from '@compiled/react';
import InlineMessage from '@atlaskit/inline-message';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages';

export const getHeader = (
	{ formatMessage }: IntlShape,
	isEditable: boolean,
	hasActions?: boolean,
) => {
	const cells = [
		{
			key: 'displayName',
			content: formatMessage(messages.nameColumn),
			width: 58,
		},
		{
			key: 'openRequests',
			content: formatMessage(messages.openRequestsColumn),
			width: 20,
		},
		{
			key: 'closedRequests',
			content: formatMessage(messages.closedRequestsColumn),
			width: 20,
		},
		{
			key: 'status',
			content: (
				<InlineMessageWrapper>
					{formatMessage(messages.statusColumn)}
					<InlineMessage appearance="info" placement="bottom-end">
						<InlineMessageContent>
							{formatMessage(messages.statusAbsentAaDescription)}
						</InlineMessageContent>
					</InlineMessage>
				</InlineMessageWrapper>
			),
			width: 15,
		},
		...(hasActions || hasActions === undefined
			? [
					{
						key: 'actions',
						content: formatMessage(messages.actionsColumn),
						width: 17,
					},
				]
			: []),
		...(isEditable
			? [
					{
						key: 'editableColumn',
						content: '',
						width: 5,
					},
				]
			: []),
	];

	return { cells };
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineMessageWrapper = styled.div(
	{
		display: 'flex',
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	css({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& button': {
			height: token('space.200', '16px'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& span': {
				height: token('space.200', '16px'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& svg': {
				width: token('space.200', '16px'),
				height: token('space.200', '16px'),
				color: token('color.text.subtlest', '#5e6c84'),
			},
		},
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineMessageContent = styled.div({
	display: 'flex',
	fontWeight: 'normal',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 30}px !important`,
});
