import React, {
	type ComponentType,
	type ReactNode,
	type ReactElement,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
} from 'react';
import PageHeader from '@atlassian/jira-common-components-page-header';
import { useCommonPageContext } from '@atlassian/jira-servicedesk-common-page-context';
import { CustomBreadcrumbs as Breadcrumb } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/header/breadcrumb/main.tsx';

type Props<A> = {
	actions: ReactElement<ComponentProps<ComponentType<A>>>;
	children: ReactNode;
	crumbText?: string;
};

export const Header = <A,>({ actions, children, crumbText }: Props<A>) => {
	const { baseUrl, projectKey, projectName } = useCommonPageContext();
	return (
		<PageHeader
			actions={actions}
			breadcrumbs={
				<Breadcrumb
					/* @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string' */
					baseUrl={baseUrl}
					/* @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string' */
					projectKey={projectKey}
					/* @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string' */
					projectName={projectName}
					crumbText={crumbText || ''}
				/>
			}
			truncateTitle
		>
			{children}
		</PageHeader>
	);
};

Header.displayName = 'HeaderWithBreadcrumb';
