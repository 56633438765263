import React from 'react';
import { BreadcrumbManager } from '@atlassian/jira-breadcrumbs/src/breadcrumb-manager/index.tsx';
import BreadcrumbRenderer from '@atlassian/jira-breadcrumbs/src/breadcrumb-renderer/index.tsx';
import Breadcrumb from '@atlassian/jira-breadcrumbs/src/breadcrumb/component.tsx';
import ProjectBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/project/index.tsx';
import type { BreadcrumbProps } from './types';

export const CustomBreadcrumbs = ({
	baseUrl,
	projectKey,
	projectName,
	crumbText,
}: BreadcrumbProps) => (
	<BreadcrumbManager>
		<BreadcrumbRenderer />
		<ProjectBreadcrumb baseUrl={baseUrl} projectKey={projectKey} projectName={projectName}>
			{crumbText ? (
				<Breadcrumb
					breadcrumb={{
						text: crumbText,
						href: `${baseUrl}/jira/servicedesk/projects/${projectKey}/customers`,
						type: 'servicedeskCustomersBreadcrumb',
					}}
				/>
			) : null}
		</ProjectBreadcrumb>
	</BreadcrumbManager>
);
