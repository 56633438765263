import { useState, useCallback, useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { fetchQuery, graphql } from 'relay-runtime';
import { JiraWorkspaceAri } from '@atlassian/ari/jira';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	helpCenterFetchQuery,
	helpCenterFetchQuery$data,
} from '@atlassian/jira-relay/src/__generated__/helpCenterFetchQuery.graphql';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { NUMBER_OF_HELP_CENTERS_TO_FETCH } from '../../common/types/constants';
import type { HelpCenterData } from '../../common/types/help-centers/types.tsx';
import type { CustomError } from '../../common/types/types.tsx';
import { logError } from '../../common/utils';
import { helpCenterFetchExperience } from '../../experiences';
import type { HelpCenterFetchServiceState } from './types';

export const useHelpCenterFetchService = (): HelpCenterFetchServiceState => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<HelpCenterData[]>([]);
	const [error, setError] = useState<Error | null>(null);
	const [after, setAfter] = useState<string | undefined>(undefined);
	const [page, setPage] = useState<number>(0);
	const [hasNext, setHasMore] = useState<boolean>(false);

	const environment = useRelayEnvironment();

	const cloudId = useCloudId();

	const activationId = useActivationId();
	const workSpaceAri = JiraWorkspaceAri.create({
		siteId: cloudId,
		activationId,
	});

	const helpCenterData = fetchQuery<helpCenterFetchQuery>(
		environment,
		graphql`
			query helpCenterFetchQuery(
				$cloudId: ID!
				$workspaceAri: ID!
				$SORT_ORDER: HelpCenterSortOrder!
				$first: Int!
				$after: String
			) {
				helpCenter(cloudId: $cloudId) {
					helpCenters(
						workspaceAri: $workspaceAri
						sortOrder: $SORT_ORDER
						first: $first
						after: $after
					) @connection(key: "multiHcDropdownContentFragment_helpCenters") {
						__typename
						edges {
							node {
								... on HelpCenter {
									id
									name {
										default
									}
									slug @optIn(to: "HelpCenterSlugTest")
								}
							}
						}
						nodes {
							__typename
							... on QueryError {
								message
								identifier
								extensions {
									statusCode
									errorType
								}
							}
						}
						pageInfo {
							endCursor
							hasNextPage
						}
					}
				}
			}
		`,
		{
			first: NUMBER_OF_HELP_CENTERS_TO_FETCH,
			cloudId,
			workspaceAri: workSpaceAri.toString(),
			SORT_ORDER: 'CREATED_DATE_ASCENDING',
			after,
		},
		{
			fetchPolicy: 'store-or-network',
		},
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fetch = useCallback(async () => {
		helpCenterFetchExperience.start();
		let helpCenters: HelpCenterData[] = [];
		setIsLoading(true);
		setError(null);
		await helpCenterData
			.toPromise()
			.then((res: helpCenterFetchQuery$data | undefined) => {
				const helpCentersList = res?.helpCenter?.helpCenters;
				const errorNodes = helpCentersList?.nodes;
				if (!helpCentersList) {
					// This could occur when the request did not reach Kosh and failed at AGG
					throw new Error(
						`Missing required fields in helpCenterFetchQuery response, possibly an AGG error. ${JSON.stringify(res)}`,
					);
				} else if (helpCentersList?.__typename === 'HelpCenterQueryResultConnection') {
					helpCenters =
						helpCentersList.edges
							?.filter((hc) => hc?.node?.name?.default && hc?.node?.id)
							.map((hc) => {
								const name = hc?.node?.name?.default || '';
								const slug = hc?.node?.slug || null;
								const helpCenterAri = hc?.node?.id || '';
								return {
									name,
									slug,
									helpCenterAri,
								};
							}) || [];
					setData(helpCenters);
					setAfter(helpCentersList.pageInfo?.endCursor || undefined);
					setHasMore(helpCentersList.pageInfo?.hasNextPage || false);
					setPage(page + 1);
					setIsLoading(false);
					helpCenterFetchExperience.success();
					const analyticsEvent = createAnalyticsEvent({});
					fireOperationalAnalytics(analyticsEvent, 'jsmHelpCenterFetch succeeded', {
						helpCentersCount: helpCenters.length,
						page,
					});
				}
				// Could be the case only one node has an error so data processing done before this
				else if (Array.isArray(errorNodes) && errorNodes[0].__typename === 'QueryError') {
					const { message, identifier, extensions } = errorNodes[0];
					const { statusCode, errorType } = extensions?.[0] || {};
					const err: CustomError = new Error(
						`QueryError: ${JSON.stringify({
							message,
							identifier,
							statusCode,
							errorType,
						})}`,
					);
					err.statusCode = statusCode ? Number(statusCode) : undefined;
					throw err;
				} else {
					throw new Error(`Unknown error occurred ${JSON.stringify(res)}`);
				}
			})
			.catch((err: CustomError) => {
				setError(err);
				setIsLoading(false);
				helpCenterFetchExperience.failure();
			});

		return helpCenters;
	}, [createAnalyticsEvent, helpCenterData, page]);

	useEffect(() => {
		if (error) {
			logError(error);
		}
	}, [error]);

	return {
		isLoading,
		error,
		data,
		after,
		hasNext,
		fetch,
	};
};
