import React from 'react';
import { INLINE_DIALOG, ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import {
	AddCustomersInlineDialogButton as AddCustomersInlineDialogButtonView,
	type AddCustomersInlineDialogButtonProps,
} from './main';

const AddCustomersInlineDialogButton = (props: AddCustomersInlineDialogButtonProps) => {
	const sourceName =
		props.isOrganizationDefaulted === true ? 'addCustomersToOrganization' : 'addCustomers';
	return (
		<ContextualAnalyticsData
			sourceName={sourceName}
			sourceType={INLINE_DIALOG}
			attributes={{
				isSpa: true,
				version: 2,
			}}
		>
			<AddCustomersInlineDialogButtonView {...props} />
		</ContextualAnalyticsData>
	);
};

export { AddCustomersInlineDialogButton };
