export const ALERTS_FEATURE = 'alerts' as const;
export const ONCALL_FEATURE = 'oncall' as const;
export const SERVICES_FEATURE = 'services' as const;
export const SIMILAR_ISSUES_FEATURE = 'similarIssues' as const;
export const SENTIMENT_FEATURE = 'sentiment-analysis' as const;

export const AUTOMATICALLY_SET_REQUEST_TYPE_FEATURE = 'automaticallySetRequestType' as const;

export const CHANGE_MANAGEMENT_FEATURE = 'change-management' as const;
export const INCIDENT_MANAGEMENT_FEATURE = 'incident-management' as const;
export const PROBLEM_MANAGEMENT_FEATURE = 'problem-management' as const;
export const SERVICE_REQUEST_FEATURE = 'service-request' as const;
export const POST_INCIDENT_REVIEW_FEATURE = 'post-incident-review' as const;
export const CSAT_FEATURE = 'csat' as const;
export const EMAIL_FEATURE = 'email-request' as const;
export const CSM_CUSTOMER_CONTEXT_FEATURE = 'csm-customer-context' as const;
export const CSM_DEVELOPER_ESCALATIONS_FEATURE = 'developer-escalation' as const;
export const CSM_PRODUCT_AND_ENTITLEMENTS_FEATURE = 'products-and-entitlements' as const;
export const JSM_VIEWS = 'jsm-views' as const;
export const JSM_BOARD = 'jsm-board' as const;
export const JSM_CALENDAR = 'jsm-calendar' as const;
export const DEVELOPER_ESCALATIONS_FEATURE = 'developer-escalation' as const;

export const ENABLED = 'ENABLED' as const;
export const DISABLED = 'DISABLED' as const;
