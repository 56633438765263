import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ProjectKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FormattedSearchResult } from './types';
import { getUrl, transformSearchResults } from './utils';

export type { FormattedSearchResult } from './types';

export const fetchCustomers = async (
	baseUrl: BaseUrl,
	projectKey: ProjectKey,
	shouldFetchUserPermissions: boolean,
	query?: string,
	page?: number,
	signal?: AbortSignal | null,
): Promise<FormattedSearchResult> => {
	const response = await performGetRequest(
		getUrl(baseUrl, projectKey, shouldFetchUserPermissions, query, page),
		{
			method: 'GET',
			signal,
		},
	);
	const result: FormattedSearchResult = transformSearchResults(response);
	return result;
};
