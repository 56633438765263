import { styled } from '@compiled/react';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export { FormErrors } from './form-errors';

export { Footer } from './footer';

export { FooterControls } from './footer-controls';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DialogWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 40}px`,
	whiteSpace: 'normal',
	maxHeight: 'inherit',
	maxWidth: 'inherit',
	overflow: 'auto',
});
