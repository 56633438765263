import React, { useEffect } from 'react';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import {
	INLINE_DIALOG,
	ContextualAnalyticsData,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useCommonPageContext } from '@atlassian/jira-servicedesk-common-page-context';
import type { Organizations } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/organizations/types.tsx';
import { DialogWrapper } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/inline-dialog/index.tsx';
import { SuccessFlag } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/success-flag/index.tsx';
import { customersAddOrganizationsExperience } from '@atlassian/jira-servicedesk-customers-organization-common/src/experiences.tsx';
import { useAppStore as useCustomersAppStore } from '@atlassian/jira-servicedesk-customers/src/controllers/app-store/index.tsx';
import { useInviteOrganizationService as useInviteOrganizationServiceDI } from '@atlassian/jira-servicedesk-customers/src/services/invite-organizations/main.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { Form } from './main';
import messages from './messages';

type AddOrganizationsFormProps = Intl & {
	useInviteOrganizationService: typeof useInviteOrganizationServiceDI;
	organizations: Organizations;
	setOrganizations: (arg1: Organizations) => void;
	onReset: () => void;
	onCloseDialog: () => void;
	onResetAndCloseDialog: () => void;
};

const AddOrganizationsFormView = ({
	intl: { formatMessage },
	organizations,
	useInviteOrganizationService,
	setOrganizations,
	onReset,
	onCloseDialog,
	onResetAndCloseDialog,
}: AddOrganizationsFormProps) => {
	const { baseUrl, projectId } = useCommonPageContext();

	const {
		data: invitedOrganizations,
		loading: isSubmitting,
		error,
		fetch: onInvite,
		// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
	} = useInviteOrganizationService(baseUrl, projectId);

	const [, { fetchAndUpdatePageData }] = useCustomersAppStore();

	const onSubmit = () => {
		customersAddOrganizationsExperience.start();
		onInvite(organizations);
	};
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (invitedOrganizations) {
			fetchAndUpdatePageData({ createAnalyticsEvent });
			customersAddOrganizationsExperience.success();
			onReset();
		}
	}, [createAnalyticsEvent, fetchAndUpdatePageData, invitedOrganizations, onReset]);

	useEffect(() => {
		if (error) {
			customersAddOrganizationsExperience.failure();
		}
	}, [error]);

	return (
		<DialogWrapper data-test-id="servicedesk-customers.ui.header-actions.add-organizations-dialog.form.dialog-wrappper">
			{invitedOrganizations ? (
				<SuccessFlag
					title={formatMessage(messages.addOrganizations)}
					description={formatMessage(messages.successfullyAddedOrganizations, {
						organizationsCount: organizations.length,
					})}
					onDismissed={onCloseDialog}
				/>
			) : (
				<ContextualAnalyticsData sourceName="addOrganizations" sourceType={INLINE_DIALOG}>
					<Form
						isSubmitting={isSubmitting}
						error={error}
						onReset={onResetAndCloseDialog}
						onSubmit={onSubmit}
						organizations={organizations}
						setOrganizations={setOrganizations}
					/>
				</ContextualAnalyticsData>
			)}
		</DialogWrapper>
	);
};

AddOrganizationsFormView.defaultProps = {
	useInviteOrganizationService: useInviteOrganizationServiceDI,
};

export const AddOrganizationsForm = injectIntl(AddOrganizationsFormView);
