import React, { type ReactNode } from 'react';
import Button from '@atlaskit/button';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import HtmlFormattedMessage from '@atlassian/jira-servicedesk-common/src/i18n/format-html-message';
import type { UserPermissions } from '../../controllers/app-store/types';
import { getMessageBasedOnUserPermissions, getMessageBasedOnUserPermissionsNew } from './utils';

type AccessMessageProps = {
	isProjectSimplified: boolean;
	baseUrl: string;
	projectKey: string;
	userPermissions?: UserPermissions;
};

export const AccessMessage = ({
	baseUrl,
	projectKey,
	isProjectSimplified,
	// @ts-expect-error - TS2740 - Type '{}' is missing the following properties from type 'UserPermissions': customerRoleMisconfigured, canAdminister, canAdministerJIRA, outgoingMailConfigured, and 7 more.
	userPermissions = {},
}: AccessMessageProps) => {
	const { formatMessage } = useIntl();
	const { canAdminister } = userPermissions;

	if (!canAdminister) {
		return null;
	}

	const customerPermissionUrl =
		isProjectSimplified && !fg('update_jsm_project_settings_pages_urls')
			? `${baseUrl}/jira/servicedesk/projects/${projectKey}/settings/channels/customer-permissions`
			: `${baseUrl}/jira/servicedesk/projects/${projectKey}/settings/customer-permissions`;

	const message = ff('jsm-csm-a11y-fixes_ho588')
		? getMessageBasedOnUserPermissionsNew(userPermissions)
		: getMessageBasedOnUserPermissions(userPermissions);

	return ff('jsm-csm-a11y-fixes_ho588') ? (
		formatMessage(message, {
			a: (children: ReactNode) => (
				<Button appearance="link" href={customerPermissionUrl} spacing="none">
					{children}
				</Button>
			),
		})
	) : (
		<HtmlFormattedMessage
			{...message}
			values={{
				tagStart: `<a href=${customerPermissionUrl} />`,
				tagEnd: '</a>',
			}}
		/>
	);
};
