import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	customersBreadcrumbTitle: {
		id: 'servicedesk-customers.customers-breadcrumb-title',
		defaultMessage: 'Customers',
		description: 'Title of the breadcrumb that navigates to customers page',
	},
	noCustomersJsm: {
		id: 'servicedesk-customers.no-customers-jsm',
		defaultMessage: 'There are no customers yet. Invite some to your service project.',
		description: 'Information message for no customers',
	},
	searchPlaceholder: {
		id: 'servicedesk-customers.search-placeholder',
		defaultMessage: 'Search customers and organizations',
		description: 'Placeholder for the search input in customers page',
	},
	searchDescription: {
		id: 'servicedesk-customers.search-description',
		defaultMessage: 'Typing in the search field will update the results in the below table.',
		description: 'Hidden text used to describe the search input in customers page',
	},
});
