import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { CustomError } from '../types/types.tsx';

export const isNetworkErrorCode = (statusCode: number): boolean => statusCode === 0;

export const isClientErrorCode = (statusCode: number): boolean =>
	statusCode >= 400 && statusCode < 500;

export const isNetworkOrClientErrorCode = (statusCode: number): boolean =>
	isNetworkErrorCode(statusCode) || isClientErrorCode(statusCode);

export const logError = (err: CustomError) => {
	if (isNetworkOrClientErrorCode(Number(err.statusCode))) {
		return;
	}
	fireErrorAnalytics({
		meta: {
			id: 'helpCenterFetchService',
			packageName: 'jiraServicedeskHelpCenterSelectField',
			teamName: 'jsm-megatron',
		},
		error: err,
		sendToPrivacyUnsafeSplunk: true,
	});
};

export const isHttpClientErrorResponse = (statusCode: number): boolean =>
	statusCode === 0 || (statusCode >= 400 && statusCode < 500);
