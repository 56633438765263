import React, { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import AkTooltip from '@atlaskit/tooltip';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { fireUIAnalytics as fireUIAnalyticsDI } from '@atlassian/jira-product-analytics-bridge';
import type { Customer } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { isOrganization } from '../../../common/types/utils';
import messages from './messages';

type EditorCellViewProps = Intl & {
	profile: Customer;
	projectName: string;
	fireUIAnalytics?: typeof fireUIAnalyticsDI;
	showRemoveCustomerTooltip?: boolean;
	onRemove: (user: Customer) => void;
};

const EditorCellView = ({
	intl: { formatMessage },
	projectName,
	profile,
	onRemove,
	showRemoveCustomerTooltip,
	fireUIAnalytics = fireUIAnalyticsDI,
}: EditorCellViewProps) => {
	const { displayName } = profile;

	const tooltipMessage =
		showRemoveCustomerTooltip === true
			? formatMessage(messages.removeCustomerFromOrganization)
			: formatMessage(messages.removeProfile, {
					displayName,
					projectName,
				});

	const handleRemoveClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type.
		(_, analyticsEvent: UIAnalyticsEvent) => {
			onRemove(profile);

			const actionSubjectId = isOrganization(profile)
				? 'removeOrganizationButton'
				: 'removeCustomerButton';
			fireUIAnalytics(analyticsEvent, actionSubjectId);
		},
		[onRemove, profile, fireUIAnalytics],
	);

	return (
		<AkTooltip content={tooltipMessage}>
			<Button
				appearance="subtle"
				testId="servicedesk-customers-organization-common.ui.table.editor-cell.button"
				iconAfter={<EditorRemoveIcon label={tooltipMessage} />}
				onClick={handleRemoveClick}
			/>
		</AkTooltip>
	);
};

export const EditorCell = injectIntl(EditorCellView);
