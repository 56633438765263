import React from 'react';
import {
	FormHeader as AkHeader,
	FormSection as AkFormSection,
	RequiredAsterisk,
} from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { FormStatus } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/form-status/index.tsx';
import type { ValidationServiceError } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import { FormErrors } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/inline-dialog/form-errors/index.tsx';
import { useAddCustomersDialogContext } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/add-customers-dialog-context/index.tsx';
import type { InviteCustomersResponse as InvitedCustomers } from '@atlassian/jira-servicedesk-customers-organization-common/src/services/invite-customers/types.tsx';
import { CustomerNotificationHelpText } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/add-customers-dialog/form/fields/customer-notification-help-text/index.tsx';
import { DynamicInputField } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/add-customers-dialog/form/fields/dynamic-input-field/index.tsx';
import { OrganizationsSelectField } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/add-customers-dialog/form/fields/organizations-select-field/index.tsx';
import { HelpCenterSelectField } from '@atlassian/jira-servicedesk-help-center-select-field/src/ui/index.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { CanManageOrganization } from '../../../common/ui/can-manage-organization';
import { Footer } from './footer';
import messages from './messages';

export type FormContentProps = {
	isSubmitting: boolean;
	hasMultiHcAndInviteRouting: boolean;
	isOrganizationDefaulted?: boolean;
} & Intl & {
		error: ValidationServiceError;
		invitedCustomers: InvitedCustomers | null;
		formSubmissionStatus: FormStatus;
		isCustomerInvitationSupported?: boolean;
	};

export const FormContentView = ({
	intl: { formatMessage },
	isSubmitting,
	isOrganizationDefaulted,
	error: formErrors,
	hasMultiHcAndInviteRouting,
	isCustomerInvitationSupported = true,
}: FormContentProps) => {
	const [
		{
			userPermissions: {
				// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
				canAdminister,
				// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
				canManageOrganizations,
				// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
				outgoingMailConfigured,
				// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
				isInviteEmailEnabled,
			} = {},
			serviceErrors,
			helpCenter,
		},
		{ resetAndCloseForm, setHelpCenter },
	] = useAddCustomersDialogContext();

	const requiredField = (
		<Box xcss={requiredFieldStyles}>
			{formatMessage(messages.requiredField)}
			<RequiredAsterisk />
		</Box>
	);

	return (
		<>
			<AkHeader title={formatMessage(messages.addCustomers)} />
			<CustomerNotificationHelpText
				isAdmin={canAdminister}
				isOutgoingMailEnabled={outgoingMailConfigured}
				isInviteEmailEnabled={isInviteEmailEnabled}
				isMultiHcAndInviteRoutingEnabled={hasMultiHcAndInviteRouting}
				isCustomerInvitationSupported={isCustomerInvitationSupported}
			/>
			<AkFormSection>
				<Box xcss={noOverflowX}>
					{hasMultiHcAndInviteRouting && requiredField}
					<DynamicInputField />
					{hasMultiHcAndInviteRouting && (
						<HelpCenterSelectField
							selectedHelpCenter={helpCenter}
							setSelectedHelpCenter={setHelpCenter}
						/>
					)}
					{isOrganizationDefaulted !== true && (
						<CanManageOrganization
							canAdminister={canAdminister}
							canManageOrganizations={canManageOrganizations}
						>
							<OrganizationsSelectField />
						</CanManageOrganization>
					)}
					<FormErrors formErrors={formErrors} serviceError={serviceErrors} />
					<Footer
						isLoading={isSubmitting}
						onCancel={resetAndCloseForm}
						hasMultiHcAndInviteRouting={hasMultiHcAndInviteRouting}
					/>
				</Box>
			</AkFormSection>
		</>
	);
};

const requiredFieldStyles = xcss({
	marginBottom: 'space.200',
});

const noOverflowX = xcss({
	overflowX: 'hidden',
});

export const FormContent = injectIntl(FormContentView);
