import React, { useCallback, useMemo, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useRouterActions } from '@atlassian/jira-router';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

type OrganizationCellProps = {
	id: number;
	displayName: string;
	projectKey: ProjectKey;
};

export const OrganizationCell = ({ id, displayName, projectKey }: OrganizationCellProps) => {
	const baseUrl = '';

	const { push } = useRouterActions();

	const organizationUrl = useMemo(
		() => `${baseUrl}/jira/servicedesk/projects/${projectKey}/organization/${id}`,
		[baseUrl, id, projectKey],
	);

	const onClick = useCallback(
		(e: MouseEvent<HTMLElement>) => {
			// modifier key + click should open in a new tab/window so we let the default
			// href behaviour occur in that case. For a regular left click we want to prevent
			// the href behaviour since we're using the router to navigate.
			// Without this, we try to load the page twice which causes resources to start
			// loading and then be cut off, creating 'Failed to fetch' errors
			if (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey) {
				return;
			}
			e.preventDefault();
			push(organizationUrl);
		},
		[organizationUrl, push],
	);

	return (
		<StyledWrapper data-testid="servicedesk-customers-organization-common.ui.table.customer-cell.organization.styled-wrapper">
			<Button
				appearance="link"
				iconBefore={
					<PeopleIcon primaryColor={token('color.icon', colors.DN30A)} label={displayName} />
				}
				onClick={onClick}
				href={organizationUrl}
			>
				{displayName}
			</Button>
		</StyledWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4.5}px`,
	padding: token('space.050', '4px'),
});
