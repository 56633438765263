import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageLabel: {
		id: 'servicedesk-customers-organization-common.table.page-label',
		defaultMessage:
			'{start}-{end} for {total}{hasPlusSign, select, true{+} other{}} <span>{total, plural, =1{result} other{results}} found</span>',
		description:
			'Pagination label shown when user loads the customer & organisation table. Results can up to 2000. For eg: 1-50 of 100+, 1-20 of 20. "result(s) found" is for screenreader.',
	},

	searchLabel: {
		id: 'servicedesk-customers-organization-common.table.search-label',
		defaultMessage:
			'{start}-{end} for {total} <span>{total, plural, =1{result} other{results}} found</span>',
		description:
			'Pagination label shown when user searches results in customer & organisation table. Result is always limited to 20. For eg: 1-20 of 20. "result(s) found" is for screenreader.',
	},
});
