import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	notificationsForNonAdmin: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.customer-notification-help-text.notifications-for-non-admin',
		defaultMessage: 'Project admins can disable or customize invitation emails.',
		description: 'Message to inform that project admins can modify customer notification settings',
	},
	invitationNotificationDisabled: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.customer-notification-help-text.invitation-notification-disabled',
		defaultMessage: 'You have disabled the invitation email to customers.',
		description: 'Message to inform that customers will not be notified by invitation email.',
	},
	viewConfigurationForAdmin: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.customer-notification-help-text.view-configuration-for-admin',
		defaultMessage: 'View configuration',
		description: 'information message for View configuration',
	},
	viewConfigurationForNonAdmin: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.customer-notification-help-text.view-configuration-for-non-admin',
		defaultMessage: 'The project admin has disabled the invitation email to customers.',
		description:
			'Message to inform that project admin has disabled the invitation email to customers.',
	},
	updatedCustomersWillReceiveEmail: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.customer-notification-help-text.updated-customers-will-receive-email',
		defaultMessage:
			'Customers will receive an invitation email if they haven’t already been added to a project on this site.',
		description: 'Message to inform that customers will receive an invitation email',
	},
	updatedCustomersWillReceiveHelpCenterEmail: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.customer-notification-help-text.updated-customers-will-receive-help-center-email',
		defaultMessage:
			'Customers will receive an invitation email if they haven’t been added to your site yet. Once signed up, they’ll be directed to the chosen help center.',
		description: 'Message to inform that customers will receive an invitation email',
	},
	updatedNotificationsForAdmin: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.customer-notification-help-text.updated-notifications-for-admin',
		defaultMessage: '{tagStart}Customize your invitation email{tagEnd}.',
		description: 'Message to inform that customer notification settings can be modified',
	},
	updatedNotificationsForAdminNew: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.customer-notification-help-text.updated-notifications-for-admin-new',
		defaultMessage: 'Customize your invitation email.',
		description: 'Message to inform that customer notification settings can be modified',
	},
});
