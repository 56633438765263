import { onResetSearchState } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/actions/reset-search-state/index.tsx';
import { updateOptimisticCustomers } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/actions/update-optimistic-customers/index.tsx';
import {
	updateSearchString,
	updatePageNumber,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/actions/update-search-state/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../types';
import { fetchAndUpdatePageData } from './fetch-and-update-page-data';

export const actions = {
	fetchAndUpdatePageData,
	updateOptimisticCustomers,
	updateSearchString,
	updatePageNumber,
	onResetSearchState,
};

export const onInit =
	(): Action<State, Props> =>
	({ dispatch }) => {
		dispatch(actions.fetchAndUpdatePageData());
	};
