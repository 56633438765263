import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	paginationMessage: {
		id: 'servicedesk-customers-organization-common.table.pagination-count-label.pagination-message',
		defaultMessage:
			'{start}-{end} for {size} <span>{size, plural, =1{result} other{results}} found</span>',
		description:
			'pagination label message for {start}-{end} for {size}. "result(s) found" is for screenreader.',
	},
});
