import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import type { CreateUIAnalyticsEvent, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { abortControllerUtils } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/utils';
import { fetchCustomers } from '@atlassian/jira-servicedesk-customers/src/services/search/index.tsx';
import type { FormattedSearchResult } from '@atlassian/jira-servicedesk-customers/src/services/search/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types';

type Options = {
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
};

const defaultOptions: Options = Object.freeze({});

const fireSearchSuccessEvent = (
	analyticsEvent: UIAnalyticsEvent,
	{
		isSearchStringEmpty,
	}: {
		isSearchStringEmpty: boolean;
	},
) => {
	fireTrackAnalytics(analyticsEvent, 'jsmCustomersPageSearch succeeded', { isSearchStringEmpty });
};

// we debounce the success event firing, to more accurately represent a complete search
// as opposed to a search which is triggered mid-way through a user's search term
const searchSuccessDebounceTime = 2000;
const debouncedFireSearchSuccessEvent = debounce(
	fireSearchSuccessEvent,
	searchSuccessDebounceTime,
	{ leading: true, trailing: false }, // fire event on the leading edge of the debounce
);

export const fetchAndUpdatePageData =
	({ createAnalyticsEvent }: Options = defaultOptions): Action<State, Props> =>
	async ({ getState, setState }, { baseUrl, projectKey }) => {
		const { loading, ...rest } = getState();

		setState({
			...rest,
			loading: true,
			error: null,
		});

		try {
			const signal = abortControllerUtils.getAbortSignal();
			const shouldFetchUserPermissions = isNil(rest.userPermissions);

			const { userPermissions, pageData }: FormattedSearchResult = await fetchCustomers(
				baseUrl,
				projectKey,
				shouldFetchUserPermissions,
				rest.searchString,
				rest.page,
				signal,
			);

			if (userPermissions) {
				setState({ ...getState(), userPermissions, pageData, loading: false });
			} else {
				setState({ ...getState(), pageData, loading: false });
			}

			if (createAnalyticsEvent) {
				const analyticsEvent = createAnalyticsEvent({});
				debouncedFireSearchSuccessEvent(analyticsEvent, {
					isSearchStringEmpty: rest.searchString === '',
				});
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			if (err && err.name === 'AbortError') {
				return;
			}
			// Only fire analytics for unexpected errors. In this case the backend really
			// only returns a 403 if the current user doesn't have a valid service desk
			// license or is not an agent in the current project. Such a user shouldn't
			// have access to the customers/orgs page anyway to trigger this fetch action.
			// As such any errors we get here can be considered unexpected, so we log them
			fireErrorAnalytics({
				meta: {
					id: 'fetchAndUpdatePageData',
					packageName: 'jiraServicedeskCustomers',
					teamName: 'boysenberry',
				},
				error: err,
				sendToPrivacyUnsafeSplunk: true,
			});
			setState({ ...getState(), error: err, loading: false });
		}
	};
