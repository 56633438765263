import { useState, useEffect } from 'react';
import keycode from 'keycode';
import type { ValidatedCustomer } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/customers/types.tsx';
import { useAddCustomersDialogContext } from '../add-customers-dialog-context';
import { trimAll, newLineForText, hasMultipleEmails } from './utils';

export const useDynamicInputHook = () => {
	const [showInput, setShowInput] = useState<boolean>(true);
	const [showHelp, setShowHelp] = useState<boolean>(false);
	const [showErrorFlag, setShowErrorFlag] = useState<boolean>(false);

	const [
		{ isValidating, emailValidationErrors, customers, emailPresetValue },
		{
			setCustomerEmailsInputFieldValue,
			onValidateCustomerEmails,
			setCustomers,
			setEmailPresetValue,
		},
	] = useAddCustomersDialogContext();
	const failed = emailValidationErrors?.failed || [];
	const failedWithErrors = emailValidationErrors?.failedWithErrors || [];

	// User input
	const [email, setEmail] = useState<string>(emailPresetValue);

	// Validated email list yet to be submitted
	const [emails, setEmails] = useState<string[]>(() =>
		customers.map((customer: ValidatedCustomer) => customer.emailAddress),
	);

	// also Toggles input view to text area if input contains comma seperated values
	const setEmailOnChange = (input: string): void => {
		let toSet = input;
		// split the input into one-email-per-line before setting the email value
		// only when input length is > 3. SPLIT BY [',' , ';' , '\n']
		if (hasMultipleEmails(toSet)) {
			toSet = newLineForText(toSet);
			setShowInput(false);
		}

		setEmail(toSet);
		setEmailPresetValue(toSet);

		const allTrimmed = trimAll(toSet);
		if (allTrimmed.length > 0) {
			const toParent = [...new Set(emails.concat(allTrimmed))];
			setCustomerEmailsInputFieldValue(toParent.join(','));
		}
	};

	const handleOnRemoved = (removedEmail: string): void => {
		const newEmails = emails.filter((eachEmail: string) => eachEmail !== removedEmail);
		setEmails(newEmails);

		setCustomers(
			customers.filter((customer: ValidatedCustomer) => customer.emailAddress !== removedEmail),
		);
	};

	// Whenever `emails` changes update the parent's email and customer state
	useEffect(() => {
		if (hasMultipleEmails(email)) {
			// Show textarea expanded when prefill has multiple emails
			setShowInput(false);
		}

		if (emails.length === 0) {
			setCustomerEmailsInputFieldValue(email);
		} else {
			const toParent = [...new Set(emails.concat(email))];
			setCustomerEmailsInputFieldValue(toParent.join(','));
		}
	}, [email, emails, setCustomerEmailsInputFieldValue, setCustomers]);

	useEffect(() => {
		// Set validated email lists on validation
		const passedList = emailValidationErrors ? emailValidationErrors.passed : [];
		const failedList = emailValidationErrors ? emailValidationErrors.failed : [];
		if (passedList.length > 0 && failedList.length === 0) {
			setEmails((prev: string[]) => [...new Set([...prev, ...passedList])]);
			setEmail('');
			setEmailPresetValue('');
			setShowInput(true);
		}

		if (failedWithErrors.length > 0) {
			setShowErrorFlag(true);
		}
	}, [emailValidationErrors, failedWithErrors.length, setEmailPresetValue]);

	const validateEmails = (emailArr: string[]) => {
		const toValidate = [...new Set(emailArr)];
		onValidateCustomerEmails(toValidate);
	};

	const handleAddMore = () => {
		const emailArr = trimAll(email);
		if (emailArr.length > 0) {
			validateEmails(emailArr);
		}
	};

	const handleBackspace = (event: KeyboardEvent) => {
		if (
			event.target instanceof HTMLTextAreaElement &&
			event.target.selectionEnd >= 2 &&
			event.target.selectionEnd === event.target.selectionStart &&
			email.substring(event.target.selectionEnd - 2, event.target.selectionEnd) === ',\n'
		) {
			event.preventDefault();
			setEmail(
				email.substring(0, event.target.selectionEnd - 2) +
					email.substring(event.target.selectionEnd),
			);
			// this will adjust a cursor position in case deletion was in the middle of a text, as it places the cursor at the end by default
			// setting selection must happen after email is set and textarea rerendered
			setTimeout(
				(target, selectionStart, selectionEnd) => {
					target.setSelectionRange(selectionStart, selectionEnd);
				},
				0,
				event.target,
				event.target.selectionEnd - 2,
				event.target.selectionEnd - 2,
			);
		}
	};

	// mimic + Add on enter or space
	const handleOnKeyDown = (event: KeyboardEvent): void => {
		if (
			([keycode('enter'), keycode('space')].includes(event.keyCode) ||
				['Enter', ' ', 'Spacebar'].includes(event.key)) &&
			email.trim()
		) {
			event.preventDefault();
			handleAddMore();
		} else if (
			(keycode('backspace') === event.keyCode || event.key === 'Backspace') &&
			email.trim()
		) {
			handleBackspace(event);
		}
	};

	return {
		email,
		setEmail,
		emails,
		setEmails,
		showInput,
		setShowInput,
		showHelp,
		setShowHelp,
		isValidating,
		emailValidationErrors,
		setCustomerEmailsInputFieldValue,
		onValidateCustomerEmails,
		failed,
		failedWithErrors,
		showErrorFlag,

		// Methods
		setEmailOnChange,
		handleOnRemoved,
		validateEmails,
		handleAddMore,
		handleOnKeyDown,
		setShowErrorFlag,
	};
};
