import React, { useEffect, useCallback } from 'react';
import type { PreloadedQuery } from 'react-relay';
import AkForm from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import Placeholder from '@atlassian/jira-placeholder';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { extendedFormContentMultiHcEntitlementQuery as entitlementQuery } from '@atlassian/jira-relay/src/__generated__/extendedFormContentMultiHcEntitlementQuery.graphql';
import type { FormStatus } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/form-status/index.tsx';
import type { ValidationServiceError } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import { DialogWrapper } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/inline-dialog/index.tsx';
import { useAddCustomersDialogContext } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/add-customers-dialog-context/index.tsx';
import type { OptimisticCustomerListUpdateResults } from '@atlassian/jira-servicedesk-customers-organization-common/src/services/invite-customers/types.tsx';
import type { Data } from '../../../services/invite-customers/types';
import { ConfirmationModal } from './confirmation-modal';
import { ExtendedFormContent } from './extended-form-content';
import { FormContent } from './main';

export type AddCustomersFormProps = {
	isConfirmationModalOpen: boolean;
	isOrganizationDefaulted?: boolean;
	isSubmitting: boolean;
	invitedCustomers: Data;
	formSubmissionStatus: FormStatus;
	error: ValidationServiceError;
	onPrependOptimisticCustomers: (newCustomers: OptimisticCustomerListUpdateResults) => void;
	onUpdateAppState: () => void;
	onToggleConfirmationModal: () => void;
	onCloseConfirmationModal: () => void;
	onFormSubmit: () => void;
	queryRef: PreloadedQuery<entitlementQuery, Record<string, unknown>> | null | undefined;
	isCustomerInvitationSupported?: boolean;
};

export const AddCustomersForm = ({
	isConfirmationModalOpen,
	isOrganizationDefaulted,
	onToggleConfirmationModal,
	onPrependOptimisticCustomers,
	onCloseConfirmationModal,
	onUpdateAppState,
	formSubmissionStatus,
	invitedCustomers,
	error,
	isSubmitting,
	onFormSubmit,
	queryRef,
	isCustomerInvitationSupported = true,
}: AddCustomersFormProps) => {
	const [{ organization, defaultOrganization }] = useAddCustomersDialogContext();

	const onSubmit = useCallback(() => {
		const selectedOrganization = organization || defaultOrganization;
		if (selectedOrganization && selectedOrganization.numberOfOtherProjects > 0) {
			onToggleConfirmationModal();
		} else {
			onFormSubmit();
		}
	}, [defaultOrganization, onFormSubmit, onToggleConfirmationModal, organization]);

	useEffect(() => {
		if (!invitedCustomers) {
			return;
		}

		const { optimisticCustomerListUpdateResults, failed } = invitedCustomers;

		// prepend customers only if there is no selected organization
		// or if there are no failed invited customers
		const shouldPrependOptimisticCustomers =
			(isOrganizationDefaulted === true || !organization) && !failed.length;

		if (shouldPrependOptimisticCustomers) {
			onPrependOptimisticCustomers(optimisticCustomerListUpdateResults);
		} else {
			onUpdateAppState();
		}
	}, [
		invitedCustomers,
		isOrganizationDefaulted,
		onPrependOptimisticCustomers,
		onUpdateAppState,
		organization,
	]);

	const formContentProps = {
		error,
		invitedCustomers,
		formSubmissionStatus,
		isSubmitting,
		isOrganizationDefaulted,
		isCustomerInvitationSupported,
	};

	return (
		<DialogWrapper>
			<AkForm onSubmit={onSubmit}>
				{({ formProps }) => (
					<form
						{...formProps}
						name="add-customers-form"
						data-testId="servicedesk-organization-details.common.ui.add-customers-dialog.form.form"
					>
						<Placeholder
							name="add-customers-popup"
							fallback={
								<Box xcss={spinnerWrapStyle}>
									<Spinner size="medium" />
								</Box>
							}
						>
							{queryRef ? (
								<ExtendedFormContent
									queryRef={queryRef}
									render={(props: { hasMultiHcAndInviteRouting: boolean }) => (
										<FormContent {...formContentProps} {...props} />
									)}
								/>
							) : (
								<FormContent {...formContentProps} hasMultiHcAndInviteRouting={false} />
							)}
						</Placeholder>
						<ConfirmationModal
							isOpen={isConfirmationModalOpen}
							onConfirm={onFormSubmit}
							onClose={onCloseConfirmationModal}
						/>
					</form>
				)}
			</AkForm>
			<FireScreenAnalytics />
		</DialogWrapper>
	);
};

AddCustomersForm.displayName = 'AddCustomersDialogContent';

const spinnerWrapStyle = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '348px',
});
