import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../types';

export const updateSearchString =
	(searchString?: string): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		setState({
			...state,
			searchString: searchString || '',
			page: 1,
		});
	};

export const updatePageNumber =
	(page: number): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		setState({
			...state,
			page: page || 1,
		});
	};
