import React from 'react';
import type { Customer } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import {
	normalizeToOrgProfile,
	normalizeToUserProfile,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/utils.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { OrganizationCell } from './organization';
import { UserCell } from './user';

type CustomerCellProps = {
	profile: Customer;
	projectKey: ProjectKey;
};

export const CustomerCell = ({ profile, projectKey }: CustomerCellProps) => {
	const orgProfile = normalizeToOrgProfile(profile);

	if (orgProfile) {
		const { id, displayName } = orgProfile;
		return <OrganizationCell id={id} displayName={displayName} projectKey={projectKey} />;
	}

	const userProfile = normalizeToUserProfile(profile);
	if (userProfile) {
		return <UserCell profile={userProfile} />;
	}

	return null;
};
