import { PROFILE_TYPES } from './constants';
import type { Customer, Organization, User, PagedSearchResults, PageData } from './types';

export const isUser = ({ type }: Customer) => type === PROFILE_TYPES.user;

export const isOrganization = ({ type }: Customer) => type === PROFILE_TYPES.organization;

export const normalizeToOrgProfile = (profile: Customer): Organization | undefined => {
	if (profile.type === PROFILE_TYPES.organization) {
		return profile;
	}
	return undefined;
};

export const normalizeToUserProfile = (profile: Customer): User | undefined => {
	if (profile.type === PROFILE_TYPES.user) {
		return profile;
	}
	return undefined;
};

export const transformCustomerSearchResults = (searchResults: PagedSearchResults): PageData => {
	const { results, pageNumber, resultsPerPage, ...rest } = searchResults;

	return {
		customers: results,
		page: pageNumber,
		rowsPerPage: resultsPerPage,
		...rest,
	};
};
