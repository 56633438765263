import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Box, xcss } from '@atlaskit/primitives';
import { useRows } from './main';
import type { Props } from './types';

export const OrganizationList = (props: Props) => {
	const rows = useRows(props);

	return (
		<Box
			xcss={tableContainerStyles}
			data-testId="servicedesk-customers.ui.header-actions.add-organizations-dialog.form.fields.selected-list.table-container"
		>
			<DynamicTable
				rows={rows}
				// @ts-expect-error - TS2769 - No overload matches this call.
				paginationi18n={{
					prev: '',
					next: '',
				}}
				isFixedSize
			/>
		</Box>
	);
};

const tableContainerStyles = xcss({
	// @ts-expect-error - TS2322 - Object literal may only specify known properties
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	thead: {
		borderBottom: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	tbody: {
		borderBottom: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	tfoot: {
		borderBottom: 'none',
	},
	marginTop: 'space.250',
});
