import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	save: {
		id: 'servicedesk-customers-organization-common.common.inline-dialog.footer-controls.save',
		defaultMessage: 'Ok',
		description: 'description of Button to add emails',
	},
	cancel: {
		id: 'servicedesk-customers-organization-common.common.inline-dialog.footer-controls.cancel',
		defaultMessage: 'Cancel',
		description: 'description of Button to cancel/close inline dialog',
	},
});
