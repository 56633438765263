import React from 'react';
import AkForm, { FormHeader as AkHeader, Label } from '@atlaskit/form';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { useCommonPageContext } from '@atlassian/jira-servicedesk-common-page-context';
import type { Organizations } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/organizations/types.tsx';
import type { ValidationServiceError } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import { FormErrors } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/inline-dialog/form-errors/index.tsx';
import { OrganizationsSelectField } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/main.tsx';
import { Footer } from '@atlassian/jira-servicedesk-customers/src/ui/header-actions/add-organizations-dialog/form/fields/footer/index.tsx';
import { OrganizationList } from '@atlassian/jira-servicedesk-customers/src/ui/header-actions/add-organizations-dialog/form/fields/selected-list/index.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

const INPUT_ID = 'add-organizations-field';
type FormViewProps = {
	isSubmitting: boolean;
} & Intl & {
		organizations: Organizations;
		error: ValidationServiceError;
		setOrganizations: (arg1: Organizations) => void;
		onReset: () => void;
		onSubmit: () => void;
	};

const FormView = ({
	intl: { formatMessage },
	organizations,
	setOrganizations,
	isSubmitting,
	onReset,
	onSubmit,
	error,
}: FormViewProps) => {
	const { baseUrl, projectId, projectKey } = useCommonPageContext();
	return (
		<AkForm onSubmit={onSubmit}>
			{({ formProps }) => (
				<form {...formProps} name="add-organizations-form">
					<AkHeader title={formatMessage(messages.addOrganizations)} />
					<Label htmlFor={INPUT_ID}>{formatMessage(messages.selectOrgLabel)}</Label>
					<OrganizationsSelectField
						inputId={INPUT_ID}
						// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
						baseUrl={baseUrl}
						// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
						projectId={projectId}
						// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
						projectKey={projectKey}
						selectedOrganizations={organizations}
						onModify={setOrganizations}
						isMulti
					/>
					<OrganizationList organizations={organizations} onRemove={setOrganizations} />
					{error && <FormErrors formErrors={error} />}
					<Footer isLoading={isSubmitting} organizations={organizations} onCancel={onReset} />
				</form>
			)}
		</AkForm>
	);
};

export const Form = injectIntl(FormView);
