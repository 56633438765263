import React, { useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import InlineDialog from '@atlaskit/inline-dialog';
import { Box, xcss, Text } from '@atlaskit/primitives';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

type HelpMeProps = {
	helperText: string;
};

export const HelpMe = ({ helperText }: HelpMeProps) => {
	const [showHelp, setShowHelp] = useState<boolean>(false);
	const helpMeContent = (
		<Box xcss={helpMeWrapperStyles}>
			<Text as="p">{helperText}</Text>
		</Box>
	);
	return (
		<InlineDialog
			onClose={() => {
				setShowHelp(false);
			}}
			content={helpMeContent}
			isOpen={showHelp}
			placement="top-start"
		>
			<Box as="span" xcss={helpMeButtonStyles}>
				<Button
					aria-label="Help"
					appearance="subtle-link"
					iconBefore={<EditorPanelIcon label="info" size="medium" />}
					spacing="none"
					onMouseOver={() => setShowHelp(!showHelp)}
					onMouseLeave={() => setShowHelp(!showHelp)}
				/>
			</Box>
		</InlineDialog>
	);
};

const helpMeWrapperStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 24}px`,
});

const helpMeButtonStyles = xcss({
	verticalAlign: 'middle',
});
