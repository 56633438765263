import { useState, useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Organizations } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/organizations/types.tsx';
import type { BaseUrl, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { State } from './types';
import { getUrl, getRequestPayload } from './utils';

export const useInviteOrganizationService = (baseUrl: BaseUrl, projectId: ProjectId): State => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<unknown>(null);
	const [error, setError] = useState<unknown>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fetch = useCallback(
		async (organizations: Organizations) => {
			setLoading(true);
			setError(null);
			try {
				const response = await performPutRequest(getUrl(baseUrl, projectId), {
					body: JSON.stringify(getRequestPayload(organizations)),
				});
				if (response.organizationsCreated.length > 0) {
					fireTrackAnalytics(createAnalyticsEvent({}), 'organization created', {
						organizationCount: response.organizationsCreated.length,
					});
				}
				setData(response);
				setLoading(false);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				setLoading(false);
				setError(err);
				// Only fire analytics for unexpected errors. ValidationErrors are used when
				// the backend returns a 400. Expected 400 errors from the backend include:
				// - organization name is > 200 characters
				// A 403 is returned if the user doesn't have permission to manage organizations,
				// but I'm not sure how to replicate that situation so I'll leave it as unexpected
				const shouldFireErrorAnalytics = !isValidationError(err);
				if (shouldFireErrorAnalytics) {
					fireErrorAnalytics({
						error: err,
						meta: {
							id: 'useInviteOrganizationService',
							packageName: 'jiraServicedeskCustomers',
							teamName: 'boysenberry',
						},
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			}
		},
		[baseUrl, projectId, createAnalyticsEvent],
	);

	return {
		loading,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type '((Error | ValidationErrors | { statusCode: number; message?: string | undefined; }) & ValidationErrors) | null'.
		error,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'ServiceData<Data>'.
		data,
		// @ts-expect-error - TS2322 - Type '(organizations: Organizations) => Promise<void>' is not assignable to type '(() => Promise<void>) & ((arg1: Organizations) => Promise<void>)'.
		fetch,
	};
};
