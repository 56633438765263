import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'servicedesk-customers-organization-common.table.limit-reached.header',
		defaultMessage: "We can't load any more results.",
		description:
			'Empty state header shown when there are more results than the maximum allowed limit in the customers list. This empty description is shown at the end of the table.',
	},
	description: {
		id: 'servicedesk-customers-organization-common.table.limit-reached.description',
		defaultMessage: "Use the search bar to find the customers or organizations you're looking for.",
		description:
			'Empty state description shown when there are more results than the maximum allowed limit in the customers list. This empty description is shown at the end of the table.',
	},
});
