import React, { type ComponentType } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props as InviteFailedProps } from '@atlassian/jira-servicedesk-customers-common-invite-failed/src/ui/invite-failed/main.tsx';

const AsyncInviteFailed: ComponentType<InviteFailedProps> = lazyAfterPaint(
	() => import(/* webpackChunkName: "jsm-servicedesk-customers-invite-failed" */ './index'),
);

const AsyncLazyInviteFailed = (props: InviteFailedProps) => (
	<ErrorBoundary
		id="customers-invite-failed-dialog"
		packageName="jira-servicedesk-customers-organization-common"
	>
		<Placeholder name="servicedesk-customers-invite-failed" fallback={null}>
			<AsyncInviteFailed {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export { AsyncLazyInviteFailed };
