import React, { type ReactNode } from 'react';

type Props = {
	canInvite?: boolean;
	children: ReactNode;
};

export const CanInviteCustomers = ({ children, canInvite }: Props) => {
	if (canInvite === true) {
		return <>{children}</>;
	}
	return null;
};
