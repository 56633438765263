import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removeProfile: {
		id: 'servicedesk-customers-organization-common.table.editor-cell.remove-profile',
		defaultMessage: 'Remove {displayName} from {projectName}.',
		description: 'Remove customer or organization from project',
	},
	removeCustomerFromOrganization: {
		id: 'servicedesk-customers-organization-common.table.editor-cell.remove-customer-from-organization',
		defaultMessage: 'Remove customer from organization',
		description: 'tooltip label for remove customer button',
	},
});
