import { usePageContext as usePageContextDI } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-context/main.tsx';
import { toProjectId, toBaseUrl, toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { CommonPageContext } from './types';

export type { CommonPageContext } from './types';

// eslint-disable-next-line jira/import/no-dev-code-import-in-app-code
export { useMockCommonPageContext } from './mocks';

export const useCommonPageContext = (
	usePageContext: typeof usePageContextDI = usePageContextDI,
): CommonPageContext => {
	const [{ data: pageContextData, error }] = usePageContext();
	const { tenantContext, projectContext } = pageContextData || {};
	const { projectKey, projectName, projectId, isProjectSimplified } = projectContext || {};
	const { baseUrl, locale } = tenantContext || {};
	return {
		baseUrl: baseUrl !== undefined ? toBaseUrl(baseUrl) : baseUrl,
		error,
		locale,
		projectKey: projectKey !== undefined ? toProjectKey(projectKey) : projectKey,
		projectName,
		projectId: projectId != null ? toProjectId(projectId.toString()) : projectId,
		isProjectSimplified,
	};
};
