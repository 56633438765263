import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../types';

export const onResetSearchState =
	(): Action<State> =>
	({ setState, getState }) => {
		setState({
			...getState(),
			searchString: '',
			page: 1,
		});
	};
