import React from 'react';
import Button from '@atlaskit/button/standard-button';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import { useIntl } from '@atlassian/jira-intl';
import { RowActiveCellWrapper } from '../../index';
import messages from './messages';

type EditorCellViewProps = {
	name: string;
	onRemove: () => void;
};

export const EditorCell = ({ name, onRemove }: EditorCellViewProps) => {
	const { formatMessage } = useIntl();

	return (
		<RowActiveCellWrapper>
			<Button
				testId="servicedesk-customers-organization-common.common.ui.editable-table.editor-cell.button"
				appearance="subtle"
				aria-label={formatMessage(messages.removeOption, {
					name,
				})}
				iconAfter={<EditorRemoveIcon label="" />}
				onClick={onRemove}
			/>
		</RowActiveCellWrapper>
	);
};
