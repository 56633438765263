import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import NonCritical from '@atlassian/jira-non-critical';
import { ServiceDeskAnalyticsContext } from '@atlassian/jira-servicedesk-analytics/src/ui/servicedesk-analytics-context/index.tsx';
import type { Props as InviteFailedProps } from '@atlassian/jira-servicedesk-customers-common-invite-failed/src/ui/invite-failed/main.tsx';
import { AsyncLazyInviteFailed } from './ui/invite-failed/async';

export const AsyncInviteFailed = (props: InviteFailedProps) => (
	<NonCritical>
		<JSErrorBoundary
			id="jsm-customers-common-invite-failed"
			packageName="jira-servicedesk-customers-common-invite-failed"
			fallback="unmount"
			sendToPrivacyUnsafeSplunk
		>
			<ServiceDeskAnalyticsContext>
				<AsyncLazyInviteFailed {...props} />
			</ServiceDeskAnalyticsContext>
		</JSErrorBoundary>
	</NonCritical>
);
