import type { InviteFailedEmails } from '@atlassian/jira-servicedesk-customers-common-invite-failed/src/ui/invite-failed/types.tsx';
import type { FailedWithErrors } from '../../../../../common/types/customers/types';

const transformToInviteFailedEmails = (emails: FailedWithErrors[]): InviteFailedEmails[] => {
	const failedEmails = emails.map((email: FailedWithErrors) => ({
		email: email.email,
		errorMessage: email.error,
	}));

	return failedEmails;
};

export { transformToInviteFailedEmails };
