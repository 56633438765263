import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export const HelpCenterOptionCustomLabel = ({ value }: { value: string }) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<Box>
				<span>{value}</span>
				<Box as="span" xcss={lozengeStyles}>
					<Lozenge appearance="default">{formatMessage(messages.default)}</Lozenge>
				</Box>
			</Box>
		</>
	);
};

const lozengeStyles = xcss({
	marginLeft: 'space.050',
});
