import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src';
import ErrorImageComponent from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/error';
import SearchErrorImageComponent from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/search-error';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { Link } from '@atlassian/jira-router';
import messages from './messages';

export const ProjectNotFound = () => {
	const { formatMessage } = useIntl();
	const projectsPath = '/jira/projects';

	return (
		<>
			<DocumentTitle title={formatMessage(messages.notFoundDocumentTitleJsm)} />
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(SearchErrorImageComponent)}
				maxImageWidth={120}
				header={formatMessage(messages.notFoundTitle)}
				description={formatMessage(messages.notFoundDescription)}
				primaryAction={<Link to={projectsPath}>{formatMessage(messages.notFoundAction)}</Link>}
			/>
		</>
	);
};

export const GenericError = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<DocumentTitle title={formatMessage(messages.genericDocumentTitleJsm)} />
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(ErrorImageComponent)}
				maxImageWidth={120}
				header={formatMessage(messages.genericErrorTitle)}
				description={formatMessage(messages.genericErrorDescription)}
			/>
		</>
	);
};
