import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	paginationPrev: {
		id: 'servicedesk-customers-organization-common.table.pagination.pagination-prev',
		defaultMessage: 'Prev',
		description: 'Text for going to the previous page in the workload table',
	},
	paginationNext: {
		id: 'servicedesk-customers-organization-common.table.pagination.pagination-next',
		defaultMessage: 'Next',
		description: 'Text for going to the next page in the workload table',
	},
	noResults: {
		id: 'servicedesk-customers-organization-common.table.pagination.no-results',
		defaultMessage: 'No matches for your search.',
		description: 'No results for workload search',
	},
});
