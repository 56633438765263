import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addCustomers: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.add-customers',
		defaultMessage: 'Add customers',
		description: 'header for adding customers',
	},
	requiredField: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.required-field',
		defaultMessage: 'Required fields are marked with an asterisk',
		description: 'message to inform that fields are required',
	},
});
