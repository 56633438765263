import React from 'react';
import ButtonGroup from '@atlaskit/button/button-group';
import { Box } from '@atlaskit/primitives';
import { useCommonPageContext } from '@atlassian/jira-servicedesk-common-page-context';
import { CanInviteCustomers } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/can-invite-customers';
import { CanManageOrganization } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/can-manage-organization';
import { AddCustomersDialogContextProvider } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/add-customers-dialog-context/main.tsx';
import { AddCustomersInlineDialogButton } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/add-customers-dialog/index.tsx';
import { useAppStore } from '@atlassian/jira-servicedesk-customers/src/controllers/app-store/index.tsx';
import { AddOrganizationsInlineDialogButton } from './add-organizations-dialog';

export const HeaderActions = () => {
	const { baseUrl, projectKey } = useCommonPageContext();

	const [{ userPermissions }, { fetchAndUpdatePageData, updateOptimisticCustomers }] =
		useAppStore();

	const { canAdminister, canManageOrganizations, canInvite } = userPermissions || {};

	return (
		<Box>
			<ButtonGroup>
				<CanManageOrganization
					canAdminister={canAdminister}
					canManageOrganizations={canManageOrganizations}
				>
					<AddOrganizationsInlineDialogButton />
				</CanManageOrganization>
				<CanInviteCustomers canInvite={canInvite}>
					<AddCustomersDialogContextProvider
						// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
						baseUrl={baseUrl}
						// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
						projectKey={projectKey}
						userPermissions={userPermissions}
					>
						<AddCustomersInlineDialogButton
							onUpdateAppState={fetchAndUpdatePageData}
							onPrependOptimisticCustomers={updateOptimisticCustomers}
						/>
					</AddCustomersDialogContextProvider>
				</CanInviteCustomers>
			</ButtonGroup>
		</Box>
	);
};
