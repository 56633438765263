import { useCallback, useMemo, useEffect } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useCommonPageContext as useCommonPageContextDI } from '@atlassian/jira-servicedesk-common-page-context';
import {
	PendingSubmission,
	Successful,
	Unsuccessful,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/form-status/index.tsx';
import { useAddCustomersDialogContext as useAddCustomersDialogContextDI } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/add-customers-dialog-context/index.tsx';
import { useInviteCustomerService } from '@atlassian/jira-servicedesk-customers-organization-common/src/services/invite-customers/main.tsx';
import { splitInputTextBySeparator } from '../../../common/utils';

export const useFormSubmissionUtils = (
	useCommonPageContext: typeof useCommonPageContextDI = useCommonPageContextDI,
	useAddCustomersDialogContext: typeof useAddCustomersDialogContextDI = useAddCustomersDialogContextDI,
) => {
	const { baseUrl, projectKey } = useCommonPageContext();
	const isMultiHcAndInviteRoutingEnabled =
		ff('multi_help_center_kosh_integration') && ff('enable_invitation_routing_with_channel_44900');
	const {
		data: invitedCustomers,
		loading: isSubmitting,
		error,
		fetch: inviteCustomersFetch,
		resetAll: resetInviteCustomer,
		/* @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string' */
	} = useInviteCustomerService(baseUrl, projectKey);
	const [
		{ customerEmailsInputFieldValue, customers, defaultOrganization, organization, helpCenter },
		{ resetForm },
	] = useAddCustomersDialogContext();

	// onSubmit get email's from validated list and
	// concat with email's from input text field and pass it down to api
	// When multiHc is disabled we send the helpCenter as undefined which will result to default help center
	const selectedHelpCenter = isMultiHcAndInviteRoutingEnabled ? helpCenter : undefined;
	const onFormSubmit = useCallback(() => {
		inviteCustomersFetch(
			[
				...customers.map(({ emailAddress }) => emailAddress),
				...splitInputTextBySeparator(customerEmailsInputFieldValue),
			],
			organization || defaultOrganization,
			selectedHelpCenter,
		);
	}, [
		customerEmailsInputFieldValue,
		customers,
		defaultOrganization,
		inviteCustomersFetch,
		organization,
		selectedHelpCenter,
	]);

	// if there are any failed errors switch to Unsuccessful view.
	const formSubmissionStatus = useMemo(() => {
		if (invitedCustomers) {
			const { failed } = invitedCustomers;
			return failed.length > 0 ? Unsuccessful : Successful;
		}

		return PendingSubmission;
	}, [invitedCustomers]);

	useEffect(
		() => () => {
			if (formSubmissionStatus !== PendingSubmission) {
				resetForm();
				resetInviteCustomer();
			}
		},
		[formSubmissionStatus, resetForm, resetInviteCustomer],
	);

	return {
		invitedCustomers,
		isSubmitting,
		formSubmissionStatus,
		error,
		onFormSubmit,
	};
};
