import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewOpen: {
		id: 'servicedesk-customers-organization-common.table.requests-cell.view-open',
		defaultMessage: 'View open',
		description: 'label for view open requests',
	},
	openRequests: {
		id: 'servicedesk-customers-organization-common.table.requests-cell.open-requests',
		defaultMessage: '{requestsCount} open',
		description: 'label for open requests',
	},
	viewClosed: {
		id: 'servicedesk-customers-organization-common.table.requests-cell.view-closed',
		defaultMessage: 'View closed',
		description: 'label for view closed requests',
	},
	closedRequests: {
		id: 'servicedesk-customers-organization-common.table.requests-cell.closed-requests',
		defaultMessage: '{requestsCount} closed',
		description: 'label for closed requests',
	},
});
