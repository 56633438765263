import React, { useCallback } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { FooterControls } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/inline-dialog/footer-controls/index.tsx';
import { useAddCustomersDialogContext as useAddCustomersDialogContextDI } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/add-customers-dialog-context/index.tsx';

export type Props = {
	isLoading: boolean;
	useAddCustomersDialogContext: typeof useAddCustomersDialogContextDI;
	onCancel: () => void;
	hasMultiHcAndInviteRouting: boolean;
};

export const Footer = ({
	useAddCustomersDialogContext,
	isLoading,
	onCancel,
	hasMultiHcAndInviteRouting,
}: Props) => {
	const [{ customerEmailsInputFieldValue, customers, helpCenter }] = useAddCustomersDialogContext();
	const isCustomerFormValid = Boolean(customerEmailsInputFieldValue || customers.length);
	const isValidHelpCenterSelected = hasMultiHcAndInviteRouting ? Boolean(helpCenter) : true;
	const isFormValid = isCustomerFormValid && isValidHelpCenterSelected;

	// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type.
	const onSubmit = useCallback((_, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'addCustomerButton');
	}, []);

	const handleOnCancel = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
		(_, analyticsEvent) => {
			onCancel();
			fireUIAnalytics(analyticsEvent, 'cancelAddCustomerButton');
		},
		[onCancel],
	);

	return (
		<FooterControls
			isFormValid={isFormValid}
			isLoading={isLoading}
			onSubmit={onSubmit}
			onCancel={handleOnCancel}
		/>
	);
};

Footer.defaultProps = {
	useAddCustomersDialogContext: useAddCustomersDialogContextDI,
	isLoading: false,
	onCancel: noop,
};
