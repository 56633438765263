import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	confirmationTitle: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.confirmation-modal.confirmation-title',
		defaultMessage: 'Add customer to multiple projects',
		description: 'title for add customer to multiple projects',
	},
	multipleProjectOrganization: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.confirmation-modal.multiple-project-organization',
		defaultMessage:
			'The organization {name} is used in {numberOfOtherProjects, plural, one {# project} other {# projects}}. Customers you add to it can view requests shared with the organization and raise their own requests in those projects.',
		description:
			'confirmation message displayed when selected organization is part of multiple projects',
	},
	addCustomers: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.confirmation-modal.add-customers',
		defaultMessage: 'Add customers',
		description: 'Button to add customers',
	},
	cancel: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.confirmation-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Button to cancel',
	},
});
