import React from 'react';
import Button from '@atlaskit/button/standard-button';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { useCommonPageContext } from '@atlassian/jira-servicedesk-common-page-context';
import HtmlFormattedMessage from '@atlassian/jira-servicedesk-common/src/i18n/format-html-message';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

type ViewConfigurationProps = Intl;

export const CustomerNotificationHelpTextForAdminLink = () => {
	const { formatMessage } = useIntl();
	const { baseUrl, projectKey } = useCommonPageContext();
	const customerNotificationLink = `${baseUrl}/jira/servicedesk/projects/${projectKey}/settings/customer-notifications/account-notification/invite-customer`;
	const notificationMsg = messages.updatedNotificationsForAdmin;
	return ff('jsm-csm-a11y-fixes_ho588') ? (
		<Button appearance="link" spacing="none" target="_blank" href={customerNotificationLink}>
			{formatMessage(messages.updatedNotificationsForAdminNew)}
		</Button>
	) : (
		<HtmlFormattedMessage
			{...notificationMsg}
			values={{
				tagStart: `<a href="${customerNotificationLink}" target="_blank"/>`,
				tagEnd: '</a>',
			}}
		/>
	);
};

const ConfigurationLinkView = ({ intl: { formatMessage } }: ViewConfigurationProps) => {
	const { baseUrl, projectKey } = useCommonPageContext();
	return (
		<Button
			appearance="link"
			spacing="none"
			href={`${baseUrl}/jira/servicedesk/projects/${projectKey}/settings/customer-notifications/account-notification/invite-customer`}
		>
			{formatMessage(messages.viewConfigurationForAdmin)}
		</Button>
	);
};

export const ViewConfigurationLink = injectIntl(ConfigurationLinkView);
