import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	flagTitle: {
		id: 'servicedesk-customers.table.flag-title',
		defaultMessage: '{customerName} removed from {projectName}',
		description: 'Success message for remove customer',
	},
	removeCustomerError: {
		id: 'servicedesk-customers.table.remove-customer-error',
		defaultMessage:
			'Something has gone wrong with your request. If symptoms persist contact your System Administrator.',
		description: 'Error message for remove customer',
	},
	resendInviteSuccessFlagTitle: {
		id: 'servicedesk-customers.table.resend-invite-success-flag-title',
		defaultMessage: 'Invite sent successfully',
		description:
			'Success flag title for when an agent tries to resend an invite email to a customer',
	},
	resendInviteSuccessFlagDescription: {
		id: 'servicedesk-customers.table.resend-invite-success-flag-description',
		defaultMessage: 'An email has been sent to {email} to set a password for their account.',
		description:
			'Success flag message for when an agent tries to resend an invite email to a customer',
	},
	resendVerificationEmailTitle: {
		id: 'servicedesk-customers.table.resend-verification-email-title',
		defaultMessage: 'Verification email sent successfully',
		description:
			'Success flag title for when an agent tries to resend an verification email to a customer',
	},
	resendVerificationEmailDescription: {
		id: 'servicedesk-customers.table.resend-verification-email-description',
		defaultMessage: 'An email has been sent to {email} to verify the customer’s email address.',
		description:
			'Success flag message for when an agent tries to resend an verification email to a customer',
	},
	resendInviteFailureFlagTitle: {
		id: 'servicedesk-customers.table.resend-invite-failure-flag-title',
		defaultMessage: 'We couldn’t resend the invite',
		description: 'Error flag title for when an agent tries to resend an invite email to a customer',
	},
	resendInviteFailureFlagDescription: {
		id: 'servicedesk-customers.table.resend-invite-failure-flag-description',
		defaultMessage: 'Check your network connection, refresh the page and try again.',
		description:
			'Error flag message for when an agent tries to resend an invite email to a customer',
	},
});
