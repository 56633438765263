import React, { type FC, type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';

export { OrganizationsSelectField } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/main.tsx';
export { isEventFromOrganizationSelectDropList } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/utils.tsx';

export type {
	Props as OrganizationSearchServiceProps,
	Organization,
	Organizations,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/organization-search-service/types.tsx';

export { EditorCell } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/editable-table/editor-cell/index.tsx';

export { InvalidEmailField } from './invalid-email-field';

export { SuccessFlag } from './success-flag';

export { DialogWrapper } from './inline-dialog';
export { FormErrors } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/inline-dialog/form-errors/index.tsx';
export { Footer } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/inline-dialog/footer/index.tsx';
export { FooterControls } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/inline-dialog/footer-controls/index.tsx';

export { CollapsibleSectionMessage } from './collapsible-section-message';

export { ResendInviteErrorFlag } from './resend-invite-error-flag';

export { MultiLineText } from './multiline-text';

export { ResendVerificationEmailErrorFlag } from './resend-verification-email-error-flag';

export const RowActiveCellWrapper: FC<PropsWithChildren> = ({ children }) => (
	<Box xcss={activeCellWrapperStyles} data-class="RowActiveCellWrapper">
		{children}
	</Box>
);

const activeCellWrapperStyles = xcss({
	cursor: 'pointer',
	opacity: 0,

	':hover': {
		opacity: 1,
	},
	':focus': {
		opacity: 1,
	},
	':focus-within': {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TableWithRowsActiveCellBase = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'tr:focus [data-class="RowActiveCellWrapper"], tr:hover [data-class="RowActiveCellWrapper"]': {
		opacity: 1,
	},
	// this is separate from the two above because Edge won't apply the :hover or :focus styles with :focus-within
	//  in the same style statement (because it doesn't support focus-within and so ignores the entire selector)
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'tr:focus-within [data-class="RowActiveCellWrapper"]': {
		opacity: 1,
	},
});
