import isNil from 'lodash/isNil';
import type { Organizations } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/organization-search-service/types.tsx';
import { OrganizationSelectIdentifier } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/organizations-single-select/constants.tsx';
import type {
	Options,
	Option,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/organizations-single-select/types.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

export const mapOrganizationsToLabelOptions = (
	organizations: Organizations,
	formatMessage: FormatMessage,
) =>
	organizations.map<Option>(({ id, name, numberOfOtherProjects }) => ({
		label: name,
		name,
		status: `${
			numberOfOtherProjects > 0
				? formatMessage(messages.inOtherProjects, { numberOfOtherProjects })
				: ''
		}`,
		value: id,
		numberOfOtherProjects,
	}));

export const isSelectedOptionValid = (options: Options, selectedOption: Option | null) => {
	if (!selectedOption) {
		return true;
	}

	const optionExistInList = options.some(
		({ name }) => name.toLowerCase() === selectedOption.name.toLowerCase(),
	);
	return !optionExistInList;
};

export const isEventFromOrganizationSelectDropList = (event: MouseEvent) => {
	if (event && !isNil(event.target)) {
		// @ts-expect-error - TS2339 - Property 'classList' does not exist on type 'EventTarget'.
		const { classList } = event.target;
		return classList.contains(`${OrganizationSelectIdentifier}__option`);
	}
	return false;
};
