import React, { type ReactNode } from 'react';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl } from '@atlassian/jira-intl';
import { SEARCH_PER_PAGE, LIST_PER_PAGE } from './constants';
import messages from './messages';

const DEFAULT_PAGE_RANGE = 40;

export type PaginationHookType = {
	query: string;
	totalHasBeenDetermined: boolean;
	total: number;
	pageNumber: number;
	lastDeterminedPaged: number;
};

export const usePaginationHook = (props: PaginationHookType) => {
	const { formatMessage } = useIntl();
	const { query, totalHasBeenDetermined, total, lastDeterminedPaged, pageNumber } = props;
	const isSearching = query !== '';

	const rowsPerPage = isSearching ? SEARCH_PER_PAGE : LIST_PER_PAGE;

	const start = (pageNumber - 1) * rowsPerPage + 1;
	const end = Math.min((pageNumber - 1) * rowsPerPage + rowsPerPage, total);

	// 1-100 of 100+
	const listLabel = formatMessage(messages.pageLabel, {
		start,
		end,
		total,
		hasPlusSign: !totalHasBeenDetermined,
		span: (chunks: ReactNode) => <VisuallyHidden>{chunks}</VisuallyHidden>,
	});

	// 1-20 of 20, search is always limited to 20 results
	const searchLabel = formatMessage(messages.searchLabel, {
		start,
		end,
		total,
		span: (chunks: ReactNode) => <VisuallyHidden>{chunks}</VisuallyHidden>,
	});

	const paginationLabel = isSearching ? searchLabel : listLabel;

	const isMaxLimitReached = lastDeterminedPaged > DEFAULT_PAGE_RANGE;
	const defaultTotalPages =
		totalHasBeenDetermined || isMaxLimitReached ? pageNumber : lastDeterminedPaged;

	const totalPages = isSearching ? total : defaultTotalPages;
	const showPagination = !isSearching && totalPages > 1;
	const hideRightEllipsis = isMaxLimitReached || totalHasBeenDetermined;

	// < 1, 2, ... >
	const bracketSize = lastDeterminedPaged < 2 ? 2 : 5;

	return {
		isMaxLimitReached,
		paginationLabel,
		showPagination,
		totalPages,
		bracketSize,
		hideRightEllipsis,
	};
};
