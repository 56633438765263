import React from 'react';
import { styled, css } from '@compiled/react';
import InlineMessage from '@atlaskit/inline-message';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import type { Customer } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import { normalizeToUserProfile } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/utils.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { HAS_LOGGED_IN, NOT_AVAILABLE, PENDING_LOGIN } from '../../../common/types/constants';
import messages from './messages';

type LoggedInStatusCellProps = {
	profile: Customer;
};

const getCutoffDateForCustomerVerification = () =>
	getFeatureFlagValue('cut-off-date-for-tenants-for-customer-invite-verification-feature', '');

export const LoggedInStatusCellView = ({ profile }: LoggedInStatusCellProps) => {
	const { formatMessage } = useIntl();
	const userProfile = normalizeToUserProfile(profile);
	const tenantContext = useTenantContext();
	if (tenantContext) {
		const { firstActivationDateMs } = tenantContext;
		const cutOffDate = getCutoffDateForCustomerVerification();
		const isTenantActivationAfterCutOffDate =
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			new Date(firstActivationDateMs as number) >= new Date(cutOffDate);

		if (userProfile) {
			const { loggedInStatus, emailVerifiedStatus } = userProfile;
			if (loggedInStatus === NOT_AVAILABLE) {
				return null;
			}

			const appendUnverifiedEmailText =
				ff('sptn-1697-email-verified-signal-on-customers-page_v2g52') &&
				isTenantActivationAfterCutOffDate;

			if (appendUnverifiedEmailText && emailVerifiedStatus === false) {
				let status = '';
				if (loggedInStatus === HAS_LOGGED_IN) {
					status = formatMessage(messages.activeStatusLabel);
				} else if (loggedInStatus === PENDING_LOGIN) {
					status = formatMessage(messages.inviteStatusLabel);
				}
				return (
					<span>
						<p>{status}</p>
						<PrivateEmailLabel>
							<InlineMessageWrapper>
								{formatMessage(messages.unverifiedEmail)}
								<InlineMessage appearance="info" placement="bottom-end">
									<InlineMessageContent>
										{formatMessage(messages.unverifiedEmailTooltip)}
									</InlineMessageContent>
								</InlineMessage>
							</InlineMessageWrapper>
						</PrivateEmailLabel>
					</span>
				);
			}

			return (
				<span>
					{loggedInStatus === HAS_LOGGED_IN
						? formatMessage(messages.activeStatusLabel)
						: formatMessage(messages.inviteStatusLabel)}
				</span>
			);
		}
	}
	return null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineMessageWrapper = styled.div(
	{
		display: 'flex',
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	css({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& button': {
			height: token('space.200', '16px'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'& span': {
				height: token('space.200', '16px'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'& svg': {
				width: token('space.200', '16px'),
				height: token('space.200', '16px'),
				color: token('color.text.subtlest', '#5e6c84'),
			},
		},
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineMessageContent = styled.div({
	display: 'flex',
	fontWeight: 'normal',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 30}px !important`,
	whiteSpace: 'normal',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.span({
	lineHeight: 1.4,
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	textAlign: 'left',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PrivateEmailLabel = styled(Label)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N90),
	fontSize: '11px',
});
