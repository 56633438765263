import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	resendInviteLinkLabel: {
		id: 'servicedesk-customers-organization-common.table.actions-cell-view.resend-invite-link-label',
		defaultMessage: 'Resend Invite',
		description: 'Label for button used to resend invites',
	},
	resendVerificationEmailLabel: {
		id: 'servicedesk-customers-organization-common.table.actions-cell-view.resend-verification-email-label',
		defaultMessage: 'Resend verification email',
		description: 'Label for button used to resend verification email',
	},
});
