import React from 'react';
import { styled } from '@compiled/react';
import AkLozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type LozengeGroupProps = {
	organizationNames?: string[];
};

export const LozengeGroup = ({ organizationNames }: LozengeGroupProps) => {
	if (!organizationNames || !organizationNames.length) {
		return null;
	}
	return (
		<Box xcss={wrapperStyles} tabIndex={0}>
			{organizationNames.map((organizationName) => (
				<Spaced key={organizationName}>
					{/* @ts-expect-error - TS2322 - Type '{ children: string; value: string; }' is not assignable to type 'IntrinsicAttributes & LozengeProps'. */}
					<AkLozenge value="default">{organizationName}</AkLozenge>
				</Spaced>
			))}
		</Box>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Spaced = styled.span({
	marginRight: token('space.100', '8px'),
});

const wrapperStyles = xcss({
	marginLeft: 'space.100',
});
