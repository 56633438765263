import React from 'react';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Flag from '@atlassian/jira-flags/src/common/ui/components/success-auto-dismiss-flag';

type SuccessFlagProps = {
	title: string;
	description: string;
	onDismissed: () => void;
};

export const SuccessFlag = ({ title, description, onDismissed }: SuccessFlagProps) => (
	<Flag
		icon={<SuccessIcon primaryColor={token('color.icon.success', G300)} label="Success" />}
		title={title}
		description={description}
		id={Math.random()}
		onDismissed={onDismissed}
		testId="servicedesk-customers-organization-common.common.ui.success-flag.success-flag"
	/>
);
