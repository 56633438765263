import React, { createContext, useState, useEffect, useCallback } from 'react';
import noop from 'lodash/noop';
import { usePopupController } from '@atlassian/jira-servicedesk-common/src/use-popup-controller';
import { CustomerTypes } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/customers/types.tsx';
import { useValidateCustomerEmailsService as useValidateCustomerEmailsServiceDI } from '@atlassian/jira-servicedesk-customers-organization-common/src/services/validate-customer-emails/index.tsx';
import type { AddCustomersDialogContextData, AddCustomersDialogProviderProps } from './types';

export const AddCustomersDialogContext = createContext<AddCustomersDialogContextData>([
	{
		isOpen: false,
		customers: [],
		isValidating: false,
		organization: undefined,
		helpCenter: undefined,
		defaultOrganization: undefined,
		customerEmailsInputFieldValue: '',
		userPermissions: undefined,
		emailValidationErrors: null,
		serviceErrors: null,
		emailPresetValue: '',
	},
	{
		togglePopup: noop,
		setCustomers: noop,
		setOrganization: noop,
		setHelpCenter: noop,
		setCustomerEmailsInputFieldValue: noop,
		// @ts-expect-error - TS2322 - Type '(...args: any[]) => void' is not assignable to type '(emails: string[]) => Promise<undefined> | undefined'.
		onValidateCustomerEmails: noop,
		resetForm: noop,
		resetAndCloseForm: noop,
		setEmailPresetValue: noop,
	},
]);

export const AddCustomersDialogContextProvider = ({
	children,
	baseUrl,
	projectKey,
	userPermissions,
	useValidateCustomerEmailsService,
	defaultOrganization,
}: AddCustomersDialogProviderProps) => {
	const [customerEmailsInputFieldValue, setCustomerEmailsInputFieldValue] = useState<string>('');
	const [customers, setCustomers] = useState<unknown>([]);
	const [organization, setOrganization] = useState<unknown>();
	const [helpCenter, setHelpCenter] = useState<unknown>();
	const [isOpen, togglePopup, closePopup] = usePopupController();
	const [emailPresetValue, setEmailPresetValue] = useState<string>('');

	const {
		loading: isValidating,
		data: validatedEmails,
		fetch: onValidateCustomerEmailsAsync,
		emailValidationErrors,
		resetEmailValidation,
		error: serviceErrors,
		resetError,
	} = useValidateCustomerEmailsService({
		baseUrl,
		projectKey,
	});

	useEffect(() => {
		if (!isOpen) {
			resetEmailValidation();
		}
	}, [isOpen, resetEmailValidation]);

	const onValidateCustomerEmails = useCallback(
		(emails: string[]) => {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS7031 - Binding element 'emailAddress' implicitly has an 'any' type.
			const existingValidatedEmails = customers.map(({ emailAddress }) => emailAddress);
			onValidateCustomerEmailsAsync([...emails, ...existingValidatedEmails]);
		},
		[customers, onValidateCustomerEmailsAsync],
	);

	useEffect(() => {
		if (!isOpen) {
			resetError();
		}
	}, [isOpen, resetError]);

	const resetForm = useCallback(() => {
		setCustomerEmailsInputFieldValue('');
		setEmailPresetValue('');
		setCustomers([]);
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
		setOrganization();
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
		setHelpCenter();
	}, []);

	const resetAndCloseForm = useCallback(() => {
		resetForm();
		closePopup();
	}, [closePopup, resetForm]);

	const state = {
		isOpen,
		isValidating,
		customers,
		organization,
		helpCenter,
		defaultOrganization,
		userPermissions,
		customerEmailsInputFieldValue,
		emailValidationErrors,
		serviceErrors,
		emailPresetValue,
	};

	const actions = {
		togglePopup,
		setCustomers,
		setOrganization,
		setHelpCenter,
		setCustomerEmailsInputFieldValue,
		onValidateCustomerEmails,
		resetForm,
		resetAndCloseForm,
		setEmailPresetValue,
	};

	useEffect(() => {
		if (validatedEmails) {
			// We only want validated emails to be prefilled as a list.
			// If we have one invalid email in the valid email list, we will
			// show this in a text area, controlled by emailPreset value
			const invalidCustomers = validatedEmails.filter(
				(customer) => customer.type === CustomerTypes.invalidCustomer,
			);

			if (invalidCustomers.length === 0) {
				setCustomers(
					validatedEmails.filter((customer) => customer.type === CustomerTypes.validCustomer),
				);
			}

			// clear email-text-field value upon validation
			setCustomerEmailsInputFieldValue('');
		}
	}, [validatedEmails]);

	return (
		// @ts-expect-error - TS2322 - Type '{ isOpen: boolean; isValidating: boolean; customers: unknown; organization: unknown; defaultOrganization: Organization | undefined; userPermissions: UserPermissions | undefined; customerEmailsInputFieldValue: string; emailValidationErrors: EmailValidationErrorType; serviceErrors: ServiceError; emailPresetValue: stri...' is not assignable to type 'AddCustomersDialogState'. | TS2322 - Type '{ togglePopup: () => void; setCustomers: React.Dispatch<unknown>; setOrganization: React.Dispatch<unknown>; setCustomerEmailsInputFieldValue: React.Dispatch<React.SetStateAction<string>>; onValidateCustomerEmails: (emails: string[]) => void; resetForm: () => void; resetAndCloseForm: () => void; setEmailPresetValue: ...' is not assignable to type 'AddCustomersDialogActions'.
		<AddCustomersDialogContext.Provider value={[state, actions]}>
			{children}
		</AddCustomersDialogContext.Provider>
	);
};

AddCustomersDialogContextProvider.defaultProps = {
	useValidateCustomerEmailsService: useValidateCustomerEmailsServiceDI,
};

AddCustomersDialogContextProvider.displayName = 'AddCustomersDialogContext';
