import React, { useCallback, useMemo } from 'react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { FlagId } from '@atlassian/jira-flags';
import Flag from '@atlassian/jira-flags/src/common/ui/components/ak-flag';
import { useFlagService } from '@atlassian/jira-flags/src/services';
import { DISMISS_COMMAND, fromFlagId } from '@atlassian/jira-flags/src/services/types';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	id: FlagId;
	isRateLimited?: boolean;
};

export const ResendVerificationEmailErrorFlag = ({ id, isRateLimited = false }: Props) => {
	const { formatMessage } = useIntl();

	const { dismissFlag } = useFlagService();

	const onDismissed = useCallback(() => {
		dismissFlag({
			command: DISMISS_COMMAND,
			id,
		});
	}, [dismissFlag, id]);

	let flagDescription = '';
	let flagTitle = '';

	if (isRateLimited) {
		flagDescription = formatMessage(
			messages.resendVerificationEmailRateLimitFailureFlagDescription,
		);
		flagTitle = formatMessage(messages.resendVerificationEmailRateLimitFailureFlagTitle);
	} else {
		flagDescription = formatMessage(messages.resendVerificationEmailGenericFailureFlagDescription);
		flagTitle = formatMessage(messages.resendVerificationEmailGenericFailureFlagTitle);
	}

	return useMemo(
		() => (
			<Flag
				id={fromFlagId(id)}
				icon={<ErrorIcon label="error" primaryColor={token('color.icon.danger', R400)} />}
				title={flagTitle}
				description={flagDescription}
				onDismissed={onDismissed}
			/>
		),
		[id, flagTitle, flagDescription, onDismissed],
	);
};
