import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	navigationLabel: {
		id: 'servicedesk-customers-organization-common.table.bracket-pagination.navigation-label',
		defaultMessage: 'Customers pagination',
		description:
			'Label for the navigation element that contains the pagination buttons for the customers table',
	},
	navigationDescription: {
		id: 'servicedesk-customers-organization-common.table.bracket-pagination.navigation-description',
		defaultMessage: 'Activating a pagination button will update the results above.',
		description:
			'Description for the navigation element that contains the pagination buttons for the customers table',
	},
	pageLabel: {
		id: 'servicedesk-customers-organization-common.table.bracket-pagination.page-label',
		defaultMessage: 'Page {pageNumber}',
		description: 'Label for the pagination buttons that display the page number',
	},
	pageLabelSelected: {
		id: 'servicedesk-customers-organization-common.table.bracket-pagination.page-label-selected',
		defaultMessage: 'Page {pageNumber}, current page',
		description: 'Label for the active pagination button that displays the current page number',
	},
});
