import { isNew as isNewOrganization } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/organizations/constants.tsx';
import type { Organizations } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/organizations/types.tsx';
import type { BaseUrl, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getUrl = (baseUrl: BaseUrl, projectId: ProjectId) =>
	`${baseUrl}/rest/servicedesk/1/servicedesk/${projectId}/organisation/invite`;

export const getRequestPayload = (organizations: Organizations = []) =>
	organizations.reduce(
		(payload, { name, value }) => {
			if (isNewOrganization(value)) {
				// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
				payload.newOrganisationNames.push(name);
			} else {
				// @ts-expect-error - TS2345 - Argument of type 'string | number | undefined' is not assignable to parameter of type 'never'.
				payload.existingOrganisationIds.push(value);
			}
			return payload;
		},
		{
			existingOrganisationIds: [],
			newOrganisationNames: [],
		},
	);
