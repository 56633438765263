export const HAS_LOGGED_IN = 'HAS_LOGGED_IN' as const;
export const PENDING_LOGIN = 'PENDING_LOGIN' as const;
export const NOT_AVAILABLE = 'NOT_AVAILABLE' as const;
export const NUMBER_OF_HELP_CENTERS_TO_FETCH = 10;
// This is subtracted from th total number of helpcenters to prefetch the next page
export const PREFETCH_OPTIONS = 5;
export const PROFILE_TYPES = {
	user: 'User',
	organization: 'Organisation',
} as const;
