import React from 'react';
import AkEmptyState from '@atlaskit/empty-state';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

const EmptyStateView = ({ intl: { formatMessage } }: Intl) => (
	<div
		data-testid="servicedesk-customers-organization-common.ui.table.pagination.wrapper"
		aria-live="polite"
	>
		<AkEmptyState header={formatMessage(messages.noResults)} />
	</div>
);

export const EmptyState = injectIntl(EmptyStateView);
