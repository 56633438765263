import React, { useMemo, useState } from 'react';
import fill from 'lodash/fill';
// @ts-expect-error - TS2614 - Module '"@atlaskit/dynamic-table"' has no exported member 'RowType'. Did you mean to use 'import RowType from "@atlaskit/dynamic-table"' instead?
import type { RowType } from '@atlaskit/dynamic-table';
import { CustomerCell } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/table/customer-cell/main.tsx';
import { isOrganization } from '../../common/types/utils';
import { ActionsCellView } from './actions-cell-view';
import { DEFAULT_PAGE_DATA } from './constants';
import { EditorCell } from './editor-cell';
import { LoggedInStatusCellView } from './logged-in-status-cell';
import { OpenRequestsCell, ClosedRequestsCell } from './requests-cell';
import type { TableProps } from './types';

export const useTableRows = ({
	pageData,
	isEditable,
	projectKey,
	projectName,
	onRemove,
	onActionSuccess,
	onActionFailure,
	showRemoveCustomerTooltip,
	rows: customRows,
	hasActions = true,
}: TableProps) => {
	const { total, page, rowsPerPage, customers } = pageData || DEFAULT_PAGE_DATA;
	const [tablePage, setTablePage] = useState<number>(1);

	const rows = useMemo(() => {
		let totalRows: RowType[] = [];

		if (!customers) return totalRows;

		const emptyRow: RowType = { cells: [] };
		const numberOfPreviousRows = (page - 1) * rowsPerPage;
		const numberOfNextRows = Math.max(0, total - rowsPerPage - numberOfPreviousRows);
		const previousRows: RowType[] = fill(Array(numberOfPreviousRows), emptyRow);
		const nextRows: RowType[] = fill(Array(numberOfNextRows), emptyRow);

		if (customRows) {
			return [...previousRows, ...customRows, ...nextRows];
		}

		const hasOrganisationRow = customers.some(isOrganization);

		const visibleRows = customers.map((customer) => {
			const cells = [];
			cells.push({
				key: `name-${customer.identifier}`,
				content: <CustomerCell profile={customer} projectKey={projectKey} />,
			});

			cells.push({
				key: `open-requests-${customer.identifier}`,
				content: <OpenRequestsCell profile={customer} />,
			});

			cells.push({
				key: `closed-requests-${customer.identifier}`,
				content: <ClosedRequestsCell profile={customer} />,
			});

			cells.push({
				key: `customer-logged-in-status-${customer.identifier}`,
				content: <LoggedInStatusCellView profile={customer} />,
			});

			if (hasActions) {
				cells.push({
					key: `resend-invite-${customer.identifier}`,
					content: (
						<ActionsCellView
							profile={customer}
							onActionSuccess={onActionSuccess}
							onActionFailure={onActionFailure}
						/>
					),
				});
			}

			const isOrganizationRow = isOrganization(customer);
			if (isEditable || isOrganizationRow) {
				cells.push({
					key: `modify-${customer.identifier}`,
					content: (
						<EditorCell
							profile={customer}
							projectName={projectName}
							onRemove={onRemove}
							showRemoveCustomerTooltip={showRemoveCustomerTooltip}
						/>
					),
				});
			} else if (hasOrganisationRow) {
				cells.push({
					key: `modify-${customer.identifier}`,
					content: null,
				});
			}

			return {
				key: `customer-${customer.identifier}`,
				cells,
			};
		});

		totalRows = [...previousRows, ...visibleRows, ...nextRows];

		return totalRows;
	}, [
		customers,
		page,
		rowsPerPage,
		total,
		customRows,
		projectKey,
		hasActions,
		isEditable,
		onActionSuccess,
		onActionFailure,
		projectName,
		onRemove,
		showRemoveCustomerTooltip,
	]);

	return {
		rows,
		tablePage,
		setTablePage,
	} as const;
};
