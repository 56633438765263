import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const customersSearchPageLoadExperience = new UFOExperience(
	'jsm-customers-component-search-page-load',
	{
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
);

export const customersAddOrganizationsExperience = new UFOExperience(
	'jsm-customers-component-add-organizations',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const addCustomersExperience = new UFOExperience('jsm-customers-component-add-customers', {
	type: ExperienceTypes.Experience,
	performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const organizationsSearchPageLoadExperience = new UFOExperience(
	'jsm-organizations-component-search-page-load',
	{
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
);

export const organizationsEditOrganizationExperience = new UFOExperience(
	'jsm-organizations-component-edit-organization',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const organizationsDeleteOrganizationExperience = new UFOExperience(
	'jsm-organizations-component-delete-organization',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const organizationsRemoveFromProjectExperience = new UFOExperience(
	'jsm-organizations-component-remove-from-project',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const removeOrganizationExperience = new UFOExperience(
	'jsm-customers-component-remove-organization',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);
// This is used to track help center dropdown experience
export const helpCenterFetchExperience = new UFOExperience('jsm-help-center-data-fetch', {
	type: ExperienceTypes.Experience,
	performanceType: ExperiencePerformanceTypes.InlineResult,
});
