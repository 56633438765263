import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	customerEmails: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.dynamic-input-field.customer-emails',
		defaultMessage: 'Customer email',
		description: 'description of label for customer emails',
	},
	placeHolder: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.dynamic-input-field.place-holder',
		defaultMessage: 'Enter email address(es)',
		description: 'description of label for customer emails',
	},
	info: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.dynamic-input-field.info',
		defaultMessage:
			'Add email addresses one by one, or enter multiple email addresses separated by commas.',
		description: 'tooltip message for information icon for customer email label',
	},
	invalidEmail: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.dynamic-input-field.invalid-email',
		defaultMessage: 'Enter a valid email address.',
		description: 'description of validation message for invalid email address',
	},
	signupUnAvailable: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.dynamic-input-field.signup-un-available',
		defaultMessage: 'Sign-up is currently unavailable.',
		description: 'description of validation message when sign-up is unavailable',
	},
});
