import React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import AppBase from '@atlassian/jira-app-base';
import { useRouter } from '@atlassian/jira-router';
import { ServiceDeskAnalyticsContext } from '@atlassian/jira-servicedesk-analytics/src/ui/servicedesk-analytics-context/index.tsx';
import {
	CSM_CUSTOMER_CONTEXT_FEATURE,
	ENABLED,
} from '@atlassian/jira-servicedesk-common/src/service-project-features/constants.tsx';
import { ServiceDeskErrorBoundary } from '@atlassian/jira-servicedesk-common/src/ui/components/servicedesk-error-boundary';
import { APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import CustomersSpaApp from '@atlassian/jira-servicedesk-customers';
import { ErrorView as ErrorViewWithAnalytics } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/error-view/index.tsx';
import useServiceProjectFeatures from '@atlassian/jira-servicedesk-features-store';
import { JsdGettingStartedPanelShouldRender } from '@atlassian/jira-servicedesk-getting-started-panel/src/async';
import { ServiceDeskDocumentTitle } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/document-title/index.tsx';
import { usePageContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-context/main.tsx';
import { PageStyle } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-style/index.tsx';
import { SpaPageWrapper } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-wrapper/index.tsx';
import { AsyncContactsAppLayout } from '@atlassian/jira-spa-apps-servicedesk-contacts/src/async';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';

const CustomersTheme = { isNestedGrid: true, spacing: 'compact', columns: 1 } as const;

const pageId = 'servicedesk-customers';

export const CustomersAppLayout = () => {
	const [{ match }] = useRouter();
	const [{ data: pageContextData, error }] = usePageContext();
	const { tenantContext, projectContext } = pageContextData || {};
	const { projectName } = projectContext || {};
	const { baseUrl } = tenantContext || {};
	const { data: serviceProjectFeatureData } = useServiceProjectFeatures();

	if (serviceProjectFeatureData?.[CSM_CUSTOMER_CONTEXT_FEATURE]?.status === ENABLED) {
		return <AsyncContactsAppLayout />;
	}

	return (
		<UFOSegment name="servicedesk-customers-v2">
			<MarkProductStart />
			<SpaPageWrapper
				pageId={pageId}
				baseUrl={baseUrl}
				currentUrl={match.url}
				// @ts-expect-error - TS2322 - Type '{ projectId: number; projectKey: string; projectName: string; projectType: ProjectType; isAdmin: boolean; isProjectSimplified: boolean; } | undefined' is not assignable to type 'ProjectContext | null | undefined'.
				projectContext={projectContext}
				isLoading={!pageContextData && !error}
				error={error}
			>
				{/* @ts-expect-error - TS2322 - Type '{ projectName: string | undefined; section: string; }' is not assignable to type 'IntrinsicAttributes & Diff<(Diff<RefAttributes<any> | (RefAttributes<any> & { children?: ReactNode; }), Partial<RefAttributes<...> | (RefAttributes<...> & { ...; })>> & RefAttributes<...>) | (Diff<...> & ... 1 more ... & { ...; }), InjectIntlVoidProps> & { ...; }'. */}
				<ServiceDeskDocumentTitle projectName={projectName} section="customers" />
				<AppBase id={pageId}>
					<ServiceDeskAnalyticsContext>
						<ServiceDeskErrorBoundary
							appName={APP_NAMES.CUSTOMERS}
							ErrorView={ErrorViewWithAnalytics}
						>
							<PageStyle>
								<Grid layout="fluid" theme={CustomersTheme}>
									<GridColumn>
										<CustomersSpaApp />
									</GridColumn>
								</Grid>
							</PageStyle>
						</ServiceDeskErrorBoundary>
					</ServiceDeskAnalyticsContext>
				</AppBase>
			</SpaPageWrapper>
			<JsdGettingStartedPanelShouldRender />
		</UFOSegment>
	);
};
