/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import Button, { type ButtonProps } from '@atlaskit/button/standard-button';
import { Field as AkField } from '@atlaskit/form';
import AddIcon from '@atlaskit/icon/glyph/add';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import InlineDialog from '@atlaskit/inline-dialog';
import { Box, xcss, Text } from '@atlaskit/primitives';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { AsyncInviteFailed } from '@atlassian/jira-servicedesk-customers-common-invite-failed/src/async.tsx';
import { useAddCustomersDialogContext as useAddCustomersDialogContextDI } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/add-customers-dialog-context/index.tsx';
import { useDynamicInputHook } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/dynamic-input-hook/index.tsx';
import { MultiLineText } from '../../../../../common/ui/multiline-text';
import { InputListView } from './input-lists';
import messages from './messages';
import type { DynamicInputProps } from './types';
import { transformToInviteFailedEmails } from './utils';

export const DynamicInputFieldView = ({ intl: { formatMessage } }: DynamicInputProps) => {
	const {
		email,
		emails,
		showInput,
		showHelp,
		setShowHelp,
		isValidating,
		failed,
		failedWithErrors,
		showErrorFlag,

		// Methods
		setEmailOnChange,
		handleOnRemoved,
		handleAddMore,
		handleOnKeyDown,
		setShowErrorFlag,
	} = useDynamicInputHook();

	const helpMeContent = (
		<Box xcss={helpMeWrapperStyles}>
			<Text as="p">{formatMessage(messages.info)}</Text>
		</Box>
	);

	const renderHelpMe = () => (
		<InlineDialog
			onClose={() => {
				setShowHelp(false);
			}}
			content={helpMeContent}
			isOpen={showHelp}
			placement="top-start"
		>
			<Box as="span" xcss={helpMeButtonStyles}>
				<Button
					aria-label="Help"
					appearance="subtle-link"
					iconBefore={<EditorPanelIcon label="info" size="medium" />}
					spacing="none"
					onMouseOver={() => setShowHelp(!showHelp)}
					onMouseLeave={() => setShowHelp(!showHelp)}
				/>
			</Box>
		</InlineDialog>
	);

	const addMoreButtonProps: ButtonProps = {
		'aria-label': 'Add More',
		appearance: 'subtle-link',
		iconBefore: <AddIcon label="add" size="small" />,
		spacing: 'none',
		isDisabled: isValidating,
		onClick: handleAddMore,
		testId:
			'servicedesk-customers-organization-common.ui.add-customers-dialog.form.fields.dynamic-input-field.add-more-button',
	};

	return (
		<>
			<AkField
				name="customer-emails-field"
				label={formatMessage(messages.customerEmails)}
				isRequired
			>
				{() => (
					<>
						{renderHelpMe()}
						<InputListView inputs={emails} onRemoved={handleOnRemoved} />
						<MultiLineText
							isInvalid={failed.length > 0}
							isMultiline={!showInput}
							isValidating={isValidating}
							value={email}
							onKeyDown={handleOnKeyDown}
							setEmailOnChange={setEmailOnChange}
						/>
					</>
				)}
			</AkField>
			{showErrorFlag && (
				<AsyncInviteFailed
					failedEmails={transformToInviteFailedEmails(failedWithErrors)}
					onClosed={() => setShowErrorFlag(false)}
				/>
			)}
			<Box xcss={addMoreStyles}>
				{isValidating ? (
					<Button
						{...addMoreButtonProps}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						css={css({
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
							cursor: 'progress !important',
						})}
					>
						&nbsp;Add
					</Button>
				) : (
					<Button {...addMoreButtonProps}>&nbsp;Add</Button>
				)}
			</Box>
		</>
	);
};

DynamicInputFieldView.defaultProps = {
	useAddCustomersDialogContext: useAddCustomersDialogContextDI,
};

export const DynamicInputField = injectIntl(DynamicInputFieldView);

const addMoreStyles = xcss({
	display: 'flex',
	justifyContent: 'right',
	marginTop: 'space.050',
	marginBottom: 'space.050',
});

const helpMeWrapperStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 24}px`,
});

const helpMeButtonStyles = xcss({
	verticalAlign: 'middle',
});
