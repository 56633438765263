import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addCustomers: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.add-customers',
		defaultMessage: 'Add customers',
		description: 'button for adding customers',
	},
	addedCustomersNew: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.added-customers-new',
		defaultMessage:
			'{count, plural, one {One customer has} other {{count} customers have}} been added',
		description: 'description of success flag message for adding customers',
	},
	addedCustomersNewMultipleHelpCenters: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.added-customers-new-multiple-help-centers',
		defaultMessage: '{count, plural, one {Customer} other {Customers}} added to project',
		description: 'description of success flag message for adding customers',
	},
	addedCustomersDescriptionNew: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.added-customers-description-new',
		defaultMessage:
			'They’ll receive an invitation email if they haven’t already been added to a project on this site.',
		description: "description of success flag stating they'll receive an email.",
	},
	addedCustomersWhenEmailDisabled: {
		id: 'servicedesk-customers-organization-common.add-customers-dialog.added-customers-when-email-disabled',
		defaultMessage: 'Their details will be visible in the table soon.',
		description:
			'Description of success flag when email notification are disabled from customer settings',
	},
});
