import {
	CustomerTypes,
	type ValidatedCustomers,
	type EmailValidationErrorType,
	type ValidCustomerEntity,
	type InvalidCustomerEntity,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/customers/types.tsx';
import type { BaseUrl, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ValidateCustomerEmailsResponse } from './types';

export const getUrl = (baseUrl: BaseUrl, projectKey: ProjectKey) =>
	`${baseUrl}/rest/servicedesk/1/pages/people/customers/pagination/${projectKey}/invite/validate`;

export const transformData = ({
	failed: failedCustomerEmails = [],
	success: successCustomerEmails = [],
}: ValidateCustomerEmailsResponse): ValidatedCustomers => [
	...failedCustomerEmails.map((failedCustomerEmail) => ({
		...failedCustomerEmail,
		type: CustomerTypes.invalidCustomer,
	})),
	...successCustomerEmails.map((successCustomerEmail) => ({
		...successCustomerEmail,
		type: CustomerTypes.validCustomer,
	})),
];

export const transformForView = (
	props: ValidateCustomerEmailsResponse,
): EmailValidationErrorType => {
	const { failed, success } = props;

	return {
		failed: failed.map((f: InvalidCustomerEntity) => f && f.emailAddress),
		passed: success.map((f: ValidCustomerEntity) => f && f.emailAddress),
		added: [],
		sent: [],
		failedWithErrors: failed.map(
			(f: InvalidCustomerEntity) => f && { email: f.emailAddress, error: f.error },
		),
	};
};

export const transformError = (statusCode: number) => {
	switch (statusCode) {
		case 401:
			return {
				reasonKey: 'Unauthorised.',
				reasonCode: '401',
				errors: [],
			};

		case 500:
			return {
				reasonKey: 'Internal server error.',
				reasonCode: '500',
				errors: [],
			};

		default:
			return null;
	}
};
