import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeHolder: {
		// eslint-disable-next-line jira/i18n/id-named-by-package
		id: 'servicedesk-customers-organization-common.add-customers-dialog.form.fields.dynamic-input-field.place-holder',
		defaultMessage: 'Enter email address(es)',
		description: 'description of label for customer emails',
	},
});
