/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	resendInviteFailureFlagDescription: {
		id: 'servicedesk-customers-organization-common.table.resend-invite-cell.error-flag.resend-invite-failure-flag-description',
		defaultMessage: 'Check your network connection, refresh the page and try again.',
		description:
			'Error flag message for when an agent tries to resend an invite email to a customer',
	},
	resendInviteFailureFlagTitle: {
		id: 'servicedesk-customers-organization-common.table.resend-invite-cell.error-flag.resend-invite-failure-flag-title',
		defaultMessage: 'We couldn’t resend the invite',
		description: 'Error flag title for when an agent tries to resend an invite email to a customer',
	},
	refreshThePage: {
		id: 'servicedesk-customers-organization-common.table.resend-invite-cell.error-flag.refresh-the-page',
		defaultMessage: 'Refresh the page',
		description:
			'Button label so user refreshes the page after an error inviting a customer to a project',
	},
});
