import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ContactsAppLayout as ServicedeskContactsType } from '@atlassian/jira-spa-apps-servicedesk-contacts/src/ui/index.tsx';

export const LazyContactsAppLayout = lazyForPaint<typeof ServicedeskContactsType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-contacts", jiraSpaEntry: "async-servicedesk-contacts" */ '@atlassian/jira-spa-apps-servicedesk-contacts'
		),
);

export const AsyncContactsAppLayout = () => (
	<Placeholder name="servicedesk-contacts" fallback={null}>
		<LazyContactsAppLayout />
	</Placeholder>
);
