import React, { useMemo } from 'react';
import { styled as styled2 } from '@compiled/react';
// @ts-expect-error - TS2614 - Module '"@atlaskit/dynamic-table"' has no exported member 'RowType'. Did you mean to use 'import RowType from "@atlaskit/dynamic-table"' instead?
import type { RowType } from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import { EditorCell } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/editable-table/editor-cell/index.tsx';
import type { Props } from './types';

export const useRows = ({ organizations, onRemove }: Props) => {
	const rows = useMemo(() => {
		if (!organizations) return [];
		return organizations.map<RowType>((organization, orgIndex) => {
			// @ts-expect-error - TS2304 - Cannot find name 'RowCellType'.
			const cells: Array<RowCellType> = [];
			const { name, status } = organization;

			cells.push({
				key: `display-org-${orgIndex}`,
				content: (
					<Inline>
						<Label>{name}</Label>
						{status && (
							<Lozenge maxWidth={90} isBold>
								{status}
							</Lozenge>
						)}
					</Inline>
				),
				colSpan: 80,
			});

			cells.push({
				key: `remove-org-${orgIndex}`,
				content: (
					<EditorCell
						name={name}
						onRemove={() => {
							onRemove(organizations.filter((_, idx) => idx !== orgIndex));
						}}
					/>
				),
				colSpan: 20,
			});

			return {
				key: `organization-name-${orgIndex}`,
				cells,
			};
		});
	}, [onRemove, organizations]);

	return rows;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled2.span({
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Inline = styled2.div({
	display: 'flex',
	alignItems: 'center',
	paddingLeft: token('space.075', '6px'),
});
