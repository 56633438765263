import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalTransition,
	ModalBody,
	ModalTitle,
	ModalHeader,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { useAddCustomersDialogContext as useAddCustomersDialogContextDI } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/add-customers-dialog-context/index.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

type Props = {
	isOpen: boolean;
	useAddCustomersDialogContext: typeof useAddCustomersDialogContextDI;
} & Intl & {
		onConfirm: () => void;
		onClose: () => void;
	};

export const ConfirmationModalView = ({
	intl: { formatMessage },
	useAddCustomersDialogContext,
	isOpen,
	onConfirm,
	onClose,
}: Props) => {
	const [{ organization, defaultOrganization }] = useAddCustomersDialogContext();
	const selectedOrganization = organization || defaultOrganization;
	const onConfirmAndClose = () => {
		onConfirm();
		onClose();
	};

	const actions = [
		{
			text: formatMessage(messages.addCustomers),
			onClick: onConfirmAndClose,
			'data-test-id':
				'servicedesk-customers-organization-common.ui.add-customers-dialog.form.confirmation-modal.confirm-add-customers-to-multiple-projects',
		},
		{
			text: formatMessage(messages.cancel),
			onClick: onClose,
			'data-test-id':
				'servicedesk-customers-organization-common.ui.add-customers-dialog.form.confirmation-modal.cancel-add-customers-to-multiple-projects',
		},
	];
	return (
		<ModalTransition>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog onClose={onClose}>
						<ModalHeader>
							<ModalTitle>{formatMessage(messages.confirmationTitle)}</ModalTitle>
						</ModalHeader>

						<ModalBody>
							<ModalContentWrapper>
								{formatMessage(messages.multipleProjectOrganization, selectedOrganization)}
							</ModalContentWrapper>
						</ModalBody>
						<ModalFooter>
							{actions
								.map((props, index) => (
									<Button
										key={index}
										{...props}
										autoFocus={index === 0}
										appearance={index === 0 ? 'primary' : 'subtle'}
									>
										{props.text}
									</Button>
								))
								.reverse()}
						</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransition>
	);
};

ConfirmationModalView.defaultProps = {
	useAddCustomersDialogContext: useAddCustomersDialogContextDI,
};

export const ConfirmationModal = injectIntl(ConfirmationModalView);

// eslint-disable-next-line @atlaskit/design-system/no-empty-styled-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalContentWrapper = styled.p({});
