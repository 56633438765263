import React, { type ReactElement, useCallback, useEffect, useState } from 'react';
import { type FlagId, withFlagService } from '@atlassian/jira-flags';
import { useCommonPageContext } from '@atlassian/jira-servicedesk-common-page-context';
import { usePopupController } from '@atlassian/jira-servicedesk-common/src/use-popup-controller';
import type { Customer } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import {
	normalizeToOrgProfile,
	normalizeToUserProfile,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/utils.tsx';
import { customersSearchPageLoadExperience } from '@atlassian/jira-servicedesk-customers-organization-common/src/experiences.tsx';
import { RemoveOrganizationDialog } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/remove-organization-dialog/index.tsx';
import { Table } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/table';
import { useRemoveCustomerService } from '@atlassian/jira-servicedesk-customers/src/services/remove-customer/index.tsx';
import messages from './messages';
import type {
	TableWithActionsProps,
	ToBeRemovedOrganizationInfo,
	ToBeRemovedUserInfo,
} from './types';

const resendVerificationEmail = 'resendVerificationEmail';

const TableWithActionsView = ({
	flagService: { showFlag },
	setPageData,
	onPageChange,
	loading,
	pageData,
	isEditable,
	hasActions = true,
}: TableWithActionsProps) => {
	const { baseUrl, projectKey, projectName } = useCommonPageContext();
	const {
		loading: removeCustomerLoading,
		data: removeCustomerData,
		fetch: removeCustomer,
		error: removeCustomerError,
		// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
	} = useRemoveCustomerService(baseUrl, projectKey);

	const [
		isRemoveOrganizationDialogOpen,
		toggleRemoveOrganizationDialog,
		closeRemoveOrganizationDialog,
	] = usePopupController();
	const [toBeRemovedOrganizationInfo, setToBeRemovedOrganizationInfo] =
		useState<ToBeRemovedOrganizationInfo>({
			id: null,
			displayName: '',
		});
	const [toBeRemovedUserInfo, setToBeRemovedUserInfo] = useState<ToBeRemovedUserInfo>({
		identifier: '',
		displayName: '',
	});

	const onRemoveClick = useCallback(
		(customer: Customer) => {
			const orgProfile = normalizeToOrgProfile(customer);

			if (orgProfile) {
				const { id, displayName } = orgProfile;
				setToBeRemovedOrganizationInfo({ id, displayName });
				toggleRemoveOrganizationDialog();
				return;
			}

			const userProfile = normalizeToUserProfile(customer);
			if (userProfile) {
				const { identifier, displayName } = userProfile;
				setToBeRemovedUserInfo({ displayName, identifier });
				removeCustomer(identifier);
			}
		},
		[removeCustomer, toggleRemoveOrganizationDialog],
	);

	const onRemoveOrganizationSuccess = useCallback(
		// @ts-expect-error - TS7031 - Binding element 'orgName' implicitly has an 'any' type.
		({ orgName }) => {
			showFlag({
				type: 'success',
				title: [messages.flagTitle, { customerName: orgName, projectName }],
			});
			closeRemoveOrganizationDialog();
			setPageData();
		},
		[closeRemoveOrganizationDialog, projectName, setPageData, showFlag],
	);

	const onActionSuccess = useCallback(
		(email: string, successAction: string) => {
			if (successAction === resendVerificationEmail) {
				showFlag({
					type: 'success',
					title: messages.resendVerificationEmailTitle,
					description: [messages.resendVerificationEmailDescription, { email }],
				});
			} else {
				showFlag({
					type: 'success',
					title: messages.resendInviteSuccessFlagTitle,
					description: [messages.resendInviteSuccessFlagDescription, { email }],
				});
			}
		},
		[showFlag],
	);

	const onActionFailure = useCallback(
		(id: FlagId, errorFlagComponent: ReactElement) => {
			showFlag({
				id,
				render: () => errorFlagComponent,
			});
		},
		[showFlag],
	);

	useEffect(() => {
		const { identifier, displayName } = toBeRemovedUserInfo;
		if (Boolean(removeCustomerData) && removeCustomerData === identifier) {
			showFlag({
				type: 'success',
				title: [messages.flagTitle, { customerName: displayName, projectName }],
			});
			setPageData();
		}
		if (removeCustomerError) {
			showFlag({
				type: 'error',
				title: messages.removeCustomerError,
			});
		}
	}, [
		projectName,
		removeCustomerData,
		removeCustomerError,
		setPageData,
		showFlag,
		toBeRemovedUserInfo,
	]);

	useEffect(() => {
		customersSearchPageLoadExperience.success();
	}, [pageData]);

	return (
		<>
			<RemoveOrganizationDialog
				isOpen={isRemoveOrganizationDialogOpen}
				onClose={closeRemoveOrganizationDialog}
				orgName={toBeRemovedOrganizationInfo.displayName}
				orgId={Number(toBeRemovedOrganizationInfo.id)}
				onSuccess={onRemoveOrganizationSuccess}
			/>
			<Table
				loading={loading || removeCustomerLoading}
				// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
				projectKey={projectKey}
				// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
				projectName={projectName}
				pageData={pageData}
				onPageChange={onPageChange}
				isEditable={isEditable}
				onRemove={onRemoveClick}
				onActionSuccess={onActionSuccess}
				onActionFailure={onActionFailure}
				hasActions={hasActions}
			/>
		</>
	);
};

export const TableWithActions = withFlagService(TableWithActionsView);
