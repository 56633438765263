import React from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { Box, Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages';
import { CustomerNotificationHelpTextForAdminLink, ViewConfigurationLink } from './utils';

export type Props = {
	isOutgoingMailEnabled: boolean;
	isInviteEmailEnabled: boolean;
	isAdmin: boolean;
	intl: IntlShape;
	isMultiHcAndInviteRoutingEnabled?: boolean;
	isCustomerInvitationSupported?: boolean;
};

export const CustomerNotificationHelpTextView = ({
	intl: { formatMessage },
	isOutgoingMailEnabled,
	isInviteEmailEnabled,
	isAdmin,
	isMultiHcAndInviteRoutingEnabled = false,
	isCustomerInvitationSupported = true,
}: Props) => {
	const inviteEmailDescription = isMultiHcAndInviteRoutingEnabled
		? formatMessage(messages.updatedCustomersWillReceiveHelpCenterEmail)
		: formatMessage(messages.updatedCustomersWillReceiveEmail);

	// For cases where sending email invites are not supported (can't be configured)
	// Note that customers are still added but no email is sent
	// TODO: Add copy when provided by design/content
	if (!isCustomerInvitationSupported)
		return (
			<Box data-testId="servicedesk-customers-organization-common.ui.add-customers-dialog.form.fields.customer-notification-help-text.box" />
		);

	if (isInviteEmailEnabled && isOutgoingMailEnabled && isAdmin) {
		return (
			<p>
				{inviteEmailDescription} <CustomerNotificationHelpTextForAdminLink />
			</p>
		);
	}

	if (isInviteEmailEnabled && isOutgoingMailEnabled) {
		return (
			<Box paddingBlockStart="space.150">
				<Text as="p">
					{inviteEmailDescription} {formatMessage(messages.notificationsForNonAdmin)}
				</Text>
			</Box>
		);
	}

	if (!isInviteEmailEnabled && isAdmin)
		return (
			<>
				<LabelContainer>
					<InfoIcon label="" primaryColor={token('color.icon.discovery', colors.P300)} />
					<Label>
						{formatMessage(messages.invitationNotificationDisabled)}
						<ViewConfigurationLink />
					</Label>
				</LabelContainer>
			</>
		);

	if (!isInviteEmailEnabled) {
		return (
			<LabelContainer>
				<InfoIcon label="" primaryColor={token('color.icon.discovery', colors.P300)} />
				<Label>{formatMessage(messages.viewConfigurationForNonAdmin)}</Label>
			</LabelContainer>
		);
	}

	return null;
};

CustomerNotificationHelpTextView.defaultProps = {
	isOutgoingMailEnabled: false,
	isInviteEmailEnabled: false,
	isAdmin: false,
};

export const CustomerNotificationHelpText = injectIntl(CustomerNotificationHelpTextView);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelContainer = styled.p({
	display: 'flex',
	alignItems: 'start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.span({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'start',
	marginLeft: token('space.100', '8px'),
});
