/**
 * @generated SignedSource<<600d7442f15f4a40d29f41a1084dacd6>>
 * @relayHash 4e079704002ecfaadcb864b248ac074d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9dd44a542e3ffa79060b2c2cde60374e5e945816ebbb26ec6f1f03c228492e44

import type { ConcreteRequest, Query } from 'relay-runtime';
export type HelpCenterSortOrder = "CREATED_DATE_ASCENDING" | "CREATED_DATE_DESCENDING" | "%future added value";
export type helpCenterFetchQuery$variables = {
  SORT_ORDER: HelpCenterSortOrder;
  after?: string | null | undefined;
  cloudId: string;
  first: number;
  workspaceAri: string;
};
export type helpCenterFetchQuery$data = {
  readonly helpCenter: {
    readonly helpCenters: {
      readonly __typename: "HelpCenterQueryResultConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id?: string;
          readonly name?: {
            readonly default: string;
          } | null | undefined;
          readonly slug?: string | null | undefined;
        } | null | undefined;
      }> | null | undefined;
      readonly nodes: ReadonlyArray<{
        readonly __typename: "QueryError";
        readonly extensions: ReadonlyArray<{
          readonly errorType: string | null | undefined;
          readonly statusCode: number | null | undefined;
        }> | null | undefined;
        readonly identifier: string | null | undefined;
        readonly message: string | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      }> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
      };
    } | null | undefined;
  } | null | undefined;
};
export type helpCenterFetchQuery = {
  response: helpCenterFetchQuery$data;
  variables: helpCenterFetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SORT_ORDER"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceAri"
},
v5 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v6 = {
  "kind": "Variable",
  "name": "sortOrder",
  "variableName": "SORT_ORDER"
},
v7 = {
  "kind": "Variable",
  "name": "workspaceAri",
  "variableName": "workspaceAri"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v9/*: any*/),
    {
      "concreteType": "HelpCenterName",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "default"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "slug"
    }
  ],
  "type": "HelpCenter"
},
v11 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v12 = {
  "kind": "ScalarField",
  "name": "message"
},
v13 = {
  "kind": "ScalarField",
  "name": "identifier"
},
v14 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v15 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v16 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v17 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v6/*: any*/),
  (v7/*: any*/)
],
v18 = {
  "kind": "InlineFragment",
  "selections": [
    (v9/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "helpCenterFetchQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": "helpCenters",
            "args": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "concreteType": "HelpCenterQueryResultConnection",
            "kind": "LinkedField",
            "name": "__multiHcDropdownContentFragment_helpCenters_connection",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "concreteType": "HelpCenterQueryResultEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v8/*: any*/)
                    ]
                  },
                  (v11/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v14/*: any*/),
                          (v15/*: any*/)
                        ]
                      }
                    ],
                    "type": "QueryError"
                  }
                ]
              },
              (v16/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "helpCenterFetchQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v17/*: any*/),
            "concreteType": "HelpCenterQueryResultConnection",
            "kind": "LinkedField",
            "name": "helpCenters",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "concreteType": "HelpCenterQueryResultEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v10/*: any*/),
                      (v18/*: any*/)
                    ]
                  },
                  (v11/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/)
                        ]
                      }
                    ],
                    "type": "QueryError"
                  },
                  (v18/*: any*/)
                ]
              },
              (v16/*: any*/)
            ]
          },
          {
            "args": (v17/*: any*/),
            "filters": [
              "workspaceAri",
              "sortOrder"
            ],
            "handle": "connection",
            "key": "multiHcDropdownContentFragment_helpCenters",
            "kind": "LinkedHandle",
            "name": "helpCenters"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9dd44a542e3ffa79060b2c2cde60374e5e945816ebbb26ec6f1f03c228492e44",
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "helpCenter",
            "helpCenters"
          ]
        }
      ]
    },
    "name": "helpCenterFetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0efd5ddafccbf9644af93c0d9a8ea221";

export default node;
