import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Field as AkField, ErrorMessage } from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import Select from '@atlaskit/select';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { PREFETCH_OPTIONS } from '../common/types/constants';
import type {
	HelpCenterDropDownOption,
	HelpCenterData,
} from '../common/types/help-centers/types.tsx';
import { HelpCenterOptionCustomLabel } from '../common/ui/help-center-option-custom-label/index.tsx';
import { HelpMe } from '../common/ui/help-me/index.tsx';
import { useHelpCenterFetchService } from '../services/help-center-fetch/index.tsx';
import messages from './messages';
import type { HelpCenterSelectViewProps } from './types';

const HelpCenterSelectView = ({
	selectedHelpCenter,
	setSelectedHelpCenter,
}: HelpCenterSelectViewProps) => {
	const { fetch, isLoading, error, hasNext } = useHelpCenterFetchService();
	const [options, setOptions] = useState<HelpCenterDropDownOption[]>([]);
	const { formatMessage } = useIntl();
	const { ref, inView } = useInView({
		threshold: 0,
	});

	const loadOptions = useCallback(
		async (initial = false) => {
			const helpCenters = await fetch();
			if (helpCenters?.length > 0 && !error) {
				const helpCenterOptions: Array<HelpCenterDropDownOption> = helpCenters.map(
					(hc: HelpCenterData, hcIndex: number) => {
						const renderWithref = hcIndex === helpCenters.length - PREFETCH_OPTIONS;
						const hcNameWithDefaultLozenge = hc.slug ? (
							hc.name
						) : (
							<HelpCenterOptionCustomLabel value={hc.name} />
						);
						const hcLabel = renderWithref ? (
							<span ref={ref}>{hc.name}</span>
						) : (
							hcNameWithDefaultLozenge
						);
						const option: HelpCenterDropDownOption = {
							label: hcLabel,
							value: hc.helpCenterAri,
							stringLabel: hc.name,
							slug: hc.slug,
						};
						if (!hc.slug && !selectedHelpCenter && initial) {
							setSelectedHelpCenter(option);
						}
						return option;
					},
				);
				if (helpCenterOptions.length > 0) {
					setOptions(helpCenterOptions);
				}
			}
		},
		[error, fetch, selectedHelpCenter, ref, setSelectedHelpCenter],
	);

	useEffect(() => {
		// Load more options only when there is not an error and there are more options to load
		if (hasNext && inView && !isLoading && !error) {
			loadOptions(false);
		}
	}, [hasNext, inView, loadOptions, isLoading, error]);

	useEffect(() => {
		loadOptions(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSelect = (selected: HelpCenterDropDownOption | null) => {
		// When someone clears the field
		if (selected === null) {
			setSelectedHelpCenter(undefined);
		} else {
			setSelectedHelpCenter(selected);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const menuPortal = (base: any) => ({ ...base, zIndex: layers.modal });
	return (
		<Box xcss={helpCenterSelectWrapper}>
			<AkField
				name="add-to-hc-field"
				label={formatMessage(messages.addToHelpCenterUpdated)}
				isRequired
			>
				{() => (
					<>
						<HelpMe helperText={formatMessage(messages.info)} />
						<Select
							cacheOptions
							options={options}
							value={selectedHelpCenter}
							onChange={onSelect}
							openMenuOnClick
							isClearable
							isLoading={isLoading}
							aria-label={formatMessage(messages.addToHelpCenterUpdated)}
							placeholder={formatMessage(messages.placeHolder)}
							menuPortalTarget={document.body}
							isSearchable={false}
							// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
							styles={{ menuPortal }}
						/>
						{!isLoading && error && (
							<>
								<ErrorMessage>{formatMessage(messages.somethingWentWrong)}</ErrorMessage>
							</>
						)}
					</>
				)}
			</AkField>
		</Box>
	);
};

const helpCenterSelectWrapper = xcss({
	marginTop: 'space.100',
	marginBottom: 'space.200',
});

export const HelpCenterSelectField = HelpCenterSelectView;
