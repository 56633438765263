import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	add: {
		id: 'servicedesk-customers-organization-common.common.inline-dialog.footer.add',
		defaultMessage: 'Add',
		description: 'Button to add',
	},
	cancel: {
		id: 'servicedesk-customers-organization-common.common.inline-dialog.footer.cancel',
		defaultMessage: 'Cancel',
		description: 'Button to cancel',
	},
});
