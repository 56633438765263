import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	resendVerificationEmailGenericFailureFlagDescription: {
		id: 'servicedesk-customers-organization-common.common.resend-verification-email-error-flag.resend-verification-email-generic-failure-flag-description',
		defaultMessage: 'An error occurred while sending the verification email. Try again later.',
		description:
			'Error flag message for when an agent tries to resend verification email to a customer',
	},
	resendVerificationEmailGenericFailureFlagTitle: {
		id: 'servicedesk-customers-organization-common.common.resend-verification-email-error-flag.resend-verification-email-generic-failure-flag-title',
		defaultMessage: 'We couldn’t send verification email',
		description:
			'Error flag title for when an agent tries to resend verification email to a customer',
	},
	resendVerificationEmailRateLimitFailureFlagDescription: {
		id: 'servicedesk-customers-organization-common.common.resend-verification-email-error-flag.resend-verification-email-rate-limit-failure-flag-description',
		defaultMessage:
			'An error occurred while sending the verification email. Resend it after 24 hours.',
		description:
			'Rate limit error flag message for when an agent tries to resend verification email to a customer',
	},
	resendVerificationEmailRateLimitFailureFlagTitle: {
		id: 'servicedesk-customers-organization-common.common.resend-verification-email-error-flag.resend-verification-email-rate-limit-failure-flag-title',
		defaultMessage: 'Verification email couldn’t be sent',
		description:
			'Rate limit error flag title for when an agent tries to resend verification email to a customer',
	},
});
