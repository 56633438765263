import React, { useEffect } from 'react';
import noop from 'lodash/noop';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { FireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { LimitReached } from '@atlassian/jira-servicedesk-customers-organization-common/src/ui/table/limit-reached/main.tsx';
import { isOrganization } from '../../common/types/utils';
import BracketPagination from './bracket-pagination';
import { DEFAULT_PAGE_DATA } from './constants';
import { getHeader } from './header';
import { useTableRows } from './main';
import { EmptyState } from './pagination';
import { PaginationLabel } from './pagination-count-label';
import type { TableProps } from './types';
import { type PaginationHookType, usePaginationHook } from './utils';

export const TableView = (props: TableProps) => {
	const { loading, intl, pageData, onPageChange, hasActions = true } = props;

	const { page, total, customers, totalHasBeenDetermined, lastDeterminedPaged } =
		pageData || DEFAULT_PAGE_DATA;

	const hasOrganisationRow = customers?.some(isOrganization);
	const head = props.head
		? props.head
		: getHeader(intl, props.isEditable || hasOrganisationRow, hasActions);

	const { rows, tablePage, setTablePage } = useTableRows(props);

	const { query } = pageData || { query: '' };
	const paginationProps: PaginationHookType = {
		query,
		totalHasBeenDetermined,
		total,
		pageNumber: page,
		lastDeterminedPaged,
	};

	const {
		isMaxLimitReached,
		paginationLabel,
		showPagination,
		totalPages,
		bracketSize,
		hideRightEllipsis,
	} = usePaginationHook(paginationProps);

	useEffect(() => {
		if (query !== '') {
			setTablePage(1);
		}
	}, [query, setTablePage]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const checkIfNeedsQuery = (pageNum: any): void => {
		const next = pageNum + 1;
		onPageChange(next);
		setTablePage(next);
	};

	return (
		<Box xcss={overflowWrapperStyles}>
			<Box
				xcss={tableContainerStyles}
				data-testId="servicedesk-customers-organization-common.ui.dynamic-table.table-container"
			>
				<DynamicTableStateless
					head={head}
					rows={rows}
					isLoading={loading}
					isFixedSize
					emptyView={<EmptyState />}
				/>
				{rows.length > 0 && (
					<>
						{!loading && isMaxLimitReached && <LimitReached />}
						<Box xcss={paginationContainerStyles} aria-disabled="true">
							{!loading && (
								<PaginationLabel label={paginationLabel} justifyCenter={showPagination} />
							)}
							{showPagination && (
								<BracketPagination
									isDisabled={loading}
									pages={totalPages}
									selectedIndex={tablePage - 1}
									onChange={(newIndex: number) => checkIfNeedsQuery(newIndex)}
									bracketSize={bracketSize}
									hideRightEllipsis={hideRightEllipsis}
								/>
							)}
						</Box>
					</>
				)}
				{!loading && <FireOperationalAnalytics eventName="customersOrgsTable loaded" />}
			</Box>
		</Box>
	);
};

TableView.defaultProps = {
	loading: false,
	onPageChange: noop,
	isEditable: false,
	onRemove: noop,
};

export const Table = injectIntl(TableView);

const tableContainerStyles = xcss({
	cursor: 'pointer',
	marginTop: 'space.0',
	// @ts-expect-error - TS2322 - Object literal may only specify known properties
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	thead: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderBottom: `2px solid ${token('color.border', colors.N40)}`,
		position: 'sticky',
		top: 0,
		zIndex: 1,
		background: `${token('elevation.surface', 'white')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	tbody: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderBottom: `2px solid ${token('color.border', colors.N40)}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	tfoot: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderBottom: `2px solid ${token('color.border', colors.N40)}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	table: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		borderBottom: 'none !important',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	div: {
		marginBottom: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	td: {
		wordBreak: 'break-word',
		padding: `0 ${token('space.025', '2px')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	th: {
		wordBreak: 'break-word',
		padding: `${token('space.100', '8px')} 0`,
	},
});

const overflowWrapperStyles = xcss({
	overflow: 'auto',
	maxHeight: '100%',
	marginTop: 'space.250',
});

const paginationContainerStyles = xcss({
	transition: `
        left 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
        right 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
        width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    `,
	borderTop: '2px solid',
	zIndex: 'navigation',
	overflow: 'visible',
	borderTopColor: 'color.border',
	padding: 'space.200',
	boxSizing: 'border-box',
	background: 'color.elevation.surface',
	display: 'grid',
});
