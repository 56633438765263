import type {
	PageData,
	Customer,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/types.tsx';
import type { OptimisticCustomerListUpdateResults } from '@atlassian/jira-servicedesk-customers-organization-common/src/services/invite-customers/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../types';

export const updateOptimisticCustomers =
	(optimisticCustomerList: OptimisticCustomerListUpdateResults = []): Action<State> =>
	async ({ getState, setState }) => {
		const { loading, pageData, ...rest } = getState();

		if (!pageData) {
			return;
		}

		setState({
			...rest,
			pageData,
			loading: true,
			error: null,
		});

		const { customers, total, rowsPerPage } = pageData;

		// The backend doesn't care if the user tries to add a customer that already
		// exists - it just returns the existing data as if it was just created.
		// This means that we can show the same customer twice, once from `optimisticCustomerList`
		// and the other from the existing entry in `customers`. Use a Map to deduplicate
		// the resulting list while maintaining insertion order
		const customerMap = new Map<string, Customer>();
		optimisticCustomerList.forEach((c) => customerMap.set(c.identifier, c));
		customers.forEach((c) => customerMap.set(c.identifier, c));
		const deduplicatedCustomersArray = Array.from(customerMap.values());

		const prependUsers = deduplicatedCustomersArray.slice(0, rowsPerPage);

		const updatedPageData: PageData = {
			...pageData,
			customers: prependUsers,
			total: total + prependUsers.length - customers.length,
		};

		setState({
			...getState(),
			pageData: updatedPageData,
			loading: false,
		});
	};
