import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

type CollapsibleSectionMessageProps = {
	isOpen: boolean;
} & Intl & {
		message: string;
		title: string;
		appearance: 'information' | 'warning' | 'error' | 'success' | 'discovery';
		onClose: (shouldHideMessage: boolean) => void;
	};

const CollapsibleSectionMessageView = ({
	intl: { formatMessage },
	message,
	appearance,
	isOpen,
	title,
	onClose,
}: CollapsibleSectionMessageProps) => (
	<>
		{isOpen && (
			<SectionMessageContainer>
				<CloseButtonContainer>
					<Button onClick={() => onClose(false)} appearance="subtle" spacing="none">
						<CrossIcon size="small" label={formatMessage(messages.closeModal)} />
					</Button>
				</CloseButtonContainer>
				<SectionMessage appearance={appearance} title={title}>
					{message}
				</SectionMessage>
			</SectionMessageContainer>
		)}
	</>
);

export const CollapsibleSectionMessage = injectIntl(CollapsibleSectionMessageView);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionMessageContainer = styled.div({
	position: 'relative',
	marginBottom: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CloseButtonContainer = styled.div({
	position: 'absolute',
	right: token('space.100', '8px'),
	top: token('space.100', '8px'),
	cursor: 'pointer',
});
