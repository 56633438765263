import React, { useState, useCallback } from 'react';
import Button from '@atlaskit/button/standard-button';
import InlineDialog from '@atlaskit/inline-dialog';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { usePopupController } from '@atlassian/jira-servicedesk-common/src/use-popup-controller';
import { isEventFromOrganizationSelectDropList } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/utils.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { AddOrganizationsForm } from './form';
import messages from './messages';

const AddOrganizationsInlineDialogTrigger = ({ intl: { formatMessage } }: Intl) => {
	const [organizations, setOrganizations] = useState<unknown>([]);
	const [isOpen, togglePopup, onCloseDialog] = usePopupController();

	const onReset = useCallback(() => {
		setOrganizations([]);
	}, []);

	const onResetAndCloseDialog = useCallback(() => {
		onReset();
		onCloseDialog();
	}, [onCloseDialog, onReset]);

	return (
		<InlineDialog
			content={
				<AddOrganizationsForm
					// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'Organizations'.
					organizations={organizations}
					setOrganizations={setOrganizations}
					onReset={onReset}
					onCloseDialog={onCloseDialog}
					onResetAndCloseDialog={onResetAndCloseDialog}
				/>
			}
			isOpen={isOpen}
			onClose={({ event }) => {
				// don't allow in-line dialog to close if close event is triggered from AkSelect.
				// @ts-expect-error - TS2345 - Argument of type 'Event' is not assignable to parameter of type 'MouseEvent'.
				if (isEventFromOrganizationSelectDropList(event)) {
					return;
				}
				togglePopup();
			}}
			placement="bottom-end"
		>
			<Button
				testId="servicedesk-customers.ui.header-actions.add-organizations-dialog.button"
				onClick={togglePopup}
			>
				{formatMessage(messages.addOrganizations)}
			</Button>
		</InlineDialog>
	);
};

export const AddOrganizationsInlineDialogButton = injectIntl(AddOrganizationsInlineDialogTrigger);
