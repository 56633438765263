import React from 'react';
import { Field as AkField } from '@atlaskit/form';
import { useIntl } from '@atlassian/jira-intl';
import { useCommonPageContext as useCommonPageContextDI } from '@atlassian/jira-servicedesk-common-page-context';
import { OrganizationsSelectField as OrganizationsSelect } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/main.tsx';
import { useAddCustomersDialogContext } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/add-customers-dialog-context/index.tsx';
import messages from './messages';

export type OrganizationsSelectProps = {
	useCommonPageContext: typeof useCommonPageContextDI;
};

export const OrganizationsSelectField = ({ useCommonPageContext }: OrganizationsSelectProps) => {
	const { formatMessage } = useIntl();
	const { baseUrl, projectId, projectKey } = useCommonPageContext();
	const [{ organization }, { setOrganization }] = useAddCustomersDialogContext();

	return (
		<AkField
			name="add-to-organizations-field"
			label={formatMessage(messages.addToOrganizationsUpdated)}
		>
			{({ fieldProps: { id } }) => (
				<OrganizationsSelect
					hidePlaceholder={false}
					inputId={id}
					/* @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string' */
					baseUrl={baseUrl}
					/* @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string' */
					projectId={projectId}
					/* @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string' */
					projectKey={projectKey}
					value={organization}
					// @ts-expect-error - TS2322 - Type '([selectedOption]: [any]) => void' is not assignable to type '(arg1: Organizations) => void'.
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onModify={([selectedOption]: [any]) => {
						setOrganization(selectedOption);
					}}
				/>
			)}
		</AkField>
	);
};

OrganizationsSelectField.defaultProps = {
	useCommonPageContext: useCommonPageContextDI,
};
