import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	activeStatusLabel: {
		id: 'servicedesk-customers-organization-common.table.logged-in-status-cell.active-status-label',
		defaultMessage: 'Active',
		description: 'Status that indicates that a user has signed up using the invite link',
	},
	inviteStatusLabel: {
		id: 'servicedesk-customers-organization-common.table.logged-in-status-cell.invite-status-label',
		defaultMessage: 'Invited',
		description:
			'Status that indicates that an invite has been sent to the user, but the user has not used it to sign up yet',
	},
	unverifiedEmail: {
		id: 'servicedesk-customers-organization-common.table.logged-in-status-cell.unverified-email',
		defaultMessage: 'Unverified email',
		description: 'Status that indicates that an email has been verified',
	},
	unverifiedEmailTooltip: {
		id: 'servicedesk-customers-organization-common.table.logged-in-status-cell.unverified-email-tooltip',
		defaultMessage: 'Customer needs to verify email address to receive notifications',
		description: 'Tooltip message to descript Unverified email status',
	},
});
