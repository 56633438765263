import React from 'react';
import {
	SCREEN,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useCommonPageContext as useCommonPageContextDI } from '@atlassian/jira-servicedesk-common-page-context';
import { AppStoreContainer } from '@atlassian/jira-servicedesk-customers/src/controllers/app-store/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { CustomersApp } from './ui';

type Props = {
	useCommonPageContext?: typeof useCommonPageContextDI;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ useCommonPageContext = useCommonPageContextDI }: Props) => {
	const { baseUrl, projectKey } = useCommonPageContext();
	const tenantContext = useTenantContext();

	return (
		<ContextualAnalyticsData
			sourceName="viewCustomers"
			sourceType={SCREEN}
			attributes={{
				viewCustomersScreenVersion: 2,
				edition: tenantContext.appEditions.serviceDesk,
			}}
		>
			<AppStoreContainer
				// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
				baseUrl={baseUrl}
				// @ts-expect-error(TS2322 baseUrl, projectId or projectKey can be undefined, if usePageContext() returns null. Please handle undefined value in your code) - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
				projectKey={projectKey}
				scope={`CUSTOMERS_SPA_APP_${projectKey}`}
			>
				<CustomersApp />
			</AppStoreContainer>
			<FireScreenAnalytics />
			<SpaStatePageReady />
		</ContextualAnalyticsData>
	);
};
