import React, { useCallback } from 'react';
import noop from 'lodash/noop';
import { ErrorMessage } from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { useOrganizationSearchService as useOrganizationSearchServiceDI } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/organization-search-service/main.tsx';
import { OrganizationsSingleSelect } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/organizations-single-select/main.tsx';
import { debounce } from '../utils';
import messages from './messages';
import type { OrganizationsSelectViewProps } from './types';
import { isSelectedOptionValid, mapOrganizationsToLabelOptions } from './utils';

export const OrganizationsSelectFieldView = ({
	intl: { formatMessage },
	baseUrl,
	projectKey,
	projectId,
	isMulti,
	useOrganizationSearchService,
	onModify,
	selectedOrganizations,
	...props
}: OrganizationsSelectViewProps) => {
	const { error, loading, fetch } = useOrganizationSearchService({
		baseUrl,
		projectKey,
		projectId,
	});
	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchWithDebounce = useCallback(
		debounce(async ({ query, resolve }) => {
			const organizations = await fetch(query);
			const formattedOptions = mapOrganizationsToLabelOptions(organizations, formatMessage);
			resolve(formattedOptions);
		}),
		[],
	);

	// @ts-expect-error - TS2304 - Cannot find name 'GetOptionsParams'.
	const handleOnInputChange = (args: GetOptionsParams) => {
		fetchWithDebounce(args);
	};

	// @ts-expect-error - TS7006 - Parameter 'selected' implicitly has an 'any' type.
	const onSelect = (selected) => {
		if (!isSelectedOptionValid(selectedOrganizations, selected)) {
			return undefined;
		}

		return onModify([...selectedOrganizations, selected]);
	};

	return (
		<Box xcss={spacedStyles}>
			<OrganizationsSingleSelect
				{...props}
				isMulti={isMulti}
				onFetchOptions={handleOnInputChange}
				onSelect={onSelect}
			/>
			{!loading && error && (
				<ErrorMessage>{formatMessage(messages.somethingWentWrong)}</ErrorMessage>
			)}
		</Box>
	);
};

OrganizationsSelectFieldView.defaultProps = {
	isMulti: false,
	useOrganizationSearchService: useOrganizationSearchServiceDI,
	selectedOrganizations: [],
	onModify: noop,
};

export const OrganizationsSelectField = injectIntl(OrganizationsSelectFieldView);

const spacedStyles = xcss({
	marginTop: 'space.050',
});
