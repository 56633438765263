import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import { useQueryLoader } from 'react-relay';
import Button from '@atlaskit/button/standard-button';
import InlineDialog from '@atlaskit/inline-dialog';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { JiraWorkspaceAri } from '@atlassian/ari/jira';
import { ff } from '@atlassian/jira-feature-flagging';
import FlagGroup from '@atlassian/jira-flags/src/common/ui/components/group-flag';
import { useIntl } from '@atlassian/jira-intl';
import { FireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import multiHCEntitlementFetchService, {
	type extendedFormContentMultiHcEntitlementQuery as multiHCentitlementQuery,
} from '@atlassian/jira-relay/src/__generated__/extendedFormContentMultiHcEntitlementQuery.graphql';
import { usePopupController } from '@atlassian/jira-servicedesk-common/src/use-popup-controller';
import { useIsJCS } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs';
import { AsyncInviteFailed } from '@atlassian/jira-servicedesk-customers-common-invite-failed/src/async.tsx';
import type { InvalidCustomerEntity } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/customers/types.tsx';
import {
	Successful,
	Unsuccessful,
} from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/form-status/index.tsx';
import { isEventFromOrganizationSelectDropList } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/organizations-select-field/utils.tsx';
import { SuccessFlag } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/ui/success-flag/index.tsx';
import { useAddCustomersDialogContext } from '@atlassian/jira-servicedesk-customers-organization-common/src/controllers/add-customers-dialog-context/index.tsx';
import type { OptimisticCustomerListUpdateResults } from '@atlassian/jira-servicedesk-customers-organization-common/src/services/invite-customers/types.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { AddCustomersForm } from './form';
import messages from './messages';
import { useFormSubmissionUtils } from './submission';

export type AddCustomersInlineDialogButtonProps = {
	isOrganizationDefaulted?: boolean;
	onPrependOptimisticCustomers: (newCustomers: OptimisticCustomerListUpdateResults) => void;
	onUpdateAppState: () => void;
};

type Props = AddCustomersInlineDialogButtonProps;

export const AddCustomersInlineDialogTrigger = ({
	isOrganizationDefaulted,
	onPrependOptimisticCustomers,
	onUpdateAppState,
}: Props) => {
	const { formatMessage } = useIntl();

	const [showSuccess, setShowSuccess] = useState<boolean>(false);
	const [showError, setShowError] = useState<boolean>(false);
	const [failedEmails, setFailedEmails] = useState<unknown>([]);
	const [successCount, setSuccessCount] = useState<number>(0);
	const isJCS = useIsJCS();
	const isMultiHcAndInviteRoutingEnabled =
		!isJCS &&
		ff('multi_help_center_kosh_integration') &&
		ff('enable_invitation_routing_with_channel_44900');
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const workSpaceARI = String(
		JiraWorkspaceAri.create({
			siteId: cloudId,
			activationId,
		}),
	);
	const [queryReference, loadQuery, disposeQuery] = useQueryLoader<multiHCentitlementQuery>(
		multiHCEntitlementFetchService,
	);

	const [
		// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
		{ isOpen, userPermissions: { isInviteEmailEnabled } = {} },
		{ resetAndCloseForm, togglePopup },
	] = useAddCustomersDialogContext();

	const [isConfirmationModalOpen, toggleConfirmationModal, closeConfirmationModal] =
		usePopupController();

	const { invitedCustomers, error, formSubmissionStatus, isSubmitting, onFormSubmit } =
		useFormSubmissionUtils();

	const setSuccessFlag = useCallback(() => {
		setShowSuccess(true);
		if (invitedCustomers) {
			const success = invitedCustomers?.success || [];
			setSuccessCount(success.length);
		}
	}, [invitedCustomers]);

	useEffect(() => {
		if (isMultiHcAndInviteRoutingEnabled) {
			loadQuery(
				{
					cloudId,
					workSpaceARI,
				},
				{
					fetchPolicy: 'store-or-network',
				},
			);
		}

		return disposeQuery;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cloudId, workSpaceARI]);

	useEffect(() => {
		if (formSubmissionStatus === Successful) {
			resetAndCloseForm();
			setSuccessFlag();
		}
	}, [formSubmissionStatus, resetAndCloseForm, setSuccessFlag]);

	const setErrorFlag = useCallback(() => {
		setShowError(true);
		if (invitedCustomers) {
			const failed = invitedCustomers?.failed || [];
			setFailedEmails(
				failed.map((failedResponse: InvalidCustomerEntity) => ({
					email: failedResponse.emailAddress,
					errorMessage: failedResponse.error,
				})),
			);
		}
	}, [invitedCustomers]);
	useEffect(() => {
		if (formSubmissionStatus === Unsuccessful) {
			setErrorFlag();
		}
	}, [formSubmissionStatus, setErrorFlag]);

	const closePopups = () => {
		setShowSuccess(false);
		setShowError(false);
	};

	const renderDialog = () => (
		<InlineDialog
			content={
				<AddCustomersForm
					isConfirmationModalOpen={isConfirmationModalOpen}
					onCloseConfirmationModal={closeConfirmationModal}
					onToggleConfirmationModal={toggleConfirmationModal}
					onUpdateAppState={onUpdateAppState}
					onPrependOptimisticCustomers={onPrependOptimisticCustomers}
					isOrganizationDefaulted={isOrganizationDefaulted}
					invitedCustomers={invitedCustomers}
					error={error}
					isSubmitting={isSubmitting}
					onFormSubmit={onFormSubmit}
					// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'FormStatus'.
					formSubmissionStatus={formSubmissionStatus}
					queryRef={queryReference}
					isCustomerInvitationSupported={!isJCS}
				/>
			}
			isOpen={isOpen}
			onClose={({ event }) => {
				// don't allow in-line dialog to close if close event is triggered from AkSelect.
				// don't allow in-line dialog to close if confirmation modal is opened on top of dialog
				// @ts-expect-error - TS2345 - Argument of type 'Event' is not assignable to parameter of type 'MouseEvent'.
				if (isEventFromOrganizationSelectDropList(event) || isConfirmationModalOpen) {
					return;
				}

				togglePopup();
			}}
			placement={isOrganizationDefaulted === true ? 'bottom-start' : 'bottom-end'}
		>
			<Button
				testId="servicedesk-customers-organization-common.ui.add-customers-dialog.button"
				onClick={togglePopup}
				appearance="primary"
			>
				{formatMessage(messages.addCustomers)}
			</Button>
		</InlineDialog>
	);

	const Trigger = useCallback(
		(triggerProps: Partial<TriggerProps>) => (
			<Button
				{...triggerProps}
				testId="servicedesk-customers-organization-common.ui.add-customers-dialog.button"
				onClick={togglePopup}
				appearance="primary"
			>
				{formatMessage(messages.addCustomers)}
			</Button>
		),
		[formatMessage, togglePopup],
	);
	const renderPopup = () => (
		<Popup
			content={() => (
				<ContentWrapper>
					<AddCustomersForm
						isConfirmationModalOpen={isConfirmationModalOpen}
						onCloseConfirmationModal={closeConfirmationModal}
						onToggleConfirmationModal={toggleConfirmationModal}
						onUpdateAppState={onUpdateAppState}
						onPrependOptimisticCustomers={onPrependOptimisticCustomers}
						isOrganizationDefaulted={isOrganizationDefaulted}
						invitedCustomers={invitedCustomers}
						error={error}
						isSubmitting={isSubmitting}
						onFormSubmit={onFormSubmit}
						// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'FormStatus'.
						formSubmissionStatus={formSubmissionStatus}
						queryRef={queryReference}
						isCustomerInvitationSupported={!isJCS}
					/>
				</ContentWrapper>
			)}
			isOpen={isOpen}
			onClose={togglePopup}
			placement={isOrganizationDefaulted === true ? 'bottom-start' : 'bottom-end'}
			trigger={Trigger}
		/>
	);

	const generalSuccessDescription = formatMessage(messages.addedCustomersDescriptionNew);
	const inviteDisabled = formatMessage(messages.addedCustomersWhenEmailDisabled);

	const successDescription = isInviteEmailEnabled ? generalSuccessDescription : inviteDisabled;
	const flagTitle = isMultiHcAndInviteRoutingEnabled
		? formatMessage(messages.addedCustomersNewMultipleHelpCenters, { count: successCount })
		: formatMessage(messages.addedCustomersNew, { count: successCount });

	return (
		<>
			{isMultiHcAndInviteRoutingEnabled ? renderPopup() : renderDialog()}
			<FlagGroup onDismissed={closePopups}>
				{showSuccess && (
					<>
						<SuccessFlag
							title={flagTitle}
							description={successDescription}
							onDismissed={closePopups}
							key={successCount}
						/>
						<FireTrackAnalytics actionSubject="customer" action="inviteSuccess" />
					</>
				)}
			</FlagGroup>
			{showError && (
				<>
					{/* @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'InviteFailedEmails[]'. */}
					<AsyncInviteFailed failedEmails={failedEmails} onClosed={closePopups} />
					<FireTrackAnalytics actionSubject="customer" action="inviteFailed" />
				</>
			)}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ContentWrapper = styled.div({
	padding: `${token('space.200', '16px')} ${token('space.300', '24px')}`,
	maxHeight: '500px',
});

export const AddCustomersInlineDialogButton = AddCustomersInlineDialogTrigger;
