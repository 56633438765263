import { useState, useCallback, useRef } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { performPostRequest } from '@atlassian/jira-servicedesk-common/src/utils/fetch/requests.tsx';
import type { Props, State } from './types';
import { getUrl, transformData, transformForView, transformError } from './utils';

export const useValidateCustomerEmailsService = ({ baseUrl, projectKey }: Props): State => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<unknown>(null);
	const [error, setError] = useState<unknown>(null);
	const [emailValidationErrors, setEmailValidationErrors] = useState<unknown>(null);
	const abortController = useRef(new AbortController());
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const resetEmailValidation = () => {
		setEmailValidationErrors(null);
	};

	const resetError = () => {
		setError(null);
	};

	const fetch = useCallback(
		async (emails: string[]) => {
			setLoading(true);
			setError(null);

			resetEmailValidation();

			if (abortController.current && abortController.current.abort) {
				abortController.current.abort();
			}

			try {
				abortController.current = new AbortController();
				const { signal } = abortController.current;

				const response = await performPostRequest(getUrl(baseUrl, projectKey), {
					body: JSON.stringify({ emails }),
					signal,
				});

				const transformedResponse = transformData(response);
				setData(transformedResponse);

				setEmailValidationErrors(transformForView(response));

				setLoading(false);
				const analyticsEvent = createAnalyticsEvent({});
				fireTrackAnalytics(
					analyticsEvent,
					`customer ${response.failed.length ? 'addValidationFailed' : 'addValidationSucceeded'}`,
				);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				const { reasonKey, response, statusCode } = err;

				// Only fire analytics for unexpected errors. ValidationErrors are used when
				// the backend returns a 400. Expected 400 errors from the backend include:
				// - the email address is invalid (eg `bad@email@example.com`)
				// A 403 is also returned if the user doesn't have permission to invite, but
				// we only show the invite button after a permission check -
				// it's an unexpected error if we see it here
				const shouldFireErrorAnalytics = err.statusCode !== 400;
				if (shouldFireErrorAnalytics) {
					fireErrorAnalytics({
						error: err,
						meta: {
							id: 'useValidateCustomerEmailsService',
							packageName: 'jiraServicedeskCustomersOrganizationCommon',
							teamName: 'boysenberry',
						},
						sendToPrivacyUnsafeSplunk: true,
						attributes: { reasonKey },
					});
				}

				if (response?.failed) {
					setEmailValidationErrors(transformForView(response));
				}

				if (response?.failed) {
					setData(transformData(response));
				} else {
					setError(transformError(statusCode) ?? err);
				}
				setLoading(false);
			}
		},
		[baseUrl, createAnalyticsEvent, projectKey],
	);

	return {
		loading,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'ServiceError'.
		error,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'ServiceData<Data>'.
		data,
		// @ts-expect-error - TS2322 - Type '(emails: string[]) => Promise<void>' is not assignable to type '(() => Promise<void>) & ((ValidateCustomerEmailsRequest: string[]) => Promise<void>)'.
		fetch,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'EmailValidationErrorType'.
		emailValidationErrors,
		resetEmailValidation,
		resetError,
	};
};
