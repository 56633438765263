import React, { useCallback, useMemo } from 'react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { FlagId } from '@atlassian/jira-flags';
import Flag from '@atlassian/jira-flags/src/common/ui/components/ak-flag';
import { useFlagService } from '@atlassian/jira-flags/src/services';
import { DISMISS_COMMAND, fromFlagId } from '@atlassian/jira-flags/src/services/types';
import { useIntl } from '@atlassian/jira-intl';
import { sanitizeHtml } from '@atlassian/jira-servicedesk-customers-common-invite-failed/src/ui/invite-failed/utils';
import messages from './messages';

type Props = {
	id: FlagId;
	errorMessage: string | null;
};

export const ResendInviteErrorFlag = ({ id, errorMessage }: Props) => {
	const { formatMessage } = useIntl();

	const { dismissFlag } = useFlagService();

	const onDismissed = useCallback(() => {
		dismissFlag({
			command: DISMISS_COMMAND,
			id,
		});
	}, [dismissFlag, id]);

	const onRefresh = useCallback(() => window.location.reload(), []);

	return useMemo(
		() => (
			<Flag
				id={fromFlagId(id)}
				icon={<ErrorIcon label="error" primaryColor={token('color.icon.danger', R400)} />}
				title={formatMessage(messages.resendInviteFailureFlagTitle)}
				description={
					errorMessage ? (
						// eslint-disable-next-line react/no-danger
						<div dangerouslySetInnerHTML={{ __html: sanitizeHtml(errorMessage) }} />
					) : (
						formatMessage(messages.resendInviteFailureFlagDescription)
					)
				}
				onDismissed={onDismissed}
				actions={[
					{
						content: formatMessage(messages.refreshThePage),
						onClick: onRefresh,
					},
				]}
			/>
		),
		[id, formatMessage, errorMessage, onDismissed, onRefresh],
	);
};
