import React from 'react';
import noop from 'lodash/noop';
import { AsyncCreatableSelect as AkSelect } from '@atlaskit/select';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { OrganizationSelectIdentifier } from './constants';
import messages from './messages';
import type { OrganizationSelectIntlProps } from './types';
import { useSelectUtils } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const menuPortal = (base: any) => ({ ...base, zIndex: layers.modal });

export const OrganizationsSingleSelect = ({
	onFetchOptions,
	onSelect,
	hidePlaceholder = true,
	inputId,
	value,
	isMulti,
}: OrganizationSelectIntlProps) => {
	const { formatMessage } = useIntl();
	const { onChange, loadOptions, isValidNewOption, formatOptionLabel, getNewOptionData } =
		useSelectUtils({
			onSelect,
			onFetchOptions,
		});

	return (
		<AkSelect
			classNamePrefix={OrganizationSelectIdentifier}
			controlShouldRenderValue={!isMulti}
			defaultOptions
			formatCreateLabel={(orgName: string) => `${formatMessage(messages.createNew, { orgName })}`}
			formatOptionLabel={formatOptionLabel}
			getNewOptionData={getNewOptionData}
			isClearable={!isMulti}
			isValidNewOption={isValidNewOption}
			menuPortalTarget={document.body}
			noOptionsMessage={() => formatMessage(messages.noOptionsMessage)}
			// @ts-expect-error - TS2322 - Type '(selectedOption: Option) => void' is not assignable to type '(value: Option | null, action: ActionMeta<Option>) => void'.
			onChange={onChange}
			styles={{ menuPortal }}
			value={value}
			placeholder={hidePlaceholder ? '' : formatMessage(messages.placeHolder)}
			loadOptions={loadOptions}
			id="servicedesk-customers-organization-common.common.ui.organizations-select-field.organizations-single-select.ak-select"
			inputId={inputId}
		/>
	);
};

OrganizationsSingleSelect.defaultProps = {
	onFetchOptions: noop,
	onSelect: noop,
	isMulti: false,
};
