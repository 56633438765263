import isNil from 'lodash/isNil';
import { stringify } from 'query-string';
import type { Data } from './types';

const stringifyOptions = {
	skipNull: true,
	skipEmptyString: true,
	sort: false,
} as const;

type QueryParams = {
	name?: string;
	id?: string | number;
};

export const getUrl = (baseUrl: string, projectKey: string, { name, id }: QueryParams = {}) => {
	const defaultUrl = `${baseUrl}/rest/servicedesk/1/pages/people/customers/pagination/${projectKey}/invite`;
	if (isNil(name) && isNil(id)) {
		return defaultUrl;
	}
	// @ts-expect-error - TS2559 - Type '{ readonly skipNull: true; readonly skipEmptyString: true; readonly sort: false; }' has no properties in common with type 'StringifyOptions'.
	return `${defaultUrl}/organisation?${stringify({ name, id }, stringifyOptions)}`;
};

export const extractInvalidEmailsFromErrMsg = (emails: string[], errMsg: string): string[] => {
	const errArr = errMsg.split(' ').map((str: string) => str.replace(',', ''));
	// exactly match email to error message eg: test in test@email.com should not be matched
	// should only match example@email.com in example@email.com, not in example@email.com.au
	return emails.filter((email) => errArr.indexOf(email) > -1);
};

export const getNormalisedErrorResponse = (emails: string[], reasonKey: string): Data => {
	const invalidEmails = extractInvalidEmailsFromErrMsg(emails, reasonKey);
	const failed = invalidEmails.map((email: string) => ({
		displayName: email,
		emailAddress: email,
		key: email,
		error: reasonKey,
	}));
	return {
		failed,
		success: [],
		optimisticCustomerListUpdateResults: [],
	};
};
