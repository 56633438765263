import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addOrganizations: {
		id: 'servicedesk-customers.header-actions.add-organizations-dialog.form.add-organizations',
		defaultMessage: 'Add organizations',
		description: 'header for adding organizations',
	},
	successfullyAddedOrganizations: {
		id: 'servicedesk-customers.header-actions.add-organizations-dialog.form.successfully-added-organizations',
		defaultMessage:
			'Success! Your {organizationsCount, plural, =1 {organization} other {organizations}} have been added.',
		description: 'success message displayed after adding organizations',
	},
	selectOrgLabel: {
		id: 'servicedesk-customers.header-actions.add-organizations-dialog.form.select-org-label',
		defaultMessage: 'Choose or create an organization',
		description: 'label for selecting an organization',
	},
});
