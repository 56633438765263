import type { UserPermissions } from '../../controllers/app-store/types';
import messages from './messages';

export const getMessageBasedOnUserPermissions = (userPermissions: UserPermissions) => {
	const { serviceDeskPublicSignUp, serviceDeskOpenAccess } = userPermissions;

	// Open: Anyone can raise a request in this service desk. Link to customer permissions page.
	if (serviceDeskPublicSignUp) {
		return messages.publicSignUpAccessMessageJsm;
	}

	// Any Existing User: Everyone with an account on this Jira instance is a customer. Link to customer permissions page.
	if (serviceDeskOpenAccess) {
		return messages.openAccessMessage;
	}

	// Closed: This service desk is open to customers who are added to the project. Link to customer permissions page.
	return messages.adminAccessMessageJsm;
};

export const getMessageBasedOnUserPermissionsNew = (userPermissions: UserPermissions) => {
	const { serviceDeskPublicSignUp, serviceDeskOpenAccess } = userPermissions;

	// Open: Anyone can raise a request in this service desk. Link to customer permissions page.
	if (serviceDeskPublicSignUp) {
		return messages.publicSignUpAccessMessageJsmNew;
	}

	// Any Existing User: Everyone with an account on this Jira instance is a customer. Link to customer permissions page.
	if (serviceDeskOpenAccess) {
		return messages.openAccessMessageNew;
	}

	// Closed: This service desk is open to customers who are added to the project. Link to customer permissions page.
	return messages.adminAccessMessageJsmNew;
};
