import { useState, useCallback } from 'react';
import isNil from 'lodash/isNil';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	performPostRequest,
	performPutRequest,
} from '@atlassian/jira-servicedesk-common/src/utils/fetch/requests.tsx';
import { isNew as isNewOrganization } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/organizations/constants.tsx';
import type { Organization } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/organizations/types.tsx';
import type { HelpCenterDropDownOption } from '@atlassian/jira-servicedesk-help-center-select-field/src/common/types/help-centers/types.tsx';
import type { BaseUrl, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { addCustomersExperience } from '../../experiences';
import type { Data, State } from './types';
import { getUrl, getNormalisedErrorResponse } from './utils';

export const useInviteCustomerService = (baseUrl: BaseUrl, projectKey: ProjectKey): State => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<Data>(null);
	const [error, setError] = useState<unknown>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const resetAll = useCallback(() => {
		setLoading(false);
		setError(null);
		setData(null);
	}, []);

	const fetch = useCallback(
		async (
			emails: string[],
			selectedOrganization?: Organization,
			helpCenter?: HelpCenterDropDownOption,
		) => {
			setLoading(true);
			setError(null);
			const name = selectedOrganization ? selectedOrganization.name : undefined;
			const value = selectedOrganization ? selectedOrganization.value : undefined;
			const isExistingOrganization = !isNil(value) && !isNewOrganization(value);
			const asyncRequest = isExistingOrganization ? performPutRequest : performPostRequest;
			const requestParams = isExistingOrganization ? { id: value } : { name };
			const helpCenterAri = helpCenter ? helpCenter.value : undefined;
			addCustomersExperience.start();

			try {
				const response = await asyncRequest(getUrl(baseUrl, projectKey, requestParams), {
					body: JSON.stringify({ emails, helpCenterAri }),
				});
				setData(response);
				setLoading(false);

				const event: UIAnalyticsEvent = createAnalyticsEvent({
					action: 'added',
					actionSubject: 'customer',
				});

				fireTrackAnalytics(event, 'customer added', {
					helpCenterAri,
					organizationId: isExistingOrganization ? value : 'new',
					customerCount: response?.success.length,
				});

				addCustomersExperience.success();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				const { reasonKey, errors, response, message } = err;
				// Only fire analytics for unexpected errors. ValidationErrors are used when
				// the backend returns a 400. Expected 400 errors from the backend include:
				// - the email address is invalid (eg `bad@email@example.com`)
				// A 403 is also returned if the user doesn't have permission to invite, but
				// we only show the invite button after a permission check -
				// it's an unexpected error if we see it here
				const shouldFireErrorAnalytics = err.statusCode !== 400;
				if (shouldFireErrorAnalytics) {
					fireErrorAnalytics({
						error: err,
						meta: {
							id: 'useInviteCustomerService',
							packageName: 'jiraServicedeskCustomersOrganizationCommon',
							teamName: 'boysenberry',
						},
						sendToPrivacyUnsafeSplunk: true,
						attributes: { reasonKey },
					});
				}

				if (reasonKey || errors) {
					const errResponse = getNormalisedErrorResponse(emails, message);
					setData(errResponse);
				} else {
					setData(response);
				}
				setLoading(false);
				addCustomersExperience.failure();
			}
		},
		[baseUrl, createAnalyticsEvent, projectKey],
	);

	return {
		loading,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type '((Error | ValidationErrors | { statusCode: number; message?: string | undefined; }) & ValidationErrors) | null'.
		error,
		data,
		// @ts-expect-error - TS2322 - Type '(emails: string[], selectedOrganization?: Organization | undefined) => Promise<void>' is not assignable to type '(() => Promise<void>) & ((emails: string[], organization?: Organization | undefined) => Promise<void>)'.
		fetch,
		resetAll,
	};
};
