import { useState, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { Props, State } from './types';
import { getFetchUrl } from './utils';

// we debounce the success event firing, to more accurately represent a complete search
// as opposed to a search which is triggered mid-way through a user's search term
const searchSuccessDebounceTime = 2000;

export const useOrganizationSearchService = ({ baseUrl, projectId }: Props): State => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<unknown>(null);
	const [error, setError] = useState<unknown>(null);

	const abortController = useRef<unknown>(null);
	const fireSearchSuccessEvent = useCallback(
		(
			// @ts-expect-error - TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
			analyticsEvent,
			{
				isSearchStringEmpty,
			}: {
				isSearchStringEmpty: boolean;
			},
		) => {
			fireTrackAnalytics(analyticsEvent, 'jsmOrganizationsSearch succeeded', {
				isSearchStringEmpty,
			});
		},
		[],
	);
	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFireSearchSuccessEvent = useCallback(
		debounce(
			fireSearchSuccessEvent,
			searchSuccessDebounceTime,
			{ leading: true, trailing: false }, // fire event on the leading edge of the debounce
		),
		[],
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fetch = useCallback(
		async (query: string) => {
			let organizationsResponse = [];

			setLoading(true);
			setError(null);

			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			if (abortController.current && abortController.current.abort) {
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				abortController.current.abort();
			}

			try {
				abortController.current = new AbortController();
				// @ts-expect-error - TS2339 - Property 'signal' does not exist on type 'unknown'.
				const { signal } = abortController.current;

				const response = await performGetRequest(getFetchUrl(baseUrl, projectId, query), {
					signal,
				});

				const analyticsEvent = createAnalyticsEvent({});
				debouncedFireSearchSuccessEvent(analyticsEvent, {
					isSearchStringEmpty: query === '',
				});

				organizationsResponse = response;
				setData(response);
				setLoading(false);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				if (err && err.name === 'AbortError') {
					return organizationsResponse;
				}
				fireErrorAnalytics({
					meta: {
						id: 'organizationSearchServiceFetch',
						packageName: 'jiraServicedeskCustomersOrganizationCommon',
						teamName: 'boysenberry',
					},
					error: err,
					sendToPrivacyUnsafeSplunk: true,
				});
				setLoading(false);
				setError(err);
			}

			return organizationsResponse;
		},
		[baseUrl, createAnalyticsEvent, debouncedFireSearchSuccessEvent, projectId],
	);

	return {
		loading,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'ServiceError'.
		error,
		// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'ServiceData<Organizations>'.
		data,
		// @ts-expect-error - TS2322 - Type '(query: string) => Promise<any>' is not assignable to type '(() => Promise<void>) & ((query: string) => Promise<Organizations>)'.
		fetch,
	};
};
