import { stringify } from 'query-string';
import { transformCustomerSearchResults } from '@atlassian/jira-servicedesk-customers-organization-common/src/common/types/utils.tsx';
import type { ProjectKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { SearchResult, FormattedSearchResult } from './types';

const stringifyOptions = {
	skipNull: true,
	skipEmptyString: true,
	sort: false,
} as const;

export const getUrl = (
	baseUrl: BaseUrl,
	projectKey: ProjectKey,
	shouldFetchUserPermissions: boolean,
	query = '',
	page?: number,
) => {
	const defaultUrl = `${baseUrl}/rest/servicedesk/1/pages/people/customers/pagination/${projectKey}`;
	if (shouldFetchUserPermissions) {
		return defaultUrl;
	}
	// @ts-expect-error - TS2559 - Type '{ readonly skipNull: true; readonly skipEmptyString: true; readonly sort: false; }' has no properties in common with type 'StringifyOptions'.
	return `${defaultUrl}/search?${stringify({ query, page }, stringifyOptions)}`;
};

export const transformSearchResults = (results: SearchResult): FormattedSearchResult => {
	// @ts-expect-error - TS2339 - Property 'customerResults' does not exist on type 'SearchResult'.
	if (results.customerResults) {
		const {
			// @ts-expect-error - TS2339 - Property 'customerResults' does not exist on type 'SearchResult'.
			customerResults,
			// @ts-expect-error - TS2339 - Property 'customerInviteDialogueModel' does not exist on type 'SearchResult'.
			customerInviteDialogueModel: { isInviteEmailEnabled },
			...rest
		} = results;
		return {
			// @ts-expect-error - TS2322 - Type '{ isInviteEmailEnabled: any; results: Customers; query: string; total: number; pageNumber: number; lastDeterminedPaged: number; totalHasBeenDetermined: boolean; resultsPerPage: number; pageRange: number; } | { ...; }' is not assignable to type 'UserPermissions | null'.
			userPermissions: { ...rest, isInviteEmailEnabled },
			pageData: transformCustomerSearchResults(customerResults),
		};
	}

	return {
		userPermissions: null,
		// @ts-expect-error - TS2345 - Argument of type 'SearchResult' is not assignable to parameter of type 'PagedSearchResults'.
		pageData: transformCustomerSearchResults(results),
	};
};
